
/*---Background---*/

.background {
	background-color: $background !important;
  }
  
  .bg-primary {
	background-color: $primary !important;
  }
  
  a.bg-primary {
	&:hover, &:focus {
	  background-color: $primary-hover !important;
	}
  }
  
  button.bg-primary {
	&:hover, &:focus {
	  background-color: $primary-hover !important;
	}
  }
  
  .bg-secondary {
	background-color: $secondary !important;
  }
  
  a.bg-secondary {
	&:hover, &:focus {
	  background-color: $secondary !important;
	}
  }
  
  button.bg-secondary {
	&:hover, &:focus {
	  background-color: $secondary !important;
	}
  }
  
  .bg-success {
	background-color: $success !important;
  }
  
  a.bg-success {
	&:hover, &:focus {
	  background-color: #2a7d01 !important;
	}
  }
  
  button.bg-success {
    &:hover, &:focus {
      background-color: #2a7d01 !important;
    }
  }
  
  .bg-info {
	background-color: $info !important;
  }
  
  a.bg-info {
	&:hover, &:focus {
	  background-color: $info !important;
	}
  }
  
  button.bg-info {
	&:hover, &:focus {
	  background-color: $info !important;
	}
  }
  
  .bg-warning {
	background-color: $warning !important;
  }
  
  a.bg-warning {
	&:hover, &:focus {
	  background-color: #ddaa19 !important;
	}
  }
  
  button.bg-warning {
	&:hover, &:focus {
	  background-color: #ddaa19 !important;
	}
  }
  
  .bg-danger {
	background-color: $danger !important;
  }
  
  a.bg-danger {
	&:hover, &:focus {
	  background-color: #bd2130 !important;
	}
  }
  
  button.bg-danger {
	&:hover, &:focus {
	  background-color: #bd2130 !important;
	}
  }
  
  .bg-purple {
	background-color: $purple!important;
  }
  
  a.bg-purple {
	&:hover, &:focus {
	  background-color: #5930a2 !important;
	}
  }
  
  button.bg-purple {
	&:hover, &:focus {
	  background-color: #5930a2 !important;
	}
  }
  
  .bg-light {
	background-color: $gray-100 !important;
  }
  
  a.bg-light {
	&:hover, &:focus {
	  background-color: #d5d9e4 !important;
	}
  }
  
  button.bg-light {
	&:hover, &:focus {
	  background-color: #d5d9e4 !important;
	}
  }
  
  .bg-dark {
	background-color: $gray-800 !important;
  }
  
  a.bg-dark {
	&:hover, &:focus {
	  background-color: #283143 !important;
	}
  }
  
  button.bg-dark {
	&:hover, &:focus {
	  background-color: #283143 !important;
	}
  }
  
  .bg-white {
	background-color: $white !important;
  color: $default-color !important;
  }
  
  .bg-transparent {
	background-color: transparent !important;
  }
  
  .bg-gray + .select2-container--default .select2-selection--single {
	background-color: #455473;
  
	.select2-selection__rendered {
	  color: $gray-300;
	}
  }
  
  /* ###### 9.1 Background  ###### */
  .bg-transparent {
	  background-color: transparent;
  }
  .bg-gray-100 {
	  background-color: $gray-100 !important;
  }
  .bg-gray-200 {
	  background-color: $gray-200;
  }
  .bg-gray-300 {
	  background-color: $gray-300 !important;
  }
  .bg-gray-400 {
	  background-color: $gray-400 !important;
  }
  .bg-gray-500 {
	  background-color: $gray-500 !important;
  }
  .bg-gray-600 {
	  background-color: $gray-600 !important;
  }
  .bg-gray-700 {
	  background-color: $gray-700 !important;
  }
  .bg-gray-800 {
	  background-color: $gray-800 !important;
  }
  .bg-gray-900 {
	  background-color: $gray-900 !important;
  }
  
  .bg-white-1 {
	background-color: $white-1;
  }
  
  .bg-white-2 {
	background-color: $white-2;
  }
  
  .bg-white-3 {
	background-color: $white-3;
  }
  
  .bg-white-4 {
	background-color: $white-4;
  }
  
  .bg-white-5 {
	background-color: $white-5;
  }
  
  .bg-white-6 {
	background-color: $white-6;
  }
  
  .bg-white-7 {
	background-color: $white-7;
  }
  
  .bg-white-8 {
	background-color: $white-8;
  }
  
  .bg-white-9 {
	background-color: $white-9;
  }
  
  .bg-black-1 {
	background-color: $black-1;
  }
  
  .bg-black-2 {
	background-color: $black-2;
  }
  
  .bg-black-3 {
	background-color: $black-3;
  }
  
  .bg-black-4 {
	background-color: $black-4;
  }
  
  .bg-black-5 {
	background-color: $black-5;
  }
  
  .bg-black-6 {
	background-color: $black-6;
  }
  
  .bg-black-7 {
	background-color: $black-7;
  }
  
  .bg-black-8 {
	background-color: $black-8;
  }
  
  .bg-black-9 {
	background: $black-9;
  }
  
  .bg-indigo {
	background: $primary;
  }
  
  .bg-purple {
	background: $purple;
  }
  
  .bg-pink {
	background: $pink !important;
  }
  
  .bg-orange {
	background: $orange !important;
  }
  
  .bg-teal {
	background: $teal !important;
  }
  
  .bg-blue {
    background-color: #083dc1 !important;
      color: #fff !important;
  }
  .bg-yellow {
     background-color: #ecb403 !important;
      color: #fff !important;
  }
  .bg-green {
      background-color: #2dce89 !important;
      color: #fff !important;
  }
  
  .bg-purple-dark {
	background: #59339d;
  }
  
  .bg-transparent {
	background: transparent;
  }
  .bg-red {
    background-color: #dc0441 !important;
    color: #fff !important;
  }
  .bg-azure {
      background-color: #45aaf2 !important;
      color: #fff !important;
  }
  .bg-lime {
    background-color: #1e74a0 !important;
    color: #fff !important;
  }
  /* ###### 4.8 transparent colors ###### */
  
  .bg-success-transparent {
	background-color: rgba($success, 0.1) !important;
	color:$success !important;
  }
  
  .bg-danger-transparent {
	background-color: rgba($danger, 0.1) !important;
	color:$danger !important;
  }
  
  .bg-primary-transparent {
	background-color: $primary-1 !important;
	color:$primary !important;
  }
  
  .bg-secondary-transparent {
	background-color: rgba($secondary, 0.1) !important;
	color:$secondary !important;
  }
  
  .bg-warning-transparent {
	background-color: rgba($warning, 0.1) !important;
	color:$warning !important;
  }
  
  .bg-pink-transparent {
	background-color: rgba($pink, 0.1) !important;
	color:$pink !important;
  }
  
  .bg-purple-transparent {
	background-color: rgba($purple, 0.1) !important;
	color:$purple !important;
  }
  
  .bg-teal-transparent {
	background-color: rgba($teal, 0.1) !important;
	color:$teal !important;
  }
  
  .bg-info-transparent {
	background-color: rgba($info, 0.1) !important;
	color:$info !important;
  }
  
  .bg-orange-transparent {
	background-color: rgba($orange, 0.1);
	color:$orange !important;
  }
  
  .bg-gradient-blue {
	background-image: linear-gradient(to right, #1976d2 0, #64b5f6 100%);
  }
  
  .bg-primary {
	background-color: $primary  !important;
  }
  
  .bg-primary-gradient {
	background-image: linear-gradient(to left, $primary-5 0%, $primary 100%) !important;
  }
  
  .bg-danger-gradient {
	background-image: linear-gradient(45deg, #f93a5a, #f7778c) !important;
  }
  
  .bg-success-gradient {
	background-image: linear-gradient(to left, #48d6a8 0%, #029666 100%) !important;
  }
  
  .bg-warning-gradient {
	background-image: linear-gradient(to left, #efa65f, #f76a2d) !important;
  }
  
  .bg-purple-gradient {
	background-image: linear-gradient(to right, $purple 0%, #ba7bfb 100%) !important;
  }
  
  .bg-info-gradient {
	background-image: linear-gradient(to right, #0296ce 0%, #38c6f7 100%) !important;
  }
  
  .bg-secondary-gradient {
	background-image: linear-gradient(to right, #636f8e 0%, #acb8da 100%) !important;
  }
  
  .bg-pink-gradient {
	background-image: linear-gradient(to right, $pink 0%, #f36eae 100%) !important;
  }
  
  .bg-purple-gradient {
	background-image: linear-gradient(to right, $purple 0%, #884af1 100%) !important;
  }
  
  .bg-teal-gradient {
	background-image: linear-gradient(to right, $teal 0%, #96e8e8 100%) !important;
  }
  /*---Background---*/

  /*------ Carousel -------*/

.carousel-slider {
	#carousel {
	  margin: 0;
	}
  
	#thumbcarousel {
	  margin: 10px 0 0 0;
	  padding: 0;
  
	  .carousel-item {
		text-align: center;
  
		.thumb {
		  width: 100%;
		  margin: 5px;
		  display: inline-block;
		  vertical-align: middle;
		  cursor: pointer;
		  max-width: 100px;
  
		  &:hover {
			border-color: #e8ebf3;
		  }
		}
	  }
	}
  
	.carousel-item img {
	  width: 100%;
	  height: auto;
	}
  }
  
  .carousel-control {
	&:active, &:focus, &:hover {
	  color: #333;
	}
  }
  
  .carousel-caption, .carousel-control .fa {
	font: normal normal normal 30px/26px FontAwesome;
  }
  
  #basicSlider img {
	width: 100%;
  }
  
  #thumbcarousel {
	.carousel-control-prev {
	  inset-inline-start: 0;
	  background: $black-8;
	}
  
	.carousel-control-next {
	  inset-inline-end: 0;
	  background: $black-8;
	}
  }
  
  .owl-carousel button.owl-dot {
	background: rgba(105, 99, 255, 0.3);
	opacity: 7;
	color: inherit;
	border: none;
	padding: 0 !important;
	font: inherit;
  }
  
  .owl-nav button:before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	inset-inline-start: 0;
	inset-inline-end: 0;
	bottom: 0;
	background: #2098d1;
	-webkit-transform: scaleY(0);
	transform: scaleY(0);
	-webkit-transform-origin: 50% 0;
	transform-origin: 50% 0;
	-webkit-transition-property: transform;
	transition-property: transform;
	-webkit-transition-duration: .5s;
	transition-duration: .5s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
  }
  
  .carousel {
	position: relative;
  
	&.pointer-event {
	  touch-action: pan-y;
	}
  }
  
  .carousel-inner {
	position: relative;
	width: 100%;
	overflow: hidden;
  
	&::after {
	  display: block;
	  clear: both;
	  content: "";
	}
  }
  
  .carousel-indicators {
	position: absolute;
	inset-inline-end: 0;
	bottom: 0;
	inset-inline-start: 0;
	z-index: 15;
	display: flex;
	justify-content: center;
	padding-inline-start: 0;
	margin-inline-end: 15%;
	margin-inline-start: 15%;
	list-style: none;
  
	li {
	  box-sizing: content-box;
	  flex: 0 1 auto;
	  margin-inline-end: 3px;
	  margin-inline-start: 3px;
	  text-indent: -999px;
	  cursor: pointer;
	  background-color: $white;
	  background-clip: padding-box;
	  border-block-start: 10px solid transparent;
	  border-block-end: 10px solid transparent;
	  opacity: .5;
	  transition: opacity 0.6s ease;
	}
  
	.active {
	  opacity: 1;
	}
  }
  
  @media (prefers-reduced-motion: reduce) {
	.carousel-indicators li {
	  transition: none;
	}
  }
  
  .carousel-caption {
	position: absolute;
    inset-inline-end: 1rem;
    bottom: 20px;
    inset-inline: 2rem;
    z-index: 1;
    padding-block-start: 20px;
    padding-block-end: 20px;
    color: $white;
    text-align: center;
    inset-block: 1rem;
	
	h5, p{
	  font-family: "Red Hat Display" , sans-serif !important;
	}
  }
  
  .carousel-control-prev-icon, .carousel-control-next-icon {
	background-image: none;
	display: block;
	width: auto;
	height: auto;
  }
  
  .carousel-control-prev-icon svg, .carousel-control-next-icon svg {
	width: 48px;
	height: 48px;
  }
  
  .carousel-indicators li {
	width: 10px !important;
	height: 10px !important;
	border-radius: 50%;
  }
  .carousel-item-background {
    content: '';
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

  /*------ Carousel -------*/
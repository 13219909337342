/***********switcher-styles****************/

body.app.body-style1 {
  background: $white;
  .card {
    box-shadow: none;
    border-color: $border !important;
  }
  .page {
    background: $white !important;
  }
  .app-sidebar {
    box-shadow: none;
    border-inline-end-color: $border !important;
   border-inline-start-color: $border !important;
  }
  .main-header {
    box-shadow: none;
    border-block-end-color: $border !important;
  }
  @media (min-width: 992px) {
    &.horizontal .horizontal-main {
      border-block-end: 1px solid $border !important;
    }
  }
  @media (max-width: 991.98px) {
    .horizontal .main-header.hor-header {
      box-shadow: none;
    }
  }
  @media (min-width: 992px) {
    &.light-header .main-header.hor-header {
      border-block-end-color: #eae8f1 !important;
    }
    &.light-menu .app-sidebar {
      border-inline-end-color: #eae8f1 !important;
     border-inline-start-color: #eae8f1 !important;
    }
  }
}

body.dark-theme.body-style1 {
  background: $dark-theme;
  .card {
    border-color: $dark-border !important;
  }
  .page {
    background: $dark-theme !important;
  }
  .app-sidebar {
    border-inline-end-color: $dark-border !important;
   border-inline-start-color: $dark-border !important;
  }
  .main-header {
    box-shadow: none;
    border-block-end-color: $dark-border !important;
  }
  @media (min-width: 992px) {
    &.horizontal .horizontal-main {
      border-block-end-color: $dark-border !important;
    }
  }
}

body.light-theme.app.body-style1 {
  background: $white;
  .card {
    box-shadow: none;
    border-color: $border !important;
  }
  .page {
    background: $white !important;
  }
  .app-sidebar {
    box-shadow: none;
    border-inline-end-color: $border !important;
   border-inline-start-color: $border !important;
  }
  .main-header {
    box-shadow: none;
    border-block-end-color: $border !important;
  }
  @media (min-width: 992px) {
    &.horizontal .horizontal-main {
      border-block-end: 1px solid $border !important;
    }
  }
  @media (max-width: 991.98px) {
    .horizontal .main-header.hor-header {
      box-shadow: none;
    }
  }
}

/***********body-styles****************/

/***********Boxed Styles****************/
@media (min-width: 1400px) {
  body.layout-boxed {
    background: $background;
    .page {
      width: 1400px;
      margin: 0 auto;
      background: $background;
      position: relative;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex-pack: center;
      justify-content: center;
      min-height: 100vh;
      box-shadow: $black-3 0px 5px 0px 0px, $black-3 0px 5px 25px 0px;
    }
    &.horizontal .hor-header .container,
    &.horizontal .horizontal-main .container,
    &.horizontal .main-content.horizontal-content .container {
      max-width: 95% !important;
    }
    .sticky-pin .horizontal-main {
      max-width: 1400px;
      margin: 0;
    }
    .main-header {
      max-width: 1400px;
      margin: 0 auto;
      inset-inline-start: 0;
      inset-inline-end: 0;
      top: 0;
    }
    .main-content .container,
    .main-header > .container,
    .horizontal-mainwrapper.container {
      padding-inline-end: 0.75rem;
      padding-inline-start: 0.75rem;
    }
    .horizontalMenu > .horizontalMenu-list > li > a {
      padding: 15px 11px 15px 11px;
    }
    .page.bg-primary-transparent,
    .main-error-wrapper.page {
      background: $primary-3 !important;
    }
    .app-sidebar {
      inset-inline-start: auto !important;
    }
    .main-content-left-chat .main-nav-line-chat .nav-link + .nav-link {
      margin-inline-start: 0;
    }
    .main-nav-line-chat {
      padding: 0 17px;
    }
    .app-sidebar .main-sidebar-header {
      inset-inline-start: auto;
      inset-inline-end: auto;
    }
    &.dark-theme {
      background: $dark-body !important;
      .page {
        background: $dark-body;
        box-shadow: $white-1 0px 5px 0px 0px, $white-1 0px 5px 25px 0px;
      }
      .page.bg-primary-transparent,
      .main-error-wrapper.page {
        background: $dark-body !important;
      }
    }
  }
}
/***********Boxed Styles****************/

/***********Scroll-layout Styles****************/

/*--- Scrollabel-header ----*/
@media (max-width: 991px) {
  .scrollable-layout .responsive-navbar.navbar {
    .navbar-collapse {
      position: absolute !important;
    }

    position: inherit !important;
  }
}
@media (min-width: 992px) {
  .horizontal.scrollable-layout .app-sidebar {
    position: relative;
    max-width: 100% !important;
  }

  .horizontal.scrollable-layout .main-header {
    position: relative;
    max-width: 100% !important;
  }
}
.scrollable-layout {
  .main-header.side-header {
    position: absolute !important;
    max-width: 100% !important;
  }
  .app-sidebar,
  .main-sidebar-header {
    position: absolute;
  }

  .page {
    position: relative;
  }
  .sticky.sticky-pin {
    position: inherit !important;
  }
}

/***********Scroll-layout Styles****************/

/***********Horizontal Styles****************/
@media (min-width: 992px) {
  .centerlogo-horizontal {
    &.horizontal {
      .hor-header {
        .main-header-left {
          .responsive-logo {
            display: block;
            position: absolute;
            inset-inline-start: 0;
            inset-inline-end: 0;
            margin: 0 auto;
            text-align: center;
            top: 17px;
          }
        }
      }
    }
  }
}
/***********switcher-styles****************/


// ----- Sidemenu -------//
.app-sidebar .mCSB_draggerContainer {
  inset-inline-end: -11px !important;
}

@media (min-width: 992px) {
  .app.sidenav-toggled {
    .side-menu__item {
      padding-block-start: 2.5px !important;
      padding-inline-end: 8px !important;
      padding-block-end: 3px !important;
      padding-inline-start: 8px !important;
      border-radius: 5px !important;
  }
    .app-content {
      margin-inline-start: 80px;
    }

    .app-sidebar {
      inset-inline-start: 0;
      width: 80px;
      overflow: hidden;
    }

    .app-sidebar__overlay {
      visibility: hidden;
    }
    .slide.is-expanded .slide-menu {
      display: none;
    }
  }
  .side-badge {
    display: none;
  }
  .side-menu{
    margin-block-start: 15px;
  }

  .side-header {
    width: 240px;
    inset-inline-start: 0;
    inset-inline-end: 0;
    top: 0;
    position: fixed;
    z-index: 1;
  }
  .sidebar-mini.sidenav-toggled-open {
      .side-menu{
        margin-block-start: 15px;
      }

      .sidebar-navs {
        display: block !important;
      }
      .nav.nav-pills-circle{
        justify-content: center;
      }
      .side-menu__item {
        padding-block-start: 2.5px !important;
        padding-inline-end: 22px !important;
        padding-block-end: 3px !important;
        padding-inline-start: 5px !important;
        margin-block-start: 5px !important;
        margin-inline-end: 8px !important;
        margin-block-end: 12px !important;
        margin-inline-start: 8px !important;
        border-radius: 50px !important;
      }
      .user-pic {
        margin-block-end: 0.8rem;
      }
      .app-sidebar__user-name{
        display: block;
      }
       .app-sidebar__user-name,  .avatar-xl {
        line-height: 1.3;
        white-space: normal;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        margin-block-end: 0;
        overflow: hidden;
        color: #e5e9ec;
        font-size: 12px;
        margin-block-start: 5px !important;
        width: 15rem;
        height: 1rem;
    }
    .app-sidebar__user{
      border-block-end: 1px solid $border;
    }
  }
  .app-sidebar .latest-timeline .timeline:not(.timeline--horizontal):before {
      inset-inline-start: 5%;
  }
}

@media (max-width: 991px) {
  .app-sidebar {
      z-index: 1029 !important;
      top: 63px !important;
  }
  .app {
    overflow-x: hidden;

    .app-sidebar {
      inset-inline-start: -240px;
    }

    .app-sidebar__overlay {
      visibility: hidden;
    }

    &.sidenav-toggled {
      .app-content {
        margin-inline-start: 0;
      }

      .app-sidebar {
        inset-inline-start: 0;
      }

      .app-sidebar__overlay {
        visibility: visible;
      }
    }

    &.sidebar-gone.sidenav-toggled .app-sidebar {
      inset-inline-start: 0;
    }
  }
  .app.sidenav-toggled .side-menu .side-menu__icon {
    margin-inline-end: 13px !important;
    margin-inline-start: 0;
  }
  .sidebar-mini ,.sidebar-gone{
    .side-menu {
      margin-inline: 0 !important;
    }
  }
}

.app-content {
  min-height: calc(77vh - 50px);
  margin-block-end: 0 !important;
  -webkit-transition: margin-inline-start 0.3s ease;
  -o-transition: margin-inline-start 0.3s ease;
  transition: margin-inline-start 0.3s ease;

  .side-app {
    padding: 0px 30px 0 30px;
  }
}

.footer .container {
  width: 100%;
  padding-inline-end: 0.75rem;
  padding-inline-start: 0.75rem;
  margin-inline-end: auto;
  margin-inline-start: auto;
}

@media (min-width: 992px) {
  .app.sidenav-toggled .avatar-xl {
    width: 55px !important;
    height: 55px !important;
  }

  .app.sidebar-mini.sidenav-toggled .logo-icon.icon-light {
    display: block;
  }

  .app.sidebar-mini.sidenav-toggled .logo-icon {
    text-align: center;
  }

  .app.sidebar-mini.sidenav-toggled .main-sidebar-header .logo-icon.icon-dark {
    display: none;
  }

  .app.sidebar-mini .main-sidebar-header .logo-icon {
    display: none;
  }

  .app.sidebar-mini.sidenav-toggled .main-sidebar-header .main-logo {
    display: none;
  }

  .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .main-sidebar-header .main-logo {
    display: block;
  }

  .app.sidebar-mini .main-semi-white {
    display: none;
  }

  .app.sidebar-mini .desktop-semilogo {
    display: none;
  }

  .app.sidebar-mini.sidenav-toggled .side-menu .side-menu__icon {
    line-height: 19px;
  }

  .app.sidebar-mini.sidenav-toggled .side-item.side-item-category {
    display: none;
  }

  .app.sidebar-mini.sidenav-toggled .side-item.side-item-category {
    display: none;
  }

  .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-item.side-item-category {
    display: block;
  }
}

@media (max-width: 991px) {
  .app-content {
    min-width: 100%;
  }
}

@media print {
  .app-content {
    margin: 0;
    padding: 0;
    background-color: $white;
  }
}

.light-text {
  font-weight: 300 !important;
}

.semibold-text {
  font-weight: 600 !important;
}

.line-head {
  padding-block-end: 10px;
  border-block-end: 1px solid $border;
}

/*----- Componant: Top Navigation Bar ----- */

.app-header {
  position: fixed;
  inset-inline-start: 0;
  inset-inline-end: 0;
  top: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 999;
  padding-inline-end: 15px;
  padding: 5px 0;
  box-shadow: 0 2px 17px 2px $black-2;
  border-block-end: 1px solid $white-1;
  background: #5e2dd8;
}

@media (min-width: 992px) {
  .app-header {
    padding-inline-end: 30px;
  }
}

@media print {
  .app-header {
    display: none;
  }
}

.app-header__logo {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  color: $white;
  text-align: center;
  font-family: "Niconne";
  padding: 0 15px;
  font-size: 26px;
  font-weight: 400;
  line-height: 50px;

  &:focus,
  &:hover {
    text-decoration: none;
  }
}

@media (min-width: 992px) {
  .app-header__logo {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    display: block;
    width: 230px;
  }
}

.app-sidebar__toggle {
  color: $white;
  -webkit-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
  z-index: 1;
  margin-inline-end: 10px;
  display: block;
  font-size: 22px;
  color: #272746;
  position: relative;
  line-height: 35px;
  outline: none;
  height: 40px;
  width: 40px;
  background: transparent;
  margin: auto;
  text-align: center;
  border-radius: 50%;

  &:hover {
    color: $white;
  }

  &:focus,
  &:hover {
    text-decoration: none;
  }
}
@media (max-width: 991px) {
  .app-sidebar__toggle {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .main-sidebar-header {
    display: none !important;
  }

  .main-sidemenu {
    margin-block-start: 0 !important;
  }
}

.app-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-block-end: 0;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

@media (min-width: 992px) {
  .app-nav {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
  }
}

.app-nav__item {
  display: block;
  padding: 15px;
  line-height: 20px;
  color: #a8a8a8;
  -webkit-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;

  &:hover,
  &:focus {
    background: $black-1;
    color: #f6f6f6;
  }
}

.app-search {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: center;
  align-self: center;
  margin-inline-end: 15px;
  padding: 10px 0;
}

@media (max-width: 480px) {
  .app-search {
    display: none;
  }
}

.app-search__input {
  border: 0;
  padding: 5px 10px;
  padding-inline-end: 30px;
  border-radius: 2px;
  background-color: $white-8;
  -webkit-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;

  &::-webkit-input-placeholder,
  &:-ms-input-placeholder,
  &::-ms-input-placeholder,
  &::placeholder {
    color: $black-4;
  }
}

.app-search__button {
  position: absolute;
  inset-inline-end: 0;
  top: 10px;
  bottom: 10px;
  padding: 0 10px;
  border: 0;
  color: $black-8;
  background: none;
  cursor: pointer;
}

.app-notification {
  min-width: 270px;
}

.app-notification__title {
  padding: 8px 20px;
  text-align: center;
  background-color: rgba(0, 150, 136, 0.4);
  color: #333;
}

.app-notification__footer {
  padding: 8px 20px;
  text-align: center;
  background-color: #eee;
}

.app-notification__content {
  max-height: 220px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: $black-2;
  }
}

.app-notification__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 20px;
  color: inherit;
  border-block-end: 1px solid $border;
  -webkit-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;

  &:focus,
  &:hover {
    color: inherit;
    text-decoration: none;
    background-color: #e0e0e0;
  }
}

.app-notification__message,
.app-notification__meta {
  margin-block-end: 0;
}

.app-notification__icon {
  padding-inline-end: 10px;
}

.app-notification__message {
  line-height: 1.2;
}

.app-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  inset-inline-start: 0;
  color: $default-color;
  width: 240px;
  max-height: 100%;
  z-index: 1024;
  background: $white;
  -webkit-box-shadow: 0px -1px 14.72px 1.28px rgba(229, 228, 230, 0.5);
  box-shadow: 0px -1px 14.72px 1.28px rgba(229, 228, 230, 0.5);
  border-inline-end: 1px solid $border;
  transition: left 0.3s ease, width 0.3s ease;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: $black-2;
  }
}

@media print {
  .app-sidebar {
    display: none;
  }
}

@media (max-width: 991px) {
  .app-sidebar__overlay {
    position: fixed;
    top: 0;
    inset-inline-start: 0;
    bottom: 0;
    inset-inline-end: 0;
    z-index: 9;
  }
}

.app-sidebar__user {
  .dropdown-menu {
    top: 10px !important;
  }

  img {
    box-shadow: 0 0 25px $white-1;
    border: 2px solid rgb(255, 255, 255);
    box-shadow: 0px 5px 5px 0px rgba(44, 44, 44, 0.2);
  }

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  display: inline-block;
  padding: 15px;
  border-block-end:1px solid $border;
}

.app-sidebar__user-avatar {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-inline-end: 15px;
}

.app-sidebar__user-name {
  &.text-sm {
    font-size: 12px;
    font-weight: 400;
  }

  font-size: 17px;
  line-height: 1.3;
  white-space: normal;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  margin-block-end: 0;
  overflow: hidden;
  font-weight: 600;
  color: #e5e9ec;
  font-size: 15px;
  margin-block-start: 5px !important;
}

.app-sidebar__user-designation {
  white-space: normal;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  margin-block-end: 0;
  overflow: hidden;
  font-weight: 600;
  color: #e5e9ec;
  font-size: 15px;
  margin-block-start: 5px !important;
}

.side-menu {
  margin-block-end: 0;
  padding: 0;
  list-style: none;
}

slide is-expanded side-menu__item {
  background: rgba(238, 238, 247, 0.9);
}

.slide {
  margin: 0;
}

.side-menu__item {
  position: relative;
  display: flex;
  align-items: center;
  padding-block-start: 2.5px !important;
  padding-inline-end: 22px !important;
  padding-block-end: 3px !important;
  padding-inline-start: 5px !important;
  font-size: 14px;
  font-weight: 400;
  transition: border-inline-start-color 0.3s ease, background-color 0.3s ease;
  color: #1a1630;
  margin-block-start: 8px !important;
  margin-inline-end: 12px !important;
  margin-block-end: 8px !important;
  margin-inline-start: 12px !important;
  border-radius: 50px;

  &.active,
  &:hover,
  &:focus {
    text-decoration: none;
    background: #ebeef1;
    color: #1a1630;
  }

  &.active .angle {
    color: $primary !important;
  }
}

@media (min-width: 992px) {
  .app.sidebar-mini.sidenav-toggled .profile-status {
    inset-inline-end: 8px;
    top: 44px;
  }

  .app.sidenav-toggled .side-menu__label {
    display: none !important;
    position: relative;
    padding: 0;
    margin: 0;
    inset-inline-start: 0;
    color: $white-8;
    opacity: 1;
    background: transparent;
    font-size: 12px;
    box-shadow: none;
  }
  .sidebar-mini.sidenav-toggled .sidebar-navs {
    display: none;
  } 
  .app.sidebar-mini.sidenav-toggled .side-menu__item {
    display: block;
    padding: 10px 0;
    margin: 0 auto;
    text-align: center;
    border-left: 0;
    border-radius: 0;
    margin-block-end: 5px !important;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .sidebar-navs {
      display: block;
  }
}

.slide-item {
  &.active,
  &:hover,
  &:focus {
    text-decoration: none;
    color: $primary !important;

    &::before {
      color: $primary !important;
    }
    .sub-angle {
      color: $primary !important;
      opacity: 1;
    }
  }
}
.sub-side-menu__item {
  &.active,
  &:hover,
  &:focus {
    text-decoration: none;
    color: $primary !important;

    &::before {
      color: $primary !important;
    }
    .sub-angle {
      color: $primary !important;
      opacity: 1;
    }
  }
}

.slide-menu {
  .sub-slide-menu {
    padding: 0 5px;
  }
}

.sub-slide .sub-angle {
  margin-inline-start: auto;
  float: $float-right;
  font-size: 12px;
  margin-block-start: 3px;
}

.slide-menu li {
  position: relative;
}

.slide.is-expanded {
  a {
    color: #6d7790;
    text-decoration: none;
  }

  .sub-side-menu__item:before {
    content: "\e0e7";
    font-family: "typicons" !important;
    position: absolute;
    top: 9px;
    inset-inline-start: 20px;
    font-size: 9px;
  }
}

.side-menu .side-menu__icon {
  font-size: 25px;
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
    margin-inline-end: 12px;
    width: 37.5px;
    height: 37.5px;
    background: #fff;
    padding: 8px;
    border-radius: 50px;
    box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
    border: 1px solid #ebecf1;
}

.side-menu__icon {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 25px;
}

.side-menu__label {
  white-space: nowrap;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  color: #5b6e88;
  position: relative;
  font-size: 13.5px;
  line-height: 1;
  vertical-align: middle;
  font-weight: 400;
}

.app-sidebar .side-item.side-item-category {
  color: #2c364c;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.5px;
  margin-block-end: 12px;
  height: 15px;
  padding-inline-start: 25px;
  padding-inline-end: 20px;
}

.app-sidebar .side-item.side-item-category:not(:first-child) {
  margin-block-start: 25px;
}

.slide-menu {
  display: none;
  padding-inline-start: 37px;
  list-style: none;
}

.slide.is-expanded .slide-menu {
  display: block;
}

.slide-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 13px;
  padding-block: 8px;
  padding-inline-start: 28px;
  padding-inline-end: 20px;
  font-weight: 400;
  color: #6d7790;

  .icon {
    margin-inline-end: 5px;
  }
}

.angle {
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

@media (max-width: 848px) {
  .profile-user .p-text {
    display: none;
  }
}

@media (min-width: 992px) {
  .sidenav-toggled {
    .app-sidebar__user-name,
    .app-sidebar__user-designation,
    .angle,
    .app-sidebar__user-name,
    .user-notification,
    .app-sidebar__user-name,
    .user-info {
      display: none;
    }

    .sidenav-toggled.user-notification::before {
      background: transparent;
      display: none;
    }

    .app-sidebar__user-name,
    .avatar-xl {
      width: 3rem;
      height: 3rem;
      line-height: 3rem;
      font-size: 1rem;
      margin-block-end: 0px !important;
    }

    .app-sidebar__user,
    .side-menu {
      margin-block-start: 0px;
    }

    .app-sidebar__user-avatar {
      width: 25px;
      height: 25px;
    }

    .app-sidebar__user {
      padding: 12px 0px 10px 0;
      margin-block-end: 0px;
      border-block-end: 0;
    }
    .profile-img {
      top: 0px;
      inset-inline-end: 19px;
    }

    .app-content {
      margin-inline-start: 0;
    }

    .app-sidebar {
      inset-inline-start: 0;

      &:hover {
        overflow: visible;
      }
    }

    .side-menu__item {
      overflow: hidden;

      &:hover {
        overflow: visible;

        .side-menu__label {
          opacity: 1;
        }

        + .slide-menu {
          visibility: visible;
        }
      }
    }

    .side-menu__label {
      display: block;
      position: absolute;
      top: 0;
      inset-inline-start: 50px;
      padding: 12px 5px 12px 20px;
      margin-inline-start: -3px;
      line-height: 1;
      opacity: 0;
      background: $white;
      color: #a8a8a8;
      border-start-end-radius: 4px;
      border-end-end-radius: 4px;
      -webkit-box-shadow: 0px 8px 17px $black-2;
      box-shadow: 0px 8px 17px $black-2;
    }

    .slide {
      &:hover {
        .side-menu__label {
          opacity: 1;
        }

        .slide-menu {
          max-height: 100%;
          opacity: 1;
          visibility: visible;
          z-index: 10;
        }
      }

      .side-menu__label {
        border-end-end-radius: 0;
      }
    }

    .slide-menu {
      position: absolute;
      inset-inline-start: 90px;
      min-width: 180px;
      opacity: 0;
      border-end-end-radius: 4px;
      z-index: 9;
      visibility: hidden;
      -webkit-transition: visibility 0.3s ease;
      -o-transition: visibility 0.3s ease;
      transition: visibility 0.3s ease;
      -webkit-box-shadow: 0px 8px 17px $black-2;
      box-shadow: 0px 8px 17px $black-2;
    }
  }

  .app.sidenav-toggled {
    .side-menu__item {
      padding: 10px 0 13px 0;
      margin: 0 auto;
      display: block;
      text-align: center;
      border-inline-start: 0;
    }

    .side-menu_label {
      display: block;
      font-size: 12px;
    }

    .side-menu__label {
      display: block;
      position: relative;
      padding: 0;
      margin: 0;
      inset-inline-start: 0;
      color: #5c6287;
      opacity: 1;
      background: transparent;
      font-size: 12px;
      box-shadow: none;
    }
  }

  .sidenav-toggled .app-sidebar__user .avatar-md {
    margin: 0 auto;
  }

  .app.sidenav-toggled .nav-badge {
    position: absolute;
    top: 8px;
    inset-inline-end: 28px;
    padding: 0.2rem 0.4rem;
    font-size: 11px;
  }
}

.app {
  &.sidenav-toggled .side-menu .side-menu__icon {
    margin-inline-end: 0;
    margin-inline-start: 0;
  }

  .side-menu_label {
    display: none;
  }
}

.dropdown-menu {
  // border-radius: 0;

  &.dropdown-menu-right {
    inset-inline-start: auto;
  }
}

.dropdown-item {
  .fa,
  .icon {
    vertical-align: middle;
  }
}

.app-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  background-color: $white;
  margin: -30px -30px 30px;
  padding: 20px 30px;
  -webkit-box-shadow: 0 1px 2px $black-1;
  box-shadow: 0 1px 2px $black-1;

  h1 {
    margin: 0;
    font-size: 24px;
    font-weight: 400;
  }

  p {
    margin-block-end: 0;
    font-style: italic;
  }
}

@media print {
  .app-title {
    display: none;
  }
}

@media (max-width: 480px) {
  .app-title {
    margin: -15px -15px 15px;
    padding: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}

@media (max-width: 480px) {
  .app-title p {
    display: none;
  }
}

.app-breadcrumb {
  margin-block-end: 0;
  text-align: end;
  font-weight: 500;
  font-size: 13px;
  text-transform: capitalize;
  padding: 0;
  text-align: start;
  padding: 0;
  background-color: transparent;
}

@media (max-width: 480px) {
  .app-breadcrumb {
    margin-block-start: 10px;
  }
}

.tile {
  position: relative;
  background: $white;
  border-radius: 3px;
  padding: 20px;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px $black-2;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px $black-2;
  margin-block-end: 30px;
  -webkit-transition: left 0.3s ease, width 0.3s ease;
  -o-transition: left 0.3s ease, width 0.3s ease;
  transition: left 0.3s ease, width 0.3s ease;
}

@media print {
  .tile {
    border: 1px solid $border;
  }
}

.sidenav-toggled .app-sidebar__user .avatar-md {
  line-height: 2rem;
  font-size: 1rem;
}

.app-sidebar {
  .mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background: $black-05;
  }

  .mCSB_scrollTools {
    .mCSB_dragger .mCSB_dragger_bar,
    .mCSB_draggerRail {
      background: $white-2;
    }
  }
}

.sidenav-toggled .main-wrapper .side-menu .side-menu__item {
  .nav-badge {
    position: absolute;
    top: 5px;
    inset-inline-start: 57px;
    display: block !important;
    padding: 3px 5px !important;
  }

  .nav-badge1 {
    display: none;
  }
}

.nav-badge {
  border-radius: 30px;
  padding: 0.4em 0.6em;
  font-size: 12px;
}

.user-info {
  .text-dark {
    color: $white !important;
    font-weight: 400;
    font-size: 16px;
  }

  .text-muted {
    color: $white-4 !important;
  }
}

.side-header {
  .header-brand1 {
    text-align: center;
    display: table;
  }

  .header-brand-img.desktop-logo {
    max-height: 2.5rem;
    text-align: center;
    display: block;
    margin-inline-end: 0;
  }
}

.sidenav-toggled .app-sidebar .side-header .header-brand-img.desktop-logo,
.side-header .header-brand-img.toggle-logo {
  display: none;
}

.sidenav-toggled {
  .app-sidebar .side-header {
    .header-brand-img.toggle-logo {
      display: none;
      margin-inline-end: 0;
    }
  }

  .user-pic {
    margin-block-end: 0;
  }
}

.side-header {
  display: flex;
  border-block-end: 1px solid $border;
  -webkit-transition: left 0.3s ease;
  -o-transition: left 0.3s ease;
  transition: left 0.3s ease;
}

.sidenav-toggled .app-sidebar__toggle {
  display: block;
}

.user-pic {
  margin-block-end: 0.8rem;
}

.side-menu h3 {
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 1px;
  opacity: 1;
  padding: 20px 34px 10px;
  text-transform: capitalize;
  width: 100%;
  position: relative;
  color: #90909e;
  position: relative;

  &:after {
    content: "--";
    position: absolute;
    inset-inline-start: 21px;
    top: 20px;
  }
}

.sidenav-toggled .side-menu h3 {
  display: none;
}

.slide.is-expanded .slide-menu,
.sub-slide {
  display: block;
}

/*-- Sub-slide--**/

.sub-side-menu__item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 7px 0;
  font-size: 12px;
  color: #6d7790;
}

.sub-side-menu__label {
  white-space: normal;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  font-weight: 400;
}

.sub-slide {
  .sub-angle {
    transform-origin: center;
    opacity: 0.5;
    transition: transform 0.3s ease;
  }

  &.is-expanded .sub-angle {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
.sub-slide2 {
  .sub-angle2 {
    transform-origin: center;
    opacity: 0.5;
    transition: transform 0.3s ease;
  }

  &.is-expanded .sub-angle2 {
    transform: rotate(180deg);
  }
}

.sub-slide-menu {
  list-style: none;
  padding: 0;
}

.sub-slide-item {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  list-style: none;
  padding: 8px 0 8px 25px !important;
}

.sub-slide-menu {
  display: none;
  font-size: 0.8rem !important;
  padding-inline-start: 0;
  list-style: none;
}

.sub-slide.is-expanded {
  .sub-slide-menu {
    display: block;
  }
}

.slide-menu .sub-slide.is-expanded {
  display: block;
}

.sub-side-menu__item {
  padding-inline-start: 40px !important;
  padding-inline-end: 15px !important;
}

.sub-slide-item {
  padding-inline-start: 42px !important;
}

.app-sidebar {
  .side-menu__item.active:hover {
    color: #7282a9 !important;
  }

}

.side-menu .slid.active .sub-slide.is-expanded .sub-slide-menu {
  max-height: 0;
}

.app-sidebar .slide-menu a:before {
  content: "\e0e7";
  font-family: "typicons" !important;
  position: absolute;
  inset-inline-start: 0px;
  font-size: 9px;
  color: #6d7790;
}

.sub-slide-menu1 .sub-slide-item2:before {
  content: "\e0e7";
  font-family: "typicons" !important;
  position: absolute;
  inset-inline-start: 29px !important;
  font-size: 9px;
  color: #6d7790;
  top: 7px !important;
  font-weight: 600;
}

.app-sidebar__toggle .close-toggle,
.sidebar-mini.sidenav-toggled .open-toggle {
  display: none;
}

.sidebar-mini.sidenav-toggled .close-toggle {
  display: block;
  color: #71829b;
  font-size: 18px;
  line-height: 42px;
  text-align: center;
}

.sidenav-toggled .app-content {
  transition: margin-inline-start 0.3s ease;
}

.sub-slide-menu .active .sub-slide-item.active {
  color: $primary;
}

.app-sidebar__user .user-info .text-muted {
  color: $muted !important;
  font-size: 13px;
}

.app-sidebar .slide-menu a.active:before {
  color: $primary;
}

@media (min-width: 992px) {
  .app.sidebar-mini.sidenav-toggled-open .app-sidebar {
    inset-inline-start: 0;
    width: 240px;
  }

  .app.sidebar-mini.sidenav-toggled-open .side-menu__item {
    display: flex;
    align-items: center;
    padding: 12px 14px;
    margin: 2px 0;
  }

  .app.sidebar-mini.sidenav-toggled-open .side-menu .slide .side-menu__item {
    padding-inline-start: 22px;
    padding-inline-end: 20px;
    padding-block: 10px;
    margin: 0;
    text-align: start;
    border-radius: 0;
  }

  .app.sidebar-mini.sidenav-toggled-open .side-menu__label {
    white-space: normal;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    opacity: 1;
    display: block !important;
    position: initial;
    font-size: 0.875rem;
    line-height: 1;
    font-weight: 400;
  }

  .app.sidebar-mini.sidenav-toggled-open .angle {
    display: block;
  }

  .app.sidebar-mini.sidenav-toggled-open .avatar-xl {
    width: 72px !important;
    height: 72px !important;
    font-size: 36px !important;
  }

  .app.sidebar-mini.sidenav-toggled-open .app-sidebar__user .user-info {
    margin: 0 auto;
    display: block !important;
    text-align: center;
  }

  .app.sidebar-mini.sidenav-toggled-open .side-menu .side-menu__icon {
    margin-inline-end: 12px;
  }

  .app.sidebar-mini.sidenav-toggled-open .ps > .ps__rail-y > .ps__thumb-y {
    inset-inline-start: 1px;
    background-color: #e9eef7;
  }

  .app.sidebar-mini.sidenav-toggled-open .main-sidebar-header {
    width: 240px !important;
  }

  .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .logo-icon {
    display: none !important;
  }

  .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .desktop-logo.logo-light {
    display: block;
  }

  .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .profile-status {
    inset-inline-end: 10px;
    top: 58px;
  }

  .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .desktop-logo.logo-dark {
    display: none;
  }

  .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-badge {
    display: block !important;
  }

  .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide-menu {
    inset-inline-start: 0;
  }

  .sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-menu__item:hover + .slide-menu {
    visibility: visible;
    opacity: inherit;
  }

  .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide.is-expanded .slide-menu {
    display: block;
    box-shadow: none;
  }

  .sidenav-toggled .slide-menu {
    box-shadow: none !important;
  }

  .sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide-menu {
    position: inherit;
    visibility: visible;
    opacity: inherit !important;
    z-index: 0;
    cursor: pointer;
  }
}

@media (min-width: 991px) and (max-width: 992px) {
  .main-sidebar-header .main-logo {
    display: block;
  }
}

@media (max-width: 991px) {
  .main-body.app.sidebar-mini.sidebar-gone.sidenav-toggled .desktop-logo.logo-light {
    display: none !important;
  }
  .main-body.app.sidebar-mini.sidebar-gone.sidenav-toggled.sidenav-toggled-open .desktop-logo.logo-light {
    display: block !important;
  }
}

@media (max-width: 991px) and (min-width: 992px) {
  .desktop-logo {
    height: auto !important;
    text-align: inherit !important;
    margin: 0 !important;
    justify-content: inherit !important;
  }
}

@media only screen and (max-width: 991px) {
  .app .desktop-logo {
    margin-inline-start: 0 !important;
  }
  .main-body.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .main-header form[role="search"].active input {
    padding-inline-start: 250px;
    transition: none;
  }
  .main-body.app.sidebar-mini.sidenav-toggled .main-header form[role="search"].active input {
    padding-inline-start: 90px;
  }
  .main-body.app.sidebar-mini.sidebar-gone .main-header form[role="search"].active input {
    padding-inline-start: 20px;
  }
  .main-body.app.sidebar-mini .main-header form[role="search"].active input {
    padding-inline-start: 250px;
  }
}

.slide.is-expanded .angle {
  transform: rotate(180deg);
}

.sub-slide.is-expanded .sub-slide-sub.is-expanded .sub-angle {
  transform: rotate(180deg);
}

.sub-slide.is-expanded .sub-slide-sub .sub-angle {
  transform: rotate(0deg);
}

.sub-slide-menu-sub {
  display: none;
  font-size: 0.8rem !important;
  padding-inline-start: 0;
  list-style: none;
  padding: 0 5px;
}

.sub-slide.is-expanded .sub-side-menu__item:hover {
  color: $primary;
}

.slide.is-expanded .sub-slide-item:hover {
  color: $primary;
}

.sub-slide-menu-sub .sub-slide-item {
  padding-inline-start: 55px !important;
}

.app-sidebar .slide-menu .sub-slide-menu .sub-slide-menu-sub a:before {
  inset-inline-start: 35px;
}

@media (min-width: 992px) {
  .app.sidenav-toggled .side-menu__item .badge {
    display: none;
  }

  .app.sidenav-toggled.sidenav-toggled-open .side-menu__item .badge {
    display: block;
  }
}

.side-menu__label1 {
  display: none !important;
}

.sub-slide-menu1 .sub-slide-item2 {
  padding-block: 6px;
  padding-inline-start: 48px;
  padding-inline-end: 0;
  display: block;
  font-size: 12px;
  color: #6d7790;
}

.sub-slide-menu1 .sub-slide-item2:hover {
  color: $primary !important;
}

.sub-slide2.is-expanded .sub-slide-menu1 {
  display: none;
}

.slide-menu .sub-slide-menu1 {
  padding: 0 10px;
}

.sub-slide-menu1 {
  display: none;
  font-size: 0.8rem !important;
  padding-inline-start: 0;
  list-style: none;
}

.sidebar-mini .slide-left,
.sidebar-mini .slide-right {
  display: none !important;
}

.logo-icon {
  img {
    height: 2.3rem;
  }
}
.sidebar-mini {
  .side-menu {
    margin-inline: 0 !important;
  }
}

// ----- CLOSED MENU -------//
.closed-menu {
  @media (min-width: 992px) {
    &.app.sidenav-toggled .app-sidebar {
      display: none !important;
    }

    &.app.sidenav-toggled {
      .app-content {
        margin-inline-start: 0px !important;
      }

      .main-header {
        padding-inline-start: 0px !important;
      }
    }
  }
}
// ----- ! CLOSED MENU -------//

// ----- ICON TEXT -------//
.icontext-menu {
  @media (min-width: 992px) {
    &.app.sidenav-toggled .side-menu .side-menu__icon {
      margin-block-end: 5px;
    }

    &.app.sidenav-toggled .side-menu__label {
      display: block !important;
      font-size: 13.5px !important;
      position: relative !important;
    }

    &.app.sidenav-toggled.sidenav-toggled-open .side-menu__label {
      font-size: 0.875rem !important;
    }

    &.app.sidenav-toggled {
      .side-menu__label {
        font-size: 12px !important;
      }

      .app-sidebar {
        width: 105px !important;
      }

      .main-sidebar-header {
        width: 105px !important;
      }

      .main-header {
        padding-inline-start: 105px !important;
      }

      .app-content {
        margin-inline-start: 105px;
      }
    }

    &.app.sidebar-mini.sidenav-toggled-open {
      .app-sidebar {
        width: 240px !important;
      }

      .main-sidebar-header {
        width: 240px !important;
      }

      .close-toggle {
        display: none !important;
      }
    }
    &.sidenav-toggled .side-menu__item {
      padding-block-start: 7px !important;
      padding-inline-end: 10px !important;
      padding-block-end: 7px !important;
      padding-inline-start: 5px !important;
      border-radius: 5px !important;
      margin: 0px !important;
    }

    &.sidebar-mini.sidenav-toggled-open .side-menu .slide .side-menu__item.active {
      width: inherit;
      height: inherit;
    }
  }
}
// ----- ! ICON TEXT -------//

// ----- HOVER SUBMENU -------//
.hover-submenu {
  @media (min-width: 992px) {
    &.sidenav-toggled-open .slide-menu {
      border-radius: 0px 7px 7px 0px !important;
    }
   &.sidebar-mini.sidenav-toggled-open .app-sidebar__user{
    border-block-end: 0px !important;
   }
    &.sidebar-mini.sidenav-toggled-open .sidebar-navs{
     display: none !important;
    }

    .slide-item {
      padding: 8px 18px 8px 35px;
    }

    .slide-menu a:before {
      inset-inline-start: 15px;
    }

    .slide-menu {
      border-radius: 0px 7px 7px 0px;
    }

    &.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide .slide-menu {
      position: absolute;
      inset-inline-start: 108px;
      min-width: 180px;
      opacity: 1;
      z-index: 9999;
      visibility: hidden;
      top: 0;
      display: block;
      max-height: inherit;
    }

    .app-sidebar .slide {
      position: relative;
      display: block;
    }

    &.app.sidenav-toggled-open {
      .ps > .ps__rail-y {
        display: none !important;
      }

      .slide-menu {
        padding-inline-start: 0 !important;
        padding: 10px 0px !important;
        position: absolute;
        background: $white;
        opacity: 1;
        visibility: visible;
        z-index: 999;
        width: 200px;
        inset-inline-start: 110px;
        border: 1px solid $border;
        transition: none !important;
        display: block !important;
        box-shadow: 8px 8px 17px $black-1;
        top: 0;
      }

      .slide:hover .slide-menu {
        visibility: visible;
        display: block !important;
        height: auto !important;
      }

      .app-sidebar {
        overflow: visible !important;
        position: absolute !important;
      }

      .side-menu__label {
        white-space: nowrap;
        display: block;
        position: relative;
        padding: 0;
        margin: 0;
        inset-inline-start: 0;
        color: #7b8191;
        opacity: 1;
        background: transparent;
        font-size: 12px;
        box-shadow: none;
      }

      .side-menu .side-menu__icon {
        margin-block-end: 5px !important;
      }

      .side-menu .slide .side-menu__item.active {
        border-inline-end: 0 !important;
        padding: 10px;
      }

      .main-sidebar-header {
        .desktop-logo,
        .desktop-dark,
        .mobile-dark {
          display: none !important;
        }

        .mobile-logo {
          display: block !important;
        }
      }

      .side-item.side-item-category {
        display: none !important;
      }

      .side-menu__icon {
        margin-inline-end: 0;
        margin-inline-start: 0;
        margin: 0 auto !important;
      }

      .side-menu .slide .side-menu__item.active {
        display: block;
        margin: 0 auto ;
        border-radius: 0;
        text-align: center !important;
      }

      .side-menu .slide .side-menu__item {
        display: block;
        margin: 0 auto !important;
        border-radius: 0;
        text-align: center !important;

        i {
          display: none !important;
        }
      }
    }

    &.app.sidenav-toggled {
      .main-content {
        min-height: 1300px;
      }

      .side-menu__label {
        font-size: 11px !important;
      }

      .app-sidebar {
        position: absolute !important;
      }

      .side-menu .side-menu__icon {
        margin-block-end: 5px;
      }

      .side-menu__label {
        display: block !important;
      }

      .app-sidebar__toggle .close-toggle {
        display: block;
      }

      .main-header {
        padding-inline-start: 110px;
      }

      .main-sidebar-header {
        width: 110px !important;
      }

      .app-content {
        margin-inline-start: 110px;
      }

      .app-sidebar {
        inset-inline-start: 0;
        width: 110px !important;
      }

      .slide.is-expanded .slide-menu {
        display: none;
      }
    }

    .side-badge {
      display: none;
    }

    .side-header {
      width: 240px;
      inset-inline-start: 0;
      inset-inline-end: 0;
      top: 0;
      position: fixed;
      z-index: 1;
    }
  }

  .sub-slide-menu .sub-side-menu__item:before {
    inset-inline-start: 25px !important;
    font-size: 9px;
  }

  @media (min-width: 992px) {
    &.app.sidebar-mini {
      &.sidenav-toggled .logo-icon.mobile-logo {
        margin: 0 auto;
        text-align: center;
      }

      &.sidenav-toggled-open .avatar-xl {
        width: 55px !important;
        height: 55px !important;
        margin-block-start: 0px !important;
      }

      &.sidenav-toggled.sidenav-toggled-open .profile-status {
        inset-inline-end: 8px;
        top: 44px;
      }

      &.sidenav-toggled-open {
        .app-sidebar__user .user-info {
          display: none !important;
        }

        .bg-side-text,
        .main-sidebar-header .logo-icon.icon-dark {
          display: none !important;
        }
      }
      &.sidenav-toggled.sidenav-toggled-open .side-menu__item{
        margin-block-end: 5px !important;
      }
      &.sidenav-toggled-open .side-menu{
        margin-block-start: 0px;
      }
    }
    &.sidenav-toggled .side-menu__item,&.sidenav-toggled-open  .side-menu__item{
      padding-block-start: 7px !important;
      padding-inline-end: 10px !important;
      padding-block-end: 7px !important;
      padding-inline-start: 5px !important;
      border-radius: 5px !important;
      margin: 0px !important;
    }
    &.sidenav-toggled-open .user-pic{
      margin-block-end: 0px !important;
    }
  }
}
// -----! HOVER SUBMENU -------//

// ----- HOVER SUBMENU 1 -------//
.hover-submenu1 {
  @media (min-width: 992px) {
    &.sidenav-toggled-open .slide-menu {
      border-radius: 0px 7px 7px 0px !important;
    }
   &.sidebar-mini.sidenav-toggled-open .app-sidebar__user{
    border-block-end: 0px !important;
   }
    &.sidebar-mini.sidenav-toggled-open .sidebar-navs{
      display: none !important;
     }

    &.sidenav-toggled .app-sidebar .slide .side-menu__item.active::before {
      height: 36px;
    }
    &.sidebar-mini.sidenav-toggled-open .user-pic{
      margin-block-end: 0px !important;
    }
    .slide-item {
      padding: 8px 18px 8px 35px;
    }

    .slide-menu a:before {
      inset-inline-start: 15px;
    }

    .slide-menu .side-menu__label1 a:before {
      display: none;
    }

    .slide-menu {
      border-radius: 0px 7px 7px 0px;
    }

    &.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide .slide-menu {
      position: absolute;
      inset-inline-start: 108px;
      min-width: 180px;
      opacity: 1;
      z-index: 9999;
      visibility: hidden;
      top: 0;
      display: block;
      max-height: inherit;
    }

    .app-sidebar .slide {
      position: relative;
      display: block;
    }

    &.app.sidenav-toggled-open {
      .ps > .ps__rail-y {
        display: none !important;
      }

      .slide-menu {
        padding-inline-start: 0 !important;
        padding: 10px 0px !important;
        position: absolute;
        background: $white;
        opacity: 1;
        visibility: visible;
        z-index: 999;
        width: 200px;
        inset-inline-start: 110px;
        border: 1px solid $border;
        transition: none !important;
        display: block !important;
        box-shadow: 8px 8px 17px $black-1;
        top: 0;
      }

      .slide:hover .slide-menu {
        visibility: visible;
        display: block !important;
        height: auto !important;
      }

      .app-sidebar {
        overflow: visible !important;
        position: absolute !important;
      }

      .side-menu__label {
        white-space: nowrap;
        display: block;
        position: relative;
        padding: 0;
        margin: 0;
        inset-inline-start: 0;
        color: #7b8191;
        opacity: 1;
        background: transparent;
        font-size: 12px;
        box-shadow: none;
      }

      .side-menu .side-menu__icon {
        margin-block-end: 2px !important;
        margin-block-start: 2px !important;
      }

      .side-menu .slide .side-menu__item.active {
        border-inline-end: 0 !important;
        padding: 10px;
      }

      .main-sidebar-header {
        .desktop-logo,
        .desktop-dark,
        .mobile-dark {
          display: none !important;
        }

        .mobile-logo {
          display: block !important;
        }
      }

      .side-item.side-item-category {
        display: none !important;
      }

      .side-menu__icon {
        margin-inline-end: 0;
        margin-inline-start: 0;
        margin: 0 auto !important;
      }

      .side-menu .slide .side-menu__item.active {
        display: block;
        margin: 0 auto ;
        border-radius: 0;
        text-align: center !important;
      }

      .side-menu .slide .side-menu__item {
        display: block;
        margin: 0 auto ;
        border-radius: 0;
        text-align: center !important;

        i {
          display: none !important;
        }
      }

      .side-menu__label1 {
        display: block !important;
        font-size: 15px;
        font-weight: 500;
        border-block-end: 1px solid $border;
        padding: 10px 20px !important;
        margin-block-end: 6px;
      }
    }

    &.app.sidenav-toggled {
      .main-content {
        min-height: 1300px;
      }

      .side-menu__label {
        font-size: 11px !important;
      }

      .app-sidebar {
        position: absolute !important;
      }

      .side-menu .side-menu__icon {
        margin-block-end: 2px !important;
        margin-block-start: 2px !important;
      }

      .side-menu__label {
        display: none !important;
      }

      .app-sidebar__toggle .close-toggle {
        display: block;
      }

      .main-header {
        padding-inline-start: 110px;
      }

      .main-sidebar-header {
        width: 110px !important;
      }

      .app-content {
        margin-inline-start: 110px;
      }

      .app-sidebar {
        inset-inline-start: 0;
        width: 110px !important;
      }

      .slide.is-expanded .slide-menu {
        display: none;
      }
    }

    .side-badge {
      display: none;
    }

    .side-header {
      width: 240px;
      inset-inline-start: 0;
      inset-inline-end: 0;
      top: 0;
      position: fixed;
      z-index: 1;
    }
  }

  .sub-slide-menu .sub-side-menu__item:before {
    inset-inline-start: 25px !important;
    font-size: 9px;
  }

  @media (min-width: 992px) {
    &.app.sidebar-mini {
      &.sidenav-toggled .logo-icon.mobile-logo {
        margin: 0 auto;
        text-align: center;
      }

      &.sidenav-toggled-open .avatar-xl {
        width: 55px !important;
        height: 55px !important;
        margin-block-start: 0px !important;
      }

      &.sidenav-toggled.sidenav-toggled-open .profile-status {
        inset-inline-end: 8px;
        top: 44px;
      }

      &.sidenav-toggled-open {
        .app-sidebar__user .user-info {
          display: none !important;
        }

        .bg-side-text,
        .main-sidebar-header .logo-icon.icon-dark {
          display: none !important;
        }
      }
      &.sidenav-toggled.sidenav-toggled-open .side-menu__item{
        margin-block-end: 5px !important;
      }
      &.sidenav-toggled-open .side-menu{
        margin-block-start: 0px;
      }
    }
    &.sidenav-toggled .side-menu__item,&.sidenav-toggled-open  .side-menu__item{
      padding-block-start: 7px !important;
      padding-inline-end: 10px !important;
      padding-block-end: 7px !important;
      padding-inline-start: 5px !important;
      border-radius: 5px !important;
      margin: 0px !important;
    }
    &.sidenav-toggled-open .user-pic{
      margin-block-end: 0px !important;
    }
  }
}
// -----! HOVER SUBMENU 1 -------//

.default-menu, .closed-menu, .icontext-menu, .sideicon-menu, .hover-submenu, .hover-submenu1, .double-menu {
  @media (min-width:992px) {
    &.app .app-sidebar {
      .main-sidemenu {
        #side12, #side13, #side15, #side16, #side18, #side19, #side21, #side22, #side24, #side25, 
        #side27, #side28, #side30, #side31, #side33, #side34, #side36, #side37,  #side39, #side40, #side42,
        #side43{
          display: none !important;
        }
        .tab-content>.tab-pane {
          display: block !important;
        }
      }
    }
  }
}

.double-menu-tabs, .double-menu {
  @media (min-width: 992px) {
    .app-sidebar .slide .side-menu__item.active::before {
      height: 34px;
    }
  }
  .sidebar-navs {
    display: none !important;
  } 
}

@media (max-width:991px) {
  .app .app-sidebar {
    .main-sidemenu {
        #side12, #side13, #side15, #side16, #side18, #side19, #side21, #side22, #side24, #side25, 
        #side27, #side28, #side30, #side31, #side33, #side34, #side36, #side37,  #side39, #side40, #side42,
        #side43{
        display: none !important;
      }
      .tab-content>.tab-pane {
        display: block !important;
      }
    }
  }
}

.app .app-sidebar {
  .main-sidemenu {
    .tab-content {
      overflow: initial;
      display: block;
    }
  }
}

.tab-content-double{
  display: none !important;
}

.tab-content-show{
  display: block !important;
}
.user-pro-body .main-img-user {
  display: inline-block;
  position: relative;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  text-align: center;
  margin: 0 auto;
}
.app-sidebar__user .user-pro-body {
  display: block;
  text-align: center;
}
.app-sidebar__user img{
  border-radius: 50px;
}
.app.sidebar-mini .desktop-logo.logo-dark {
  display: none;
}
.main-sidebar-header {
  height: 64px;
  padding: 15px 20px;
  width: 240px;
  background: $white;
  border-block-end: 1px solid $border;
  position: fixed;
  border-inline-end: 1px solid $border;
  z-index: 999;
  top: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  transition: left 0.3s ease, width 0.3s ease;
}
.main-sidemenu {
  margin-block-start: 64px;
}
.sidebar-navs a {
  background: $white;
  color: #68798b !important;
  border-radius: 50px;
  padding: 0.5rem !important;
  margin-block-start: 10px;
  margin-inline-end: 5px;
  margin-block-end: 0px;
  margin-inline-start: 8px;
  border-radius: 50px;
  box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
  border: 1px solid $border;
}
.sidebar-navs .header-icons {
  color: #5c678f;
  fill: #5c678f;
  font-size: 20px;
  vertical-align: middle;
}
.icon i {
  vertical-align: -1px;
  color: #5c678f;
}
// ----- Sidemenu -------//
//
// Google font
//

/*-------- Bootstrap Framework -------*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@600&family=Red+Hat+Display:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@600&family=Red+Hat+Display:ital,wght@0,700;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@600&family=Red+Hat+Display:ital,wght@0,400;0,700;1,500;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@600&family=Red+Hat+Display:ital,wght@0,400;0,500;0,700;1,500;1,600&display=swap');

/*----- Avatars -----*/

.country-selector .avatar {
	width: 1.4rem;
	height: 1.2rem;
  }
  
.avatar {
	position: relative;
	width: 36px;
	height: 36px;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $white;
	font-weight: 600;
	font-size: 16px;
	background-color: $primary;
  } 
  
  .noborder {
	border-radius: 0;
  }
  
  .brround {
	border-radius: 50%;
  }
  
  .bradius {
	border-radius: 25%;
  }
  
  .avatar i {
	font-size: 125%;
	vertical-align: sub;
  }
  
  .avatar-status {
	position: absolute;
	inset-inline-end: -2px;
	bottom: -2px;
	width: .75rem;
	height: .75rem;
	border: 2px solid $white;
	background: $gray;
	border-radius: 50%;
  }
  
  .avatar-xs {
	width: 24px !important;
	height: 24px !important;
	font-size: 11px !important;
  
	&::after {
	  width: 5px;
	  height: 5px;
	}
  }
  
  .avatar-sm {
	width: 32px !important;
	height: 32px !important;
	font-size: 14px !important;
  
	&::after {
	  width: 7px;
	  height: 7px;
	}
  }
  
  .avatar-md {
	width: 48px !important;
	height: 48px !important;
	font-size: 24px !important;
  
	&::after {
	  width: 9px;
	  height: 9px;
	  inset-inline-end: 2px;
	  bottom: 2px;
	}
  }
  
  .avatar-lg {
	width: 64px !important;
	height: 64px !important;
	font-size: 28px !important;
  
	&::after {
	  width: 10px;
	  height: 10px;
	  bottom: 3px;
	  inset-inline-end: 4px;
	}
  }
  
  .avatar-xl {
	width: 72px !important;
	height: 72px !important;
	font-size: 36px !important;
  
	&::after {
	  width: 11px;
	  height: 11px;
	  bottom: 4px;
	  inset-inline-end: 5px;
	  box-shadow: 0 0 0 2.5px $white;
	}
  }
  
  .avatar-xxl {
	width: 100px !important;
	height: 100px !important;
	font-size: 56px !important;
  
	&::after {
	  width: 13px;
	  height: 13px;
	  bottom: 6px;
	  inset-inline-end: 7px;
	  box-shadow: 0 0 0 3px $white;
	}
  }
  
  .avatar-placeholder {
	background: #ced4da url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="134" height="134" viewBox="0 0 134 134"><path fill="$gray" d="M65.92 66.34h2.16c14.802.42 30.928 6.062 29.283 20.35l-1.618 13.32c-.844 6.815-5.208 7.828-13.972 7.866H52.23c-8.764-.038-13.13-1.05-13.973-7.865l-1.62-13.32C34.994 72.4 51.12 66.76 65.92 66.34zM49.432 43.934c0-9.82 7.99-17.81 17.807-17.81 9.82 0 17.81 7.99 17.81 17.81 0 9.82-7.99 17.807-17.81 17.807-9.82 0-17.808-7.987-17.808-17.806z"/></svg>') no-repeat (center / 80%);
  }
  
  .avatar-list {
	margin: 0 0 -.5rem;
	padding: 0;
	font-size: 0;
  
	.avatar {
	  margin-bottom: .5rem;
  
	  &:not(:last-child) {
		margin-inline-end: .5rem;
	  }
	}
  }
  
  .avatar-list-stacked .avatar {
	box-shadow: 0 0 0 2px $white;
	margin-inline-end: -.8em !important;
  }
  
  .avatar-blue {
	background-color: #083dc1;
	color: #467fcf;
  }
  
  .avatar-indigo {
	background-color: #d1d5f0;
	color: $indigo;
  }
  
  .avatar-purple {
	background-color: #e4cff9;
	color: $purple;
  }
  
  .avatar-pink {
	background-color: #fcd3e1;
	color: $pink;
  }
  
  .avatar-red {
	background-color: #f0bcbc;
	color: #dc0441;
  }
  
  .avatar-orange {
	background-color: #fee0c7;
	color: $orange;
  }
  
  .avatar-yellow {
	background-color: #fbedb7;
	color: #ecb403;
  }
  
  .avatar-green {
	background-color: #cfeab3;
	color: #2dce89;
  }
  
  .avatar-teal {
	background-color: #bfefea;
	color: $teal;
  }
  
  .avatar-cyan {
	background-color: #b9e3ea;
	color: $cyan;
  }
  
  .avatar-white {
	background-color: white;
	color: $white;
  }
  
  .avatar-gray {
	background-color: #dbdde0;
	color: $gray;
  }
  
  .avatar-gray-dark {
	background-color: #c2c4c6;
	color: $dark;
  }
  
  .avatar-azure {
	background-color: #c7e6fb;
	color: #45aaf2;
  }
  
  .avatar-lime {
	background-color: #d7f2c2;
	color: #7bd235;
  }
  
  .product-price {
	font-size: 1rem;
  
	strong {
	  font-size: 1.5rem;
	}
  }
  
  @-webkit-keyframes indeterminate {
	0% {
	  inset-inline-start: -35%;
	  inset-inline-end: 100%;
	}
  
	100%, 60% {
	  inset-inline-start: 100%;
	  inset-inline-end: -90%;
	}
  }
  
  @keyframes indeterminate {
	0% {
	  inset-inline-start: -35%;
	  inset-inline-end: 100%;
	}
  
	100%, 60% {
	  inset-inline-start: 100%;
	  inset-inline-end: -90%;
	}
  }
  
  @-webkit-keyframes indeterminate-short {
	0% {
	  inset-inline-start: -200%;
	  inset-inline-end: 100%;
	}
  
	100%, 60% {
	  inset-inline-start: 107%;
	  inset-inline-end: -8%;
	}
  }
  
  @keyframes indeterminate-short {
	0% {
	  inset-inline-start: -200%;
	  inset-inline-end: 100%;
	}
  
	100%, 60% {
	  inset-inline-start: 107%;
	  inset-inline-end: -8%;
	}
  }
  .demo-avatar-group {
    display: flex;
    align-items: center;
}
  /*------ Avatars -------*/

/*---Opacity---*/

.op-0 {
  opacity: 0;
}

.op-1 {
  opacity: 0.1;
}

.op-2 {
  opacity: 0.2;
}

.op-3 {
  opacity: 0.3;
}

.op-4 {
  opacity: 0.4;
}

.op-5 {
  opacity: 0.5;
}

.op-6 {
  opacity: 0.6;
}

.op-7 {
  opacity: 0.7;
}

.op-8 {
  opacity: 0.8;
}

.op-9 {
  opacity: 0.9;
}

.opacity-1 {
  opacity: 1;
}

.shadow-base {
  box-shadow: 0 0 2px 2px rgba(28, 39, 60, 0.03);
}

.shadow-1 {
  box-shadow: 0 0 7px 7px rgba(28, 39, 60, 0.1);
}

.shadow-2 {
  box-shadow: 0 0 30px rgba(28, 39, 60, 0.08);
}

.shadow-none {
  box-shadow: none;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.flex-1 {
  flex: 1;
}

.flex-0 {
  flex: 0;
}

.img-fit-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 480px) {
  .op-xs-0 {
    opacity: 0;
  }

  .op-xs-1 {
    opacity: 0.1;
  }

  .op-xs-2 {
    opacity: 0.2;
  }

  .op-xs-3 {
    opacity: 0.3;
  }

  .op-xs-4 {
    opacity: 0.4;
  }

  .op-xs-5 {
    opacity: 0.5;
  }

  .op-xs-6 {
    opacity: 0.6;
  }

  .op-xs-7 {
    opacity: 0.7;
  }

  .op-xs-8 {
    opacity: 0.8;
  }

  .op-xs-9 {
    opacity: 0.9;
  }
}

@media (min-width: 576px) {
  .op-sm-0 {
    opacity: 0;
  }

  .op-sm-1 {
    opacity: 0.1;
  }

  .op-sm-2 {
    opacity: 0.2;
  }

  .op-sm-3 {
    opacity: 0.3;
  }

  .op-sm-4 {
    opacity: 0.4;
  }

  .op-sm-5 {
    opacity: 0.5;
  }

  .op-sm-6 {
    opacity: 0.6;
  }

  .op-sm-7 {
    opacity: 0.7;
  }

  .op-sm-8 {
    opacity: 0.8;
  }

  .op-sm-9 {
    opacity: 0.9;
  }
}

@media (min-width: 768px) {
  .op-md-0 {
    opacity: 0;
  }

  .op-md-1 {
    opacity: 0.1;
  }

  .op-md-2 {
    opacity: 0.2;
  }

  .op-md-3 {
    opacity: 0.3;
  }

  .op-md-4 {
    opacity: 0.4;
  }

  .op-md-5 {
    opacity: 0.5;
  }

  .op-md-6 {
    opacity: 0.6;
  }

  .op-md-7 {
    opacity: 0.7;
  }

  .op-md-8 {
    opacity: 0.8;
  }

  .op-md-9 {
    opacity: 0.9;
  }
}

@media (min-width: 992px) {
  .op-lg-0 {
    opacity: 0;
  }

  .op-lg-1 {
    opacity: 0.1;
  }

  .op-lg-2 {
    opacity: 0.2;
  }

  .op-lg-3 {
    opacity: 0.3;
  }

  .op-lg-4 {
    opacity: 0.4;
  }

  .op-lg-5 {
    opacity: 0.5;
  }

  .op-lg-6 {
    opacity: 0.6;
  }

  .op-lg-7 {
    opacity: 0.7;
  }

  .op-lg-8 {
    opacity: 0.8;
  }

  .op-lg-9 {
    opacity: 0.9;
  }
}

@media (min-width: 1200px) {
  .op-xl-0 {
    opacity: 0;
  }

  .op-xl-1 {
    opacity: 0.1;
  }

  .op-xl-2 {
    opacity: 0.2;
  }

  .op-xl-3 {
    opacity: 0.3;
  }

  .op-xl-4 {
    opacity: 0.4;
  }

  .op-xl-5 {
    opacity: 0.5;
  }

  .op-xl-6 {
    opacity: 0.6;
  }

  .op-xl-7 {
    opacity: 0.7;
  }

  .op-xl-8 {
    opacity: 0.8;
  }

  .op-xl-9 {
    opacity: 0.9;
  }
}

/*---Opacity---*/
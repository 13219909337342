// ----- Horizontal-menu -------//
@media (min-width: 992px) {
  .horizontal {
    .horizontal-main .sub-slide2 .sub-slide-menu1 {
      inset-inline-start: 180px;
      top: 8px;
      width: 190px;
      position: absolute;
      background-color: $white;
      z-index: 9999;
      border-radius: 5px;
      padding: 5px;
      min-width: fit-content;
      box-shadow: 0px 16px 18px rgba(135, 135, 182, 0.1) !important;
      border: 1px solid $border;
    }
    .side-menu {
        margin-block-start: 0px;
    }
    .app-sidebar {
      border-block-end: 1px solid $border !important;
      border-block-start: 0;
    }
    .horizontal-main {
      border-inline-start: 0 !important;
      border-inline-end: 0 !important;
    }

    .hor-header .container,
    .horizontal-main .container,
    .main-content.horizontal-content .container {
      max-width: 85% !important;
      padding: 0;
    }

    .app-sidebar .side-item.side-item-category {
      display: none;
    }

    .side-menu {
      display: flex;
      overflow: hidden;
      padding: 0px;
    }

    .main-sidemenu {
      margin-block-start: 0;
    }

    .main-sidebar-header {
      display: none;
    }

    .horizontal-main {
      width: 100%;
      position: relative;
      bottom: initial;
      z-index:1;

      &.ps {
        overflow: visible !important;
      }

      .slide {
        .slide-menu,
        .sub-slide-menu {
          width: 190px;
          position: absolute;
          background-color: $white;
          z-index: 9999;
          border-radius: 0px 0px 5px 5px;
          padding: 5px;
          min-width: fit-content;
          box-shadow: 0px 16px 18px rgba(135, 135, 182, 0.1) !important;
          border: 1px solid $border;
          display: none;
          .slide-item:before {
            inset-inline-start: 18px;
            top: 11px;
            margin-inline-end: 8px;
          }
        }
      }
      .slide-menu{
         inset-block-start: 3.34rem;
         border-block-start: 0px !important;
      }
    }
    .slide-item {
      padding: 8px 35px !important;
    }

    .slide.is-expanded .sub-slide-item2:before {
      inset-inline-start: 18px !important;
      top: 9px !important;
    }

    .sub-slide-menu .sub-slide-item2 {
      padding-block: 8px;
      padding-inline-start: 35px;
      padding-inline-end: 20px;
    }

    .sub-slide .sub-angle {
      inset-inline-end: -20px;
      inset-inline-start: auto;
      margin-inline-end: 0 !important;
      position: relative;
    }

    .sub-slide2 .sub-angle {
      inset-inline-end: -5px;
      inset-inline-start: auto;
      margin-inline-end: 0 !important;
      position: relative;
    }

    .sub-slide .sub-slide-menu {
      position: absolute;
      background-color: $white;
      z-index: 9999;
      box-shadow: 5px 5px 5px #b9b9b9;
      border-radius: 5px;
      padding: 0px;
      min-width: fit-content;
      border: 1px solid #f0f0f8;
      inset-inline-start: 180px;
      top: 13px;
    }

    .side-menu > li > a {
      display: flex;
      margin: 0px -4px !important;
      text-decoration: none;
      position: relative;
      color: #7b8191;
      padding: 7px  !important;
      font-weight: 400;
      border-radius: 0px !important;
      .side-menu__icon{
       margin-inline-end:9px;
     }
    }
    .breadcrumb-header {
      margin-block-start: 20px;
    }

    .slide {
      margin: 0 3px;
    }

    .logo-horizontal {
      display: block;
    }

    .app-sidebar__toggle {
      display: none;
    }

    .mobile-logo.dark-logo-1 {
      display: none;
    }

    .main-content {
      margin-inline-start: 0px !important;
    }

    .main-sidemenu .slide-right {
      inset-inline-end: 0;
    }

    .main-sidemenu .slide-left,
    .main-sidemenu .slide-right {
      position: absolute;
      top: -6px;
      padding: 16px;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      cursor: pointer;
    }

    .main-sidemenu .slide-left {
      inset-inline-start: 0;
    }

    .horizontal-main {
      position: relative;
      margin: 0 auto;
    }

    .main-sidemenu {
      overflow: hidden;
    }

    .sticky-pin {
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 2;
    }
    .hor-header {
      position: relative !important; 
      z-index: 9 !important;
    }
    .angle {
        margin-inline-start: 5px;
    }
  }
  .horizontal-hover.horizontal {
    .angle {
        margin-inline-start: 5px;
    }
    .slide {
      &:hover {
        ul.slide-menu {
          display: block !important;
        }
      }
    }
    .sub-slide {
      &:hover {
        .sub-slide-menu {
          display: block !important;
        }
      }
    }
  }
  .horizontal-main {
    .app-sidebar__user {
      display: none;
    }
    .bg-side-text {
      display: none;
    }
    &.app-sidebar .slide .side-menu__item.active::before {
      display: none;
    }
  }
}

.logo-horizontal {
  display: none;
}

.horizontal {
  @media (max-width: 991.98px) {
    .app-sidebar.horizontal-main {
      inset-inline-start: -300px;
    }
    .main-header.hor-header {
      box-shadow: 0px 7px 26px rgba(154, 154, 204, 0.1);
    }
    .main-sidemenu {
      padding: 0;
    }
    .horizontalMenucontainer .main-header.hor-header {
      position: fixed;
    }
    .slide-left,
    .slide-right {
      display: none !important;
    }
  }
  .main-sidemenu.container {
    padding-inline-start: 0 !important;
    padding-inline-end: 0 !important;
  }
  
  .ps--active-x > .ps__rail-x,
  .ps--active-y > .ps__rail-y {
    display: none;
  }
}
@media (min-width: 992px) {
  .horizontal-hover.horizontal .sub-slide2:hover .sub-slide-menu1 {
    display: block !important;
  }
  .horizontal-hover.horizontal .app-sidebar .slide-menu .sub-slide-menu1 a:before {
    inset-inline-start: 20px !important;
    top: 11px;
  }
  .horizontal-hover.horizontal .sub-slide-menu .sub-side-menu__item:before {
    inset-inline-start: 20px !important;
    top: 10px !important;
  }
}

.hor-header {
  .responsive-logo {
    display: block;
    img {
      height: 2rem;
      text-align: center;
      margin: 0 auto;
      justify-content: center;
    }
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  .horizontal.centerlogo-horizontal {
    .main-header-right {
        .main-header-message, .main-header-notification, .main-layout {
          margin: auto 1px;
      }
    }
  }
}
// ----- Horizontal-menu -------//
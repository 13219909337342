
/*---Padding---*/
.p-0 {
  padding: 0 !important;
}

.pt-0, .py-0 {
  padding-block-start: 0 !important;
}

.pe-0, .px-0 {
  padding-inline-end: 0 !important;
}

.pb-0, .py-0 {
  padding-block-end: 0 !important;
}

.ps-0, .px-0 {
  padding-inline-start: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1, .py-1 {
  padding-block-start: 0.25rem !important;
}

.pe-1, .px-1 {
  padding-inline-end: 0.25rem !important;
}

.pb-1, .py-1 {
  padding-block-end: 0.25rem !important;
}

.ps-1, .px-1 {
  padding-inline-start: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2, .py-2 {
  padding-block-start: 0.5rem !important;
}

.pe-2, .px-2 {
  padding-inline-end: 0.5rem !important;
}

.pb-2, .py-2 {
  padding-block-end: 0.5rem !important;
}

.ps-2, .px-2 {
  padding-inline-start: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3, .py-3 {
  padding-block-start: 1rem !important;
}

.pe-3, .px-3 {
  padding-inline-end: 1rem !important;
}

.pb-3, .py-3 {
  padding-block-end: 1rem !important;
}

.ps-3, .px-3 {
  padding-inline-start: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4, .py-4 {
  padding-block-start: 1.5rem !important;
}

.pe-4, .px-4 {
  padding-inline-end: 1.5rem !important;
}

.pb-4, .py-4 {
  padding-block-end: 1.5rem !important;
}

.ps-4, .px-4 {
  padding-inline-start: 1.5rem !important;
}

.p-5 {
  padding: 2rem !important;
}

.pt-5, .py-5 {
  padding-block-start: 2rem !important;
}

.pe-5, .px-5 {
  padding-inline-end: 2rem !important;
}

.pb-5, .py-5 {
  padding-block-end: 2rem !important;
}

.ps-5, .px-5 {
  padding-inline-start: 2rem !important;
}
.p-6 {
  padding: 2.5rem !important;
}

.pt-6, .py-6 {
  padding-block-start: 2.5rem !important;
}

.pe-5, .px-5 {
  padding-inline-end: 2.5rem !important;
}

.pb-6, .py-6 {
  padding-block-end: 2.5rem !important;
}

.ps-6, .px-6 {
  padding-inline-start: 2.5rem !important;
}
.p-7 {
  padding: 3rem !important;
}

.pt-6, .py-6 {
  padding-block-start: 3rem !important;
}

.pe-5, .px-5 {
  padding-inline-end: 3rem !important;
}

.pb-6, .py-6 {
  padding-block-end: 3rem !important;
}

.ps-6, .px-6 {
  padding-inline-start: 3rem !important;
}



@media (min-width: 576px) {
  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0, .py-sm-0 {
    padding-block-start: 0 !important;
  }

  .pe-sm-0, .px-sm-0 {
    padding-inline-end: 0 !important;
  }

  .pb-sm-0, .py-sm-0 {
    padding-block-end: 0 !important;
  }

  .ps-sm-0, .px-sm-0 {
    padding-inline-start: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1, .py-sm-1 {
    padding-block-start: 0.25rem !important;
  }

  .pe-sm-1, .px-sm-1 {
    padding-inline-end: 0.25rem !important;
  }

  .pb-sm-1, .py-sm-1 {
    padding-block-end: 0.25rem !important;
  }

  .ps-sm-1, .px-sm-1 {
    padding-inline-start: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2, .py-sm-2 {
    padding-block-start: 0.5rem !important;
  }

  .pe-sm-2, .px-sm-2 {
    padding-inline-end: 0.5rem !important;
  }

  .pb-sm-2, .py-sm-2 {
    padding-block-end: 0.5rem !important;
  }

  .ps-sm-2, .px-sm-2 {
    padding-inline-start: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3, .py-sm-3 {
    padding-block-start: 1rem !important;
  }

  .pe-sm-3, .px-sm-3 {
    padding-inline-end: 1rem !important;
  }

  .pb-sm-3, .py-sm-3 {
    padding-block-end: 1rem !important;
  }

  .ps-sm-3, .px-sm-3 {
    padding-inline-start: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4, .py-sm-4 {
    padding-block-start: 1.5rem !important;
  }

  .pe-sm-4, .px-sm-4 {
    padding-inline-end: 1.5rem !important;
  }

  .pb-sm-4, .py-sm-4 {
    padding-block-end: 1.5rem !important;
  }

  .ps-sm-4, .px-sm-4 {
    padding-inline-start: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5, .py-sm-5 {
    padding-block-start: 3rem !important;
  }

  .pe-sm-5, .px-sm-5 {
    padding-inline-end: 3rem !important;
  }

  .pb-sm-5, .py-sm-5 {
    padding-block-end: 3rem !important;
  }

  .ps-sm-5, .px-sm-5 {
    padding-inline-start: 3rem !important;
  }
}

@media (min-width: 768px) {
  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0, .py-md-0 {
    padding-block-start: 0 !important;
  }

  .pe-md-0, .px-md-0 {
    padding-inline-end: 0 !important;
  }

  .pb-md-0, .py-md-0 {
    padding-block-end: 0 !important;
  }

  .ps-md-0, .px-md-0 {
    padding-inline-start: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1, .py-md-1 {
    padding-block-start: 0.25rem !important;
  }

  .pe-md-1, .px-md-1 {
    padding-inline-end: 0.25rem !important;
  }

  .pb-md-1, .py-md-1 {
    padding-block-end: 0.25rem !important;
  }

  .ps-md-1, .px-md-1 {
    padding-inline-start: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2, .py-md-2 {
    padding-block-start: 0.5rem !important;
  }

  .pe-md-2, .px-md-2 {
    padding-inline-end: 0.5rem !important;
  }

  .pb-md-2, .py-md-2 {
    padding-block-end: 0.5rem !important;
  }

  .ps-md-2, .px-md-2 {
    padding-inline-start: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3, .py-md-3 {
    padding-block-start: 1rem !important;
  }

  .pe-md-3, .px-md-3 {
    padding-inline-end: 1rem !important;
  }

  .pb-md-3, .py-md-3 {
    padding-block-end: 1rem !important;
  }

  .ps-md-3, .px-md-3 {
    padding-inline-start: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4, .py-md-4 {
    padding-block-start: 1.5rem !important;
  }

  .pe-md-4, .px-md-4 {
    padding-inline-end: 1.5rem !important;
  }

  .pb-md-4, .py-md-4 {
    padding-block-end: 1.5rem !important;
  }

  .ps-md-4, .px-md-4 {
    padding-inline-start: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5, .py-md-5 {
    padding-block-start: 3rem !important;
  }

  .pe-md-5, .px-md-5 {
    padding-inline-end: 3rem !important;
  }

  .pb-md-5, .py-md-5 {
    padding-block-end: 3rem !important;
  }

  .ps-md-5, .px-md-5 {
    padding-inline-start: 3rem !important;
  }
}

@media (min-width: 992px) {
  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0, .py-lg-0 {
    padding-block-start: 0 !important;
  }

  .pe-lg-0, .px-lg-0 {
    padding-inline-end: 0 !important;
  }

  .pb-lg-0, .py-lg-0 {
    padding-block-end: 0 !important;
  }

  .ps-lg-0, .px-lg-0 {
    padding-inline-start: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1, .py-lg-1 {
    padding-block-start: 0.25rem !important;
  }

  .pe-lg-1, .px-lg-1 {
    padding-inline-end: 0.25rem !important;
  }

  .pb-lg-1, .py-lg-1 {
    padding-block-end: 0.25rem !important;
  }

  .ps-lg-1, .px-lg-1 {
    padding-inline-start: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2, .py-lg-2 {
    padding-block-start: 0.5rem !important;
  }

  .pe-lg-2, .px-lg-2 {
    padding-inline-end: 0.5rem !important;
  }

  .pb-lg-2, .py-lg-2 {
    padding-block-end: 0.5rem !important;
  }

  .ps-lg-2, .px-lg-2 {
    padding-inline-start: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3, .py-lg-3 {
    padding-block-start: 1rem !important;
  }

  .pe-lg-3, .px-lg-3 {
    padding-inline-end: 1rem !important;
  }

  .pb-lg-3, .py-lg-3 {
    padding-block-end: 1rem !important;
  }

  .ps-lg-3, .px-lg-3 {
    padding-inline-start: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4, .py-lg-4 {
    padding-block-start: 1.5rem !important;
  }

  .pe-lg-4, .px-lg-4 {
    padding-inline-end: 1.5rem !important;
  }

  .pb-lg-4, .py-lg-4 {
    padding-block-end: 1.5rem !important;
  }

  .ps-lg-4, .px-lg-4 {
    padding-inline-start: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5, .py-lg-5 {
    padding-block-start: 3rem !important;
  }

  .pe-lg-5, .px-lg-5 {
    padding-inline-end: 3rem !important;
  }

  .pb-lg-5, .py-lg-5 {
    padding-block-end: 3rem !important;
  }

  .ps-lg-5, .px-lg-5 {
    padding-inline-start: 3rem !important;
  }
}

@media (min-width: 1200px) {
  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0, .py-xl-0 {
    padding-block-start: 0 !important;
  }

  .pe-xl-0, .px-xl-0 {
    padding-inline-end: 0 !important;
  }

  .pb-xl-0, .py-xl-0 {
    padding-block-end: 0 !important;
  }

  .ps-xl-0, .px-xl-0 {
    padding-inline-start: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1, .py-xl-1 {
    padding-block-start: 0.25rem !important;
  }

  .pe-xl-1, .px-xl-1 {
    padding-inline-end: 0.25rem !important;
  }

  .pb-xl-1, .py-xl-1 {
    padding-block-end: 0.25rem !important;
  }

  .ps-xl-1, .px-xl-1 {
    padding-inline-start: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2, .py-xl-2 {
    padding-block-start: 0.5rem !important;
  }

  .pe-xl-2, .px-xl-2 {
    padding-inline-end: 0.5rem !important;
  }

  .pb-xl-2, .py-xl-2 {
    padding-block-end: 0.5rem !important;
  }

  .ps-xl-2, .px-xl-2 {
    padding-inline-start: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3, .py-xl-3 {
    padding-block-start: 1rem !important;
  }

  .pe-xl-3, .px-xl-3 {
    padding-inline-end: 1rem !important;
  }

  .pb-xl-3, .py-xl-3 {
    padding-block-end: 1rem !important;
  }

  .ps-xl-3, .px-xl-3 {
    padding-inline-start: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4, .py-xl-4 {
    padding-block-start: 1.5rem !important;
  }

  .pe-xl-4, .px-xl-4 {
    padding-inline-end: 1.5rem !important;
  }

  .pb-xl-4, .py-xl-4 {
    padding-block-end: 1.5rem !important;
  }

  .ps-xl-4, .px-xl-4 {
    padding-inline-start: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5, .py-xl-5 {
    padding-block-start: 3rem !important;
  }

  .pe-xl-5, .px-xl-5 {
    padding-inline-end: 3rem !important;
  }

  .pb-xl-5, .py-xl-5 {
    padding-block-end: 3rem !important;
  }

  .ps-xl-5, .px-xl-5 {
    padding-inline-start: 3rem !important;
  }
}

/* ###### 9.6 Padding   ###### */

.pd-0 {
  padding: 0px;
}

.pd-y-0 {
  padding-block-start: 0px;
  padding-block-end: 0px;
}

.pd-x-0 {
  padding-inline-start: 0px;
  padding-inline-end: 0px;
}

.pd-t-0 {
  padding-block-start: 0px !important;
}

.pd-r-0 {
  padding-inline-end: 0px;
}

.pd-b-0 {
  padding-block-end: 0px;
}

.pd-l-0 {
  padding-inline-start: 0px;
}

.pd-1 {
  padding: 1px;
}

.pd-y-1 {
  padding-block-start: 1px;
  padding-block-end: 1px;
}

.pd-x-1 {
  padding-inline-start: 1px;
  padding-inline-end: 1px;
}

.pd-t-1 {
  padding-block-start: 1px;
}

.pd-r-1 {
  padding-inline-end: 1px;
}

.pd-b-1 {
  padding-block-end: 1px;
}

.pd-l-1 {
  padding-inline-start: 1px;
}

.pd-2 {
  padding: 2px;
}

.pd-y-2 {
  padding-block-start: 2px;
  padding-block-end: 2px;
}

.pd-x-2 {
  padding-inline-start: 2px;
  padding-inline-end: 2px;
}

.pd-t-2 {
  padding-block-start: 2px;
}

.pd-r-2 {
  padding-inline-end: 2px;
}

.pd-b-2 {
  padding-block-end: 2px;
}

.pd-l-2 {
  padding-inline-start: 2px;
}

.pd-3 {
  padding: 3px;
}

.pd-y-3 {
  padding-block-start: 3px;
  padding-block-end: 3px;
}

.pd-x-3 {
  padding-inline-start: 3px;
  padding-inline-end: 3px;
}

.pd-t-3 {
  padding-block-start: 3px;
}

.pd-r-3 {
  padding-inline-end: 3px;
}

.pd-b-3 {
  padding-block-end: 3px;
}

.pd-l-3 {
  padding-inline-start: 3px;
}

.pd-4 {
  padding: 4px;
}

.pd-y-4 {
  padding-block-start: 4px;
  padding-block-end: 4px;
}

.pd-x-4 {
  padding-inline-start: 4px;
  padding-inline-end: 4px;
}

.pd-t-4 {
  padding-block-start: 4px;
}

.pd-r-4 {
  padding-inline-end: 4px;
}

.pd-b-4 {
  padding-block-end: 4px;
}

.pd-l-4 {
  padding-inline-start: 4px;
}

.pd-5 {
  padding: 5px;
}

.pd-y-5 {
  padding-block-start: 5px;
  padding-block-end: 5px;
}

.pd-x-5 {
  padding-inline-start: 5px;
  padding-inline-end: 5px;
}

.pd-t-5 {
  padding-block-start: 5px;
}

.pd-r-5 {
  padding-inline-end: 5px;
}

.pd-b-5 {
  padding-block-end: 5px;
}

.pd-l-5 {
  padding-inline-start: 5px;
}

.pd-6 {
  padding: 6px;
}

.pd-y-6 {
  padding-block-start: 6px;
  padding-block-end: 6px;
}

.pd-x-6 {
  padding-inline-start: 6px;
  padding-inline-end: 6px;
}

.pd-t-6 {
  padding-block-start: 6px !important;
}

.pd-r-6 {
  padding-inline-end: 6px;
}

.pd-b-6 {
  padding-block-end: 6px;
}

.pd-l-6 {
  padding-inline-start: 6px;
}

.pd-7 {
  padding: 7px;
}

.pd-y-7 {
  padding-block-start: 7px;
  padding-block-end: 7px;
}

.pd-x-7 {
  padding-inline-start: 7px;
  padding-inline-end: 7px;
}

.pd-t-7 {
  padding-block-start: 7px;
}

.pd-r-7 {
  padding-inline-end: 7px;
}

.pd-b-7 {
  padding-block-end: 7px;
}

.pd-l-7 {
  padding-inline-start: 7px;
}

.pd-8 {
  padding: 8px;
}

.pd-y-8 {
  padding-block-start: 8px;
  padding-block-end: 8px;
}

.pd-x-8 {
  padding-inline-start: 8px;
  padding-inline-end: 8px;
}

.pd-t-8 {
  padding-block-start: 8px;
}

.pd-r-8 {
  padding-inline-end: 8px;
}

.pd-b-8 {
  padding-block-end: 8px;
}

.pd-l-8 {
  padding-inline-start: 8px;
}

.pd-9 {
  padding: 9px;
}

.pd-y-9 {
  padding-block-start: 9px;
  padding-block-end: 9px;
}

.pd-x-9 {
  padding-inline-start: 9px;
  padding-inline-end: 9px;
}

.pd-t-9 {
  padding-block-start: 9px;
}

.pd-r-9 {
  padding-inline-end: 9px;
}

.pd-b-9 {
  padding-block-end: 9px;
}

.pd-l-9 {
  padding-inline-start: 9px;
}

.pd-10 {
  padding: 10px;
}

.pd-y-10 {
  padding-block-start: 10px;
  padding-block-end: 10px;
}

.pd-x-10 {
  padding-inline-start: 10px;
  padding-inline-end: 10px;
}

.pd-t-10 {
  padding-block-start: 10px;
}

.pd-r-10 {
  padding-inline-end: 10px;
}

.pd-b-10 {
  padding-block-end: 10px;
}

.pd-l-10 {
  padding-inline-start: 10px;
}

.pd-15 {
  padding: 15px;
}

.pd-y-15 {
  padding-block-start: 15px;
  padding-block-end: 15px;
}

.pd-x-15 {
  padding-inline-start: 15px;
  padding-inline-end: 15px;
}

.pd-t-15 {
  padding-block-start: 15px;
}

.pd-r-15 {
  padding-inline-end: 15px;
}

.pd-b-15 {
  padding-block-end: 15px;
}

.pd-l-15 {
  padding-inline-start: 15px;
}

.pd-20 {
  padding: 20px;
}

.pd-y-20 {
  padding-block-start: 20px;
  padding-block-end: 20px;
}

.pd-x-20 {
  padding-inline-start: 20px;
  padding-inline-end: 20px;
}

.pd-t-20 {
  padding-block-start: 20px;
}

.pd-r-20 {
  padding-inline-end: 20px;
}

.pd-b-20 {
  padding-block-end: 20px;
}

.pd-l-20 {
  padding-inline-start: 20px;
}

.pd-25 {
  padding: 25px;
}

.pd-y-25 {
  padding-block-start: 25px;
  padding-block-end: 25px;
}

.pd-x-25 {
  padding-inline-start: 25px;
  padding-inline-end: 25px;
}

.pd-t-25 {
  padding-block-start: 25px;
}

.pd-r-25 {
  padding-inline-end: 25px;
}

.pd-b-25 {
  padding-block-end: 25px;
}

.pd-l-25 {
  padding-inline-start: 25px;
}

.pd-30 {
  padding: 30px;
}

.pd-y-30 {
  padding-block-start: 30px;
  padding-block-end: 30px;
}

.pd-x-30 {
  padding-inline-start: 30px;
  padding-inline-end: 30px;
}

.pd-t-30 {
  padding-block-start: 30px;
}

.pd-r-30 {
  padding-inline-end: 30px;
}

.pd-b-30 {
  padding-block-end: 30px;
}

.pd-l-30 {
  padding-inline-start: 30px;
}

.pd-35 {
  padding: 35px;
}

.pd-y-35 {
  padding-block-start: 35px;
  padding-block-end: 35px;
}

.pd-x-35 {
  padding-inline-start: 35px;
  padding-inline-end: 35px;
}

.pd-t-35 {
  padding-block-start: 35px;
}

.pd-r-35 {
  padding-inline-end: 35px;
}

.pd-b-35 {
  padding-block-end: 35px;
}

.pd-l-35 {
  padding-inline-start: 35px;
}

.pd-40 {
  padding: 40px;
}

.pd-y-40 {
  padding-block-start: 40px;
  padding-block-end: 40px;
}

.pd-x-40 {
  padding-inline-start: 40px;
  padding-inline-end: 40px;
}

.pd-t-40 {
  padding-block-start: 40px;
}

.pd-r-40 {
  padding-inline-end: 40px;
}

.pd-b-40 {
  padding-block-end: 40px;
}

.pd-l-40 {
  padding-inline-start: 40px;
}

.pd-45 {
  padding: 45px;
}

.pd-y-45 {
  padding-block-start: 45px;
  padding-block-end: 45px;
}

.pd-x-45 {
  padding-inline-start: 45px;
  padding-inline-end: 45px;
}

.pd-t-45 {
  padding-block-start: 45px;
}

.pd-r-45 {
  padding-inline-end: 45px;
}

.pd-b-45 {
  padding-block-end: 45px;
}

.pd-l-45 {
  padding-inline-start: 45px;
}

.pd-50 {
  padding: 50px;
}

.pd-y-50 {
  padding-block-start: 50px;
  padding-block-end: 50px;
}

.pd-x-50 {
  padding-inline-start: 50px;
  padding-inline-end: 50px;
}

.pd-t-50 {
  padding-block-start: 50px;
}

.pd-r-50 {
  padding-inline-end: 50px;
}

.pd-b-50 {
  padding-block-end: 50px;
}

.pd-l-50 {
  padding-inline-start: 50px;
}

.pd-55 {
  padding: 55px;
}

.pd-y-55 {
  padding-block-start: 55px;
  padding-block-end: 55px;
}

.pd-x-55 {
  padding-inline-start: 55px;
  padding-inline-end: 55px;
}

.pd-t-55 {
  padding-block-start: 55px;
}

.pd-r-55 {
  padding-inline-end: 55px;
}

.pd-b-55 {
  padding-block-end: 55px;
}

.pd-l-55 {
  padding-inline-start: 55px;
}

.pd-60 {
  padding: 60px;
}

.pd-y-60 {
  padding-block-start: 60px;
  padding-block-end: 60px;
}

.pd-x-60 {
  padding-inline-start: 60px;
  padding-inline-end: 60px;
}

.pd-t-60 {
  padding-block-start: 60px;
}

.pd-r-60 {
  padding-inline-end: 60px;
}

.pd-b-60 {
  padding-block-end: 60px;
}

.pd-l-60 {
  padding-inline-start: 60px;
}

.pd-65 {
  padding: 65px;
}

.pd-y-65 {
  padding-block-start: 65px;
  padding-block-end: 65px;
}

.pd-x-65 {
  padding-inline-start: 65px;
  padding-inline-end: 65px;
}

.pd-t-65 {
  padding-block-start: 65px;
}

.pd-r-65 {
  padding-inline-end: 65px;
}

.pd-b-65 {
  padding-block-end: 65px;
}

.pd-l-65 {
  padding-inline-start: 65px;
}

.pd-70 {
  padding: 70px;
}

.pd-y-70 {
  padding-block-start: 70px;
  padding-block-end: 70px;
}

.pd-x-70 {
  padding-inline-start: 70px;
  padding-inline-end: 70px;
}

.pd-t-70 {
  padding-block-start: 70px;
}

.pd-r-70 {
  padding-inline-end: 70px;
}

.pd-b-70 {
  padding-block-end: 70px;
}

.pd-l-70 {
  padding-inline-start: 70px;
}

.pd-75 {
  padding: 75px;
}

.pd-y-75 {
  padding-block-start: 75px;
  padding-block-end: 75px;
}

.pd-x-75 {
  padding-inline-start: 75px;
  padding-inline-end: 75px;
}

.pd-t-75 {
  padding-block-start: 75px;
}

.pd-r-75 {
  padding-inline-end: 75px;
}

.pd-b-75 {
  padding-block-end: 75px;
}

.pd-l-75 {
  padding-inline-start: 75px;
}

.pd-80 {
  padding: 80px;
}

.pd-y-80 {
  padding-block-start: 80px;
  padding-block-end: 80px;
}

.pd-x-80 {
  padding-inline-start: 80px;
  padding-inline-end: 80px;
}

.pd-t-80 {
  padding-block-start: 80px;
}

.pd-r-80 {
  padding-inline-end: 80px;
}

.pd-b-80 {
  padding-block-end: 80px;
}

.pd-l-80 {
  padding-inline-start: 80px;
}

.pd-85 {
  padding: 85px;
}

.pd-y-85 {
  padding-block-start: 85px;
  padding-block-end: 85px;
}

.pd-x-85 {
  padding-inline-start: 85px;
  padding-inline-end: 85px;
}

.pd-t-85 {
  padding-block-start: 85px;
}

.pd-r-85 {
  padding-inline-end: 85px;
}

.pd-b-85 {
  padding-block-end: 85px;
}

.pd-l-85 {
  padding-inline-start: 85px;
}

.pd-90 {
  padding: 90px;
}

.pd-y-90 {
  padding-block-start: 90px;
  padding-block-end: 90px;
}

.pd-x-90 {
  padding-inline-start: 90px;
  padding-inline-end: 90px;
}

.pd-t-90 {
  padding-block-start: 90px;
}

.pd-r-90 {
  padding-inline-end: 90px;
}

.pd-b-90 {
  padding-block-end: 90px;
}

.pd-l-90 {
  padding-inline-start: 90px;
}

.pd-95 {
  padding: 95px;
}

.pd-y-95 {
  padding-block-start: 95px;
  padding-block-end: 95px;
}

.pd-x-95 {
  padding-inline-start: 95px;
  padding-inline-end: 95px;
}

.pd-t-95 {
  padding-block-start: 95px;
}

.pd-r-95 {
  padding-inline-end: 95px;
}

.pd-b-95 {
  padding-block-end: 95px;
}

.pd-l-95 {
  padding-inline-start: 95px;
}

.pd-100 {
  padding: 100px;
}

.pd-y-100 {
  padding-block-start: 100px;
  padding-block-end: 100px;
}

.pd-x-100 {
  padding-inline-start: 100px;
  padding-inline-end: 100px;
}

.pd-t-100 {
  padding-block-start: 100px;
}

.pd-r-100 {
  padding-inline-end: 100px;
}

.pd-b-100 {
  padding-block-end: 100px;
}

.pd-l-100 {
  padding-inline-start: 100px;
}

.pd-110 {
  padding: 110px;
}

.pd-y-110 {
  padding-block-start: 110px;
  padding-block-end: 110px;
}

.pd-x-110 {
  padding-inline-start: 110px;
  padding-inline-end: 110px;
}

.pd-t-110 {
  padding-block-start: 110px;
}

.pd-r-110 {
  padding-inline-end: 110px;
}

.pd-b-110 {
  padding-block-end: 110px;
}

.pd-l-110 {
  padding-inline-start: 110px;
}

.pd-120 {
  padding: 120px;
}

.pd-y-120 {
  padding-block-start: 120px;
  padding-block-end: 120px;
}

.pd-x-120 {
  padding-inline-start: 120px;
  padding-inline-end: 120px;
}

.pd-t-120 {
  padding-block-start: 120px;
}

.pd-r-120 {
  padding-inline-end: 120px;
}

.pd-b-120 {
  padding-block-end: 120px;
}

.pd-l-120 {
  padding-inline-start: 120px;
}

.pd-130 {
  padding: 130px;
}

.pd-y-130 {
  padding-block-start: 130px;
  padding-block-end: 130px;
}

.pd-x-130 {
  padding-inline-start: 130px;
  padding-inline-end: 130px;
}

.pd-t-130 {
  padding-block-start: 130px;
}

.pd-r-130 {
  padding-inline-end: 130px;
}

.pd-b-130 {
  padding-block-end: 130px;
}

.pd-l-130 {
  padding-inline-start: 130px;
}

.pd-140 {
  padding: 140px;
}

.pd-y-140 {
  padding-block-start: 140px;
  padding-block-end: 140px;
}

.pd-x-140 {
  padding-inline-start: 140px;
  padding-inline-end: 140px;
}

.pd-t-140 {
  padding-block-start: 140px;
}

.pd-r-140 {
  padding-inline-end: 140px;
}

.pd-b-140 {
  padding-block-end: 140px;
}

.pd-l-140 {
  padding-inline-start: 140px;
}

.pd-150 {
  padding: 150px;
}

.pd-y-150 {
  padding-block-start: 150px;
  padding-block-end: 150px;
}

.pd-x-150 {
  padding-inline-start: 150px;
  padding-inline-end: 150px;
}

.pd-t-150 {
  padding-block-start: 150px;
}

.pd-r-150 {
  padding-inline-end: 150px;
}

.pd-b-150 {
  padding-block-end: 150px;
}

.pd-l-150 {
  padding-inline-start: 150px;
}

.pd-160 {
  padding: 160px;
}

.pd-y-160 {
  padding-block-start: 160px;
  padding-block-end: 160px;
}

.pd-x-160 {
  padding-inline-start: 160px;
  padding-inline-end: 160px;
}

.pd-t-160 {
  padding-block-start: 160px;
}

.pd-r-160 {
  padding-inline-end: 160px;
}

.pd-b-160 {
  padding-block-end: 160px;
}

.pd-l-160 {
  padding-inline-start: 160px;
}

.pd-170 {
  padding: 170px;
}

.pd-y-170 {
  padding-block-start: 170px;
  padding-block-end: 170px;
}

.pd-x-170 {
  padding-inline-start: 170px;
  padding-inline-end: 170px;
}

.pd-t-170 {
  padding-block-start: 170px;
}

.pd-r-170 {
  padding-inline-end: 170px;
}

.pd-b-170 {
  padding-block-end: 170px;
}

.pd-l-170 {
  padding-inline-start: 170px;
}

.pd-180 {
  padding: 180px;
}

.pd-y-180 {
  padding-block-start: 180px;
  padding-block-end: 180px;
}

.pd-x-180 {
  padding-inline-start: 180px;
  padding-inline-end: 180px;
}

.pd-t-180 {
  padding-block-start: 180px;
}

.pd-r-180 {
  padding-inline-end: 180px;
}

.pd-b-180 {
  padding-block-end: 180px;
}

.pd-l-180 {
  padding-inline-start: 180px;
}

.pd-190 {
  padding: 190px;
}

.pd-y-190 {
  padding-block-start: 190px;
  padding-block-end: 190px;
}

.pd-x-190 {
  padding-inline-start: 190px;
  padding-inline-end: 190px;
}

.pd-t-190 {
  padding-block-start: 190px;
}

.pd-r-190 {
  padding-inline-end: 190px;
}

.pd-b-190 {
  padding-block-end: 190px;
}

.pd-l-190 {
  padding-inline-start: 190px;
}

.pd-200 {
  padding: 200px;
}

.pd-y-200 {
  padding-block-start: 200px;
  padding-block-end: 200px;
}

.pd-x-200 {
  padding-inline-start: 200px;
  padding-inline-end: 200px;
}

.pd-t-200 {
  padding-block-start: 200px;
}

.pd-r-200 {
  padding-inline-end: 200px;
}

.pd-b-200 {
  padding-block-end: 200px;
}

.pd-l-200 {
  padding-inline-start: 200px;
}

@media (min-width: 480px) {
  .pd-xs-0 {
    padding: 0px;
  }

  .pd-xs-y-0 {
    padding-block-start: 0px;
    padding-block-end: 0px;
  }

  .pd-xs-x-0 {
    padding-inline-start: 0px;
    padding-inline-end: 0px;
  }

  .pd-xs-t-0 {
    padding-block-start: 0px;
  }

  .pd-xs-r-0 {
    padding-inline-end: 0px;
  }

  .pd-xs-b-0 {
    padding-block-end: 0px;
  }

  .pd-xs-l-0 {
    padding-inline-start: 0px;
  }

  .pd-xs-1 {
    padding: 1px;
  }

  .pd-xs-y-1 {
    padding-block-start: 1px;
    padding-block-end: 1px;
  }

  .pd-xs-x-1 {
    padding-inline-start: 1px;
    padding-inline-end: 1px;
  }

  .pd-xs-t-1 {
    padding-block-start: 1px;
  }

  .pd-xs-r-1 {
    padding-inline-end: 1px;
  }

  .pd-xs-b-1 {
    padding-block-end: 1px;
  }

  .pd-xs-l-1 {
    padding-inline-start: 1px;
  }

  .pd-xs-2 {
    padding: 2px;
  }

  .pd-xs-y-2 {
    padding-block-start: 2px;
    padding-block-end: 2px;
  }

  .pd-xs-x-2 {
    padding-inline-start: 2px;
    padding-inline-end: 2px;
  }

  .pd-xs-t-2 {
    padding-block-start: 2px;
  }

  .pd-xs-r-2 {
    padding-inline-end: 2px;
  }

  .pd-xs-b-2 {
    padding-block-end: 2px;
  }

  .pd-xs-l-2 {
    padding-inline-start: 2px;
  }

  .pd-xs-3 {
    padding: 3px;
  }

  .pd-xs-y-3 {
    padding-block-start: 3px;
    padding-block-end: 3px;
  }

  .pd-xs-x-3 {
    padding-inline-start: 3px;
    padding-inline-end: 3px;
  }

  .pd-xs-t-3 {
    padding-block-start: 3px;
  }

  .pd-xs-r-3 {
    padding-inline-end: 3px;
  }

  .pd-xs-b-3 {
    padding-block-end: 3px;
  }

  .pd-xs-l-3 {
    padding-inline-start: 3px;
  }

  .pd-xs-4 {
    padding: 4px;
  }

  .pd-xs-y-4 {
    padding-block-start: 4px;
    padding-block-end: 4px;
  }

  .pd-xs-x-4 {
    padding-inline-start: 4px;
    padding-inline-end: 4px;
  }

  .pd-xs-t-4 {
    padding-block-start: 4px;
  }

  .pd-xs-r-4 {
    padding-inline-end: 4px;
  }

  .pd-xs-b-4 {
    padding-block-end: 4px;
  }

  .pd-xs-l-4 {
    padding-inline-start: 4px;
  }

  .pd-xs-5 {
    padding: 5px;
  }

  .pd-xs-y-5 {
    padding-block-start: 5px;
    padding-block-end: 5px;
  }

  .pd-xs-x-5 {
    padding-inline-start: 5px;
    padding-inline-end: 5px;
  }

  .pd-xs-t-5 {
    padding-block-start: 5px;
  }

  .pd-xs-r-5 {
    padding-inline-end: 5px;
  }

  .pd-xs-b-5 {
    padding-block-end: 5px;
  }

  .pd-xs-l-5 {
    padding-inline-start: 5px;
  }

  .pd-xs-6 {
    padding: 6px;
  }

  .pd-xs-y-6 {
    padding-block-start: 6px;
    padding-block-end: 6px;
  }

  .pd-xs-x-6 {
    padding-inline-start: 6px;
    padding-inline-end: 6px;
  }

  .pd-xs-t-6 {
    padding-block-start: 6px;
  }

  .pd-xs-r-6 {
    padding-inline-end: 6px;
  }

  .pd-xs-b-6 {
    padding-block-end: 6px;
  }

  .pd-xs-l-6 {
    padding-inline-start: 6px;
  }

  .pd-xs-7 {
    padding: 7px;
  }

  .pd-xs-y-7 {
    padding-block-start: 7px;
    padding-block-end: 7px;
  }

  .pd-xs-x-7 {
    padding-inline-start: 7px;
    padding-inline-end: 7px;
  }

  .pd-xs-t-7 {
    padding-block-start: 7px;
  }

  .pd-xs-r-7 {
    padding-inline-end: 7px;
  }

  .pd-xs-b-7 {
    padding-block-end: 7px;
  }

  .pd-xs-l-7 {
    padding-inline-start: 7px;
  }

  .pd-xs-8 {
    padding: 8px;
  }

  .pd-xs-y-8 {
    padding-block-start: 8px;
    padding-block-end: 8px;
  }

  .pd-xs-x-8 {
    padding-inline-start: 8px;
    padding-inline-end: 8px;
  }

  .pd-xs-t-8 {
    padding-block-start: 8px;
  }

  .pd-xs-r-8 {
    padding-inline-end: 8px;
  }

  .pd-xs-b-8 {
    padding-block-end: 8px;
  }

  .pd-xs-l-8 {
    padding-inline-start: 8px;
  }

  .pd-xs-9 {
    padding: 9px;
  }

  .pd-xs-y-9 {
    padding-block-start: 9px;
    padding-block-end: 9px;
  }

  .pd-xs-x-9 {
    padding-inline-start: 9px;
    padding-inline-end: 9px;
  }

  .pd-xs-t-9 {
    padding-block-start: 9px;
  }

  .pd-xs-r-9 {
    padding-inline-end: 9px;
  }

  .pd-xs-b-9 {
    padding-block-end: 9px;
  }

  .pd-xs-l-9 {
    padding-inline-start: 9px;
  }

  .pd-xs-10 {
    padding: 10px;
  }

  .pd-xs-y-10 {
    padding-block-start: 10px;
    padding-block-end: 10px;
  }

  .pd-xs-x-10 {
    padding-inline-start: 10px;
    padding-inline-end: 10px;
  }

  .pd-xs-t-10 {
    padding-block-start: 10px;
  }

  .pd-xs-r-10 {
    padding-inline-end: 10px;
  }

  .pd-xs-b-10 {
    padding-block-end: 10px;
  }

  .pd-xs-l-10 {
    padding-inline-start: 10px;
  }

  .pd-xs-15 {
    padding: 15px;
  }

  .pd-xs-y-15 {
    padding-block-start: 15px;
    padding-block-end: 15px;
  }

  .pd-xs-x-15 {
    padding-inline-start: 15px;
    padding-inline-end: 15px;
  }

  .pd-xs-t-15 {
    padding-block-start: 15px;
  }

  .pd-xs-r-15 {
    padding-inline-end: 15px;
  }

  .pd-xs-b-15 {
    padding-block-end: 15px;
  }

  .pd-xs-l-15 {
    padding-inline-start: 15px;
  }

  .pd-xs-20 {
    padding: 20px;
  }

  .pd-xs-y-20 {
    padding-block-start: 20px;
    padding-block-end: 20px;
  }

  .pd-xs-x-20 {
    padding-inline-start: 20px;
    padding-inline-end: 20px;
  }

  .pd-xs-t-20 {
    padding-block-start: 20px;
  }

  .pd-xs-r-20 {
    padding-inline-end: 20px;
  }

  .pd-xs-b-20 {
    padding-block-end: 20px;
  }

  .pd-xs-l-20 {
    padding-inline-start: 20px;
  }

  .pd-xs-25 {
    padding: 25px;
  }

  .pd-xs-y-25 {
    padding-block-start: 25px;
    padding-block-end: 25px;
  }

  .pd-xs-x-25 {
    padding-inline-start: 25px;
    padding-inline-end: 25px;
  }

  .pd-xs-t-25 {
    padding-block-start: 25px;
  }

  .pd-xs-r-25 {
    padding-inline-end: 25px;
  }

  .pd-xs-b-25 {
    padding-block-end: 25px;
  }

  .pd-xs-l-25 {
    padding-inline-start: 25px;
  }

  .pd-xs-30 {
    padding: 30px;
  }

  .pd-xs-y-30 {
    padding-block-start: 30px;
    padding-block-end: 30px;
  }

  .pd-xs-x-30 {
    padding-inline-start: 30px;
    padding-inline-end: 30px;
  }

  .pd-xs-t-30 {
    padding-block-start: 30px;
  }

  .pd-xs-r-30 {
    padding-inline-end: 30px;
  }

  .pd-xs-b-30 {
    padding-block-end: 30px;
  }

  .pd-xs-l-30 {
    padding-inline-start: 30px;
  }

  .pd-xs-35 {
    padding: 35px;
  }

  .pd-xs-y-35 {
    padding-block-start: 35px;
    padding-block-end: 35px;
  }

  .pd-xs-x-35 {
    padding-inline-start: 35px;
    padding-inline-end: 35px;
  }

  .pd-xs-t-35 {
    padding-block-start: 35px;
  }

  .pd-xs-r-35 {
    padding-inline-end: 35px;
  }

  .pd-xs-b-35 {
    padding-block-end: 35px;
  }

  .pd-xs-l-35 {
    padding-inline-start: 35px;
  }

  .pd-xs-40 {
    padding: 40px;
  }

  .pd-xs-y-40 {
    padding-block-start: 40px;
    padding-block-end: 40px;
  }

  .pd-xs-x-40 {
    padding-inline-start: 40px;
    padding-inline-end: 40px;
  }

  .pd-xs-t-40 {
    padding-block-start: 40px;
  }

  .pd-xs-r-40 {
    padding-inline-end: 40px;
  }

  .pd-xs-b-40 {
    padding-block-end: 40px;
  }

  .pd-xs-l-40 {
    padding-inline-start: 40px;
  }

  .pd-xs-45 {
    padding: 45px;
  }

  .pd-xs-y-45 {
    padding-block-start: 45px;
    padding-block-end: 45px;
  }

  .pd-xs-x-45 {
    padding-inline-start: 45px;
    padding-inline-end: 45px;
  }

  .pd-xs-t-45 {
    padding-block-start: 45px;
  }

  .pd-xs-r-45 {
    padding-inline-end: 45px;
  }

  .pd-xs-b-45 {
    padding-block-end: 45px;
  }

  .pd-xs-l-45 {
    padding-inline-start: 45px;
  }

  .pd-xs-50 {
    padding: 50px;
  }

  .pd-xs-y-50 {
    padding-block-start: 50px;
    padding-block-end: 50px;
  }

  .pd-xs-x-50 {
    padding-inline-start: 50px;
    padding-inline-end: 50px;
  }

  .pd-xs-t-50 {
    padding-block-start: 50px;
  }

  .pd-xs-r-50 {
    padding-inline-end: 50px;
  }

  .pd-xs-b-50 {
    padding-block-end: 50px;
  }

  .pd-xs-l-50 {
    padding-inline-start: 50px;
  }

  .pd-xs-55 {
    padding: 55px;
  }

  .pd-xs-y-55 {
    padding-block-start: 55px;
    padding-block-end: 55px;
  }

  .pd-xs-x-55 {
    padding-inline-start: 55px;
    padding-inline-end: 55px;
  }

  .pd-xs-t-55 {
    padding-block-start: 55px;
  }

  .pd-xs-r-55 {
    padding-inline-end: 55px;
  }

  .pd-xs-b-55 {
    padding-block-end: 55px;
  }

  .pd-xs-l-55 {
    padding-inline-start: 55px;
  }

  .pd-xs-60 {
    padding: 60px;
  }

  .pd-xs-y-60 {
    padding-block-start: 60px;
    padding-block-end: 60px;
  }

  .pd-xs-x-60 {
    padding-inline-start: 60px;
    padding-inline-end: 60px;
  }

  .pd-xs-t-60 {
    padding-block-start: 60px;
  }

  .pd-xs-r-60 {
    padding-inline-end: 60px;
  }

  .pd-xs-b-60 {
    padding-block-end: 60px;
  }

  .pd-xs-l-60 {
    padding-inline-start: 60px;
  }

  .pd-xs-65 {
    padding: 65px;
  }

  .pd-xs-y-65 {
    padding-block-start: 65px;
    padding-block-end: 65px;
  }

  .pd-xs-x-65 {
    padding-inline-start: 65px;
    padding-inline-end: 65px;
  }

  .pd-xs-t-65 {
    padding-block-start: 65px;
  }

  .pd-xs-r-65 {
    padding-inline-end: 65px;
  }

  .pd-xs-b-65 {
    padding-block-end: 65px;
  }

  .pd-xs-l-65 {
    padding-inline-start: 65px;
  }

  .pd-xs-70 {
    padding: 70px;
  }

  .pd-xs-y-70 {
    padding-block-start: 70px;
    padding-block-end: 70px;
  }

  .pd-xs-x-70 {
    padding-inline-start: 70px;
    padding-inline-end: 70px;
  }

  .pd-xs-t-70 {
    padding-block-start: 70px;
  }

  .pd-xs-r-70 {
    padding-inline-end: 70px;
  }

  .pd-xs-b-70 {
    padding-block-end: 70px;
  }

  .pd-xs-l-70 {
    padding-inline-start: 70px;
  }

  .pd-xs-75 {
    padding: 75px;
  }

  .pd-xs-y-75 {
    padding-block-start: 75px;
    padding-block-end: 75px;
  }

  .pd-xs-x-75 {
    padding-inline-start: 75px;
    padding-inline-end: 75px;
  }

  .pd-xs-t-75 {
    padding-block-start: 75px;
  }

  .pd-xs-r-75 {
    padding-inline-end: 75px;
  }

  .pd-xs-b-75 {
    padding-block-end: 75px;
  }

  .pd-xs-l-75 {
    padding-inline-start: 75px;
  }

  .pd-xs-80 {
    padding: 80px;
  }

  .pd-xs-y-80 {
    padding-block-start: 80px;
    padding-block-end: 80px;
  }

  .pd-xs-x-80 {
    padding-inline-start: 80px;
    padding-inline-end: 80px;
  }

  .pd-xs-t-80 {
    padding-block-start: 80px;
  }

  .pd-xs-r-80 {
    padding-inline-end: 80px;
  }

  .pd-xs-b-80 {
    padding-block-end: 80px;
  }

  .pd-xs-l-80 {
    padding-inline-start: 80px;
  }

  .pd-xs-85 {
    padding: 85px;
  }

  .pd-xs-y-85 {
    padding-block-start: 85px;
    padding-block-end: 85px;
  }

  .pd-xs-x-85 {
    padding-inline-start: 85px;
    padding-inline-end: 85px;
  }

  .pd-xs-t-85 {
    padding-block-start: 85px;
  }

  .pd-xs-r-85 {
    padding-inline-end: 85px;
  }

  .pd-xs-b-85 {
    padding-block-end: 85px;
  }

  .pd-xs-l-85 {
    padding-inline-start: 85px;
  }

  .pd-xs-90 {
    padding: 90px;
  }

  .pd-xs-y-90 {
    padding-block-start: 90px;
    padding-block-end: 90px;
  }

  .pd-xs-x-90 {
    padding-inline-start: 90px;
    padding-inline-end: 90px;
  }

  .pd-xs-t-90 {
    padding-block-start: 90px;
  }

  .pd-xs-r-90 {
    padding-inline-end: 90px;
  }

  .pd-xs-b-90 {
    padding-block-end: 90px;
  }

  .pd-xs-l-90 {
    padding-inline-start: 90px;
  }

  .pd-xs-95 {
    padding: 95px;
  }

  .pd-xs-y-95 {
    padding-block-start: 95px;
    padding-block-end: 95px;
  }

  .pd-xs-x-95 {
    padding-inline-start: 95px;
    padding-inline-end: 95px;
  }

  .pd-xs-t-95 {
    padding-block-start: 95px;
  }

  .pd-xs-r-95 {
    padding-inline-end: 95px;
  }

  .pd-xs-b-95 {
    padding-block-end: 95px;
  }

  .pd-xs-l-95 {
    padding-inline-start: 95px;
  }

  .pd-xs-100 {
    padding: 100px;
  }

  .pd-xs-y-100 {
    padding-block-start: 100px;
    padding-block-end: 100px;
  }

  .pd-xs-x-100 {
    padding-inline-start: 100px;
    padding-inline-end: 100px;
  }

  .pd-xs-t-100 {
    padding-block-start: 100px;
  }

  .pd-xs-r-100 {
    padding-inline-end: 100px;
  }

  .pd-xs-b-100 {
    padding-block-end: 100px;
  }

  .pd-xs-l-100 {
    padding-inline-start: 100px;
  }

  .pd-xs-110 {
    padding: 110px;
  }

  .pd-xs-y-110 {
    padding-block-start: 110px;
    padding-block-end: 110px;
  }

  .pd-xs-x-110 {
    padding-inline-start: 110px;
    padding-inline-end: 110px;
  }

  .pd-xs-t-110 {
    padding-block-start: 110px;
  }

  .pd-xs-r-110 {
    padding-inline-end: 110px;
  }

  .pd-xs-b-110 {
    padding-block-end: 110px;
  }

  .pd-xs-l-110 {
    padding-inline-start: 110px;
  }

  .pd-xs-120 {
    padding: 120px;
  }

  .pd-xs-y-120 {
    padding-block-start: 120px;
    padding-block-end: 120px;
  }

  .pd-xs-x-120 {
    padding-inline-start: 120px;
    padding-inline-end: 120px;
  }

  .pd-xs-t-120 {
    padding-block-start: 120px;
  }

  .pd-xs-r-120 {
    padding-inline-end: 120px;
  }

  .pd-xs-b-120 {
    padding-block-end: 120px;
  }

  .pd-xs-l-120 {
    padding-inline-start: 120px;
  }

  .pd-xs-130 {
    padding: 130px;
  }

  .pd-xs-y-130 {
    padding-block-start: 130px;
    padding-block-end: 130px;
  }

  .pd-xs-x-130 {
    padding-inline-start: 130px;
    padding-inline-end: 130px;
  }

  .pd-xs-t-130 {
    padding-block-start: 130px;
  }

  .pd-xs-r-130 {
    padding-inline-end: 130px;
  }

  .pd-xs-b-130 {
    padding-block-end: 130px;
  }

  .pd-xs-l-130 {
    padding-inline-start: 130px;
  }

  .pd-xs-140 {
    padding: 140px;
  }

  .pd-xs-y-140 {
    padding-block-start: 140px;
    padding-block-end: 140px;
  }

  .pd-xs-x-140 {
    padding-inline-start: 140px;
    padding-inline-end: 140px;
  }

  .pd-xs-t-140 {
    padding-block-start: 140px;
  }

  .pd-xs-r-140 {
    padding-inline-end: 140px;
  }

  .pd-xs-b-140 {
    padding-block-end: 140px;
  }

  .pd-xs-l-140 {
    padding-inline-start: 140px;
  }

  .pd-xs-150 {
    padding: 150px;
  }

  .pd-xs-y-150 {
    padding-block-start: 150px;
    padding-block-end: 150px;
  }

  .pd-xs-x-150 {
    padding-inline-start: 150px;
    padding-inline-end: 150px;
  }

  .pd-xs-t-150 {
    padding-block-start: 150px;
  }

  .pd-xs-r-150 {
    padding-inline-end: 150px;
  }

  .pd-xs-b-150 {
    padding-block-end: 150px;
  }

  .pd-xs-l-150 {
    padding-inline-start: 150px;
  }

  .pd-xs-160 {
    padding: 160px;
  }

  .pd-xs-y-160 {
    padding-block-start: 160px;
    padding-block-end: 160px;
  }

  .pd-xs-x-160 {
    padding-inline-start: 160px;
    padding-inline-end: 160px;
  }

  .pd-xs-t-160 {
    padding-block-start: 160px;
  }

  .pd-xs-r-160 {
    padding-inline-end: 160px;
  }

  .pd-xs-b-160 {
    padding-block-end: 160px;
  }

  .pd-xs-l-160 {
    padding-inline-start: 160px;
  }

  .pd-xs-170 {
    padding: 170px;
  }

  .pd-xs-y-170 {
    padding-block-start: 170px;
    padding-block-end: 170px;
  }

  .pd-xs-x-170 {
    padding-inline-start: 170px;
    padding-inline-end: 170px;
  }

  .pd-xs-t-170 {
    padding-block-start: 170px;
  }

  .pd-xs-r-170 {
    padding-inline-end: 170px;
  }

  .pd-xs-b-170 {
    padding-block-end: 170px;
  }

  .pd-xs-l-170 {
    padding-inline-start: 170px;
  }

  .pd-xs-180 {
    padding: 180px;
  }

  .pd-xs-y-180 {
    padding-block-start: 180px;
    padding-block-end: 180px;
  }

  .pd-xs-x-180 {
    padding-inline-start: 180px;
    padding-inline-end: 180px;
  }

  .pd-xs-t-180 {
    padding-block-start: 180px;
  }

  .pd-xs-r-180 {
    padding-inline-end: 180px;
  }

  .pd-xs-b-180 {
    padding-block-end: 180px;
  }

  .pd-xs-l-180 {
    padding-inline-start: 180px;
  }

  .pd-xs-190 {
    padding: 190px;
  }

  .pd-xs-y-190 {
    padding-block-start: 190px;
    padding-block-end: 190px;
  }

  .pd-xs-x-190 {
    padding-inline-start: 190px;
    padding-inline-end: 190px;
  }

  .pd-xs-t-190 {
    padding-block-start: 190px;
  }

  .pd-xs-r-190 {
    padding-inline-end: 190px;
  }

  .pd-xs-b-190 {
    padding-block-end: 190px;
  }

  .pd-xs-l-190 {
    padding-inline-start: 190px;
  }

  .pd-xs-200 {
    padding: 200px;
  }

  .pd-xs-y-200 {
    padding-block-start: 200px;
    padding-block-end: 200px;
  }

  .pd-xs-x-200 {
    padding-inline-start: 200px;
    padding-inline-end: 200px;
  }

  .pd-xs-t-200 {
    padding-block-start: 200px;
  }

  .pd-xs-r-200 {
    padding-inline-end: 200px;
  }

  .pd-xs-b-200 {
    padding-block-end: 200px;
  }

  .pd-xs-l-200 {
    padding-inline-start: 200px;
  }
}

@media (min-width: 576px) {
  .pd-sm-0 {
    padding: 0px;
  }

  .pd-sm-y-0 {
    padding-block-start: 0px;
    padding-block-end: 0px;
  }

  .pd-sm-x-0 {
    padding-inline-start: 0px;
    padding-inline-end: 0px;
  }

  .pd-sm-t-0 {
    padding-block-start: 0px;
  }

  .pd-sm-r-0 {
    padding-inline-end: 0px;
  }

  .pd-sm-b-0 {
    padding-block-end: 0px;
  }

  .pd-sm-l-0 {
    padding-inline-start: 0px;
  }

  .pd-sm-1 {
    padding: 1px;
  }

  .pd-sm-y-1 {
    padding-block-start: 1px;
    padding-block-end: 1px;
  }

  .pd-sm-x-1 {
    padding-inline-start: 1px;
    padding-inline-end: 1px;
  }

  .pd-sm-t-1 {
    padding-block-start: 1px;
  }

  .pd-sm-r-1 {
    padding-inline-end: 1px;
  }

  .pd-sm-b-1 {
    padding-block-end: 1px;
  }

  .pd-sm-l-1 {
    padding-inline-start: 1px;
  }

  .pd-sm-2 {
    padding: 2px;
  }

  .pd-sm-y-2 {
    padding-block-start: 2px;
    padding-block-end: 2px;
  }

  .pd-sm-x-2 {
    padding-inline-start: 2px;
    padding-inline-end: 2px;
  }

  .pd-sm-t-2 {
    padding-block-start: 2px;
  }

  .pd-sm-r-2 {
    padding-inline-end: 2px;
  }

  .pd-sm-b-2 {
    padding-block-end: 2px;
  }

  .pd-sm-l-2 {
    padding-inline-start: 2px;
  }

  .pd-sm-3 {
    padding: 3px;
  }

  .pd-sm-y-3 {
    padding-block-start: 3px;
    padding-block-end: 3px;
  }

  .pd-sm-x-3 {
    padding-inline-start: 3px;
    padding-inline-end: 3px;
  }

  .pd-sm-t-3 {
    padding-block-start: 3px;
  }

  .pd-sm-r-3 {
    padding-inline-end: 3px;
  }

  .pd-sm-b-3 {
    padding-block-end: 3px;
  }

  .pd-sm-l-3 {
    padding-inline-start: 3px;
  }

  .pd-sm-4 {
    padding: 4px;
  }

  .pd-sm-y-4 {
    padding-block-start: 4px;
    padding-block-end: 4px;
  }

  .pd-sm-x-4 {
    padding-inline-start: 4px;
    padding-inline-end: 4px;
  }

  .pd-sm-t-4 {
    padding-block-start: 4px;
  }

  .pd-sm-r-4 {
    padding-inline-end: 4px;
  }

  .pd-sm-b-4 {
    padding-block-end: 4px;
  }

  .pd-sm-l-4 {
    padding-inline-start: 4px;
  }

  .pd-sm-5 {
    padding: 5px;
  }

  .pd-sm-y-5 {
    padding-block-start: 5px;
    padding-block-end: 5px;
  }

  .pd-sm-x-5 {
    padding-inline-start: 5px;
    padding-inline-end: 5px;
  }

  .pd-sm-t-5 {
    padding-block-start: 5px;
  }

  .pd-sm-r-5 {
    padding-inline-end: 5px;
  }

  .pd-sm-b-5 {
    padding-block-end: 5px;
  }

  .pd-sm-l-5 {
    padding-inline-start: 5px;
  }

  .pd-sm-6 {
    padding: 6px;
  }

  .pd-sm-y-6 {
    padding-block-start: 6px;
    padding-block-end: 6px;
  }

  .pd-sm-x-6 {
    padding-inline-start: 6px;
    padding-inline-end: 6px;
  }

  .pd-sm-t-6 {
    padding-block-start: 6px;
  }

  .pd-sm-r-6 {
    padding-inline-end: 6px;
  }

  .pd-sm-b-6 {
    padding-block-end: 6px;
  }

  .pd-sm-l-6 {
    padding-inline-start: 6px;
  }

  .pd-sm-7 {
    padding: 7px;
  }

  .pd-sm-y-7 {
    padding-block-start: 7px;
    padding-block-end: 7px;
  }

  .pd-sm-x-7 {
    padding-inline-start: 7px;
    padding-inline-end: 7px;
  }

  .pd-sm-t-7 {
    padding-block-start: 7px;
  }

  .pd-sm-r-7 {
    padding-inline-end: 7px;
  }

  .pd-sm-b-7 {
    padding-block-end: 7px;
  }

  .pd-sm-l-7 {
    padding-inline-start: 7px;
  }

  .pd-sm-8 {
    padding: 8px;
  }

  .pd-sm-y-8 {
    padding-block-start: 8px;
    padding-block-end: 8px;
  }

  .pd-sm-x-8 {
    padding-inline-start: 8px;
    padding-inline-end: 8px;
  }

  .pd-sm-t-8 {
    padding-block-start: 8px;
  }

  .pd-sm-r-8 {
    padding-inline-end: 8px;
  }

  .pd-sm-b-8 {
    padding-block-end: 8px;
  }

  .pd-sm-l-8 {
    padding-inline-start: 8px;
  }

  .pd-sm-9 {
    padding: 9px;
  }

  .pd-sm-y-9 {
    padding-block-start: 9px;
    padding-block-end: 9px;
  }

  .pd-sm-x-9 {
    padding-inline-start: 9px;
    padding-inline-end: 9px;
  }

  .pd-sm-t-9 {
    padding-block-start: 9px;
  }

  .pd-sm-r-9 {
    padding-inline-end: 9px;
  }

  .pd-sm-b-9 {
    padding-block-end: 9px;
  }

  .pd-sm-l-9 {
    padding-inline-start: 9px;
  }

  .pd-sm-10 {
    padding: 10px;
  }

  .pd-sm-y-10 {
    padding-block-start: 10px;
    padding-block-end: 10px;
  }

  .pd-sm-x-10 {
    padding-inline-start: 10px;
    padding-inline-end: 10px;
  }

  .pd-sm-t-10 {
    padding-block-start: 10px;
  }

  .pd-sm-r-10 {
    padding-inline-end: 10px;
  }

  .pd-sm-b-10 {
    padding-block-end: 10px;
  }

  .pd-sm-l-10 {
    padding-inline-start: 10px;
  }

  .pd-sm-15 {
    padding: 15px;
  }

  .pd-sm-y-15 {
    padding-block-start: 15px;
    padding-block-end: 15px;
  }

  .pd-sm-x-15 {
    padding-inline-start: 15px;
    padding-inline-end: 15px;
  }

  .pd-sm-t-15 {
    padding-block-start: 15px;
  }

  .pd-sm-r-15 {
    padding-inline-end: 15px;
  }

  .pd-sm-b-15 {
    padding-block-end: 15px;
  }

  .pd-sm-l-15 {
    padding-inline-start: 15px;
  }

  .pd-sm-20 {
    padding: 20px;
  }

  .pd-sm-y-20 {
    padding-block-start: 20px;
    padding-block-end: 20px;
  }

  .pd-sm-x-20 {
    padding-inline-start: 20px;
    padding-inline-end: 20px;
  }

  .pd-sm-t-20 {
    padding-block-start: 20px;
  }

  .pd-sm-r-20 {
    padding-inline-end: 20px;
  }

  .pd-sm-b-20 {
    padding-block-end: 20px;
  }

  .pd-sm-l-20 {
    padding-inline-start: 20px;
  }

  .pd-sm-25 {
    padding: 25px;
  }

  .pd-sm-y-25 {
    padding-block-start: 25px;
    padding-block-end: 25px;
  }

  .pd-sm-x-25 {
    padding-inline-start: 25px;
    padding-inline-end: 25px;
  }

  .pd-sm-t-25 {
    padding-block-start: 25px;
  }

  .pd-sm-r-25 {
    padding-inline-end: 25px;
  }

  .pd-sm-b-25 {
    padding-block-end: 25px;
  }

  .pd-sm-l-25 {
    padding-inline-start: 25px;
  }

  .pd-sm-30 {
    padding: 30px;
  }

  .pd-sm-y-30 {
    padding-block-start: 30px;
    padding-block-end: 30px;
  }

  .pd-sm-x-30 {
    padding-inline-start: 30px;
    padding-inline-end: 30px;
  }

  .pd-sm-t-30 {
    padding-block-start: 30px;
  }

  .pd-sm-r-30 {
    padding-inline-end: 30px;
  }

  .pd-sm-b-30 {
    padding-block-end: 30px;
  }

  .pd-sm-l-30 {
    padding-inline-start: 30px;
  }

  .pd-sm-35 {
    padding: 35px;
  }

  .pd-sm-y-35 {
    padding-block-start: 35px;
    padding-block-end: 35px;
  }

  .pd-sm-x-35 {
    padding-inline-start: 35px;
    padding-inline-end: 35px;
  }

  .pd-sm-t-35 {
    padding-block-start: 35px;
  }

  .pd-sm-r-35 {
    padding-inline-end: 35px;
  }

  .pd-sm-b-35 {
    padding-block-end: 35px;
  }

  .pd-sm-l-35 {
    padding-inline-start: 35px;
  }

  .pd-sm-40 {
    padding: 40px;
  }

  .pd-sm-y-40 {
    padding-block-start: 40px;
    padding-block-end: 40px;
  }

  .pd-sm-x-40 {
    padding-inline-start: 40px;
    padding-inline-end: 40px;
  }

  .pd-sm-t-40 {
    padding-block-start: 40px;
  }

  .pd-sm-r-40 {
    padding-inline-end: 40px;
  }

  .pd-sm-b-40 {
    padding-block-end: 40px;
  }

  .pd-sm-l-40 {
    padding-inline-start: 40px;
  }

  .pd-sm-45 {
    padding: 45px;
  }

  .pd-sm-y-45 {
    padding-block-start: 45px;
    padding-block-end: 45px;
  }

  .pd-sm-x-45 {
    padding-inline-start: 45px;
    padding-inline-end: 45px;
  }

  .pd-sm-t-45 {
    padding-block-start: 45px;
  }

  .pd-sm-r-45 {
    padding-inline-end: 45px;
  }

  .pd-sm-b-45 {
    padding-block-end: 45px;
  }

  .pd-sm-l-45 {
    padding-inline-start: 45px;
  }

  .pd-sm-50 {
    padding: 50px;
  }

  .pd-sm-y-50 {
    padding-block-start: 50px;
    padding-block-end: 50px;
  }

  .pd-sm-x-50 {
    padding-inline-start: 50px;
    padding-inline-end: 50px;
  }

  .pd-sm-t-50 {
    padding-block-start: 50px;
  }

  .pd-sm-r-50 {
    padding-inline-end: 50px;
  }

  .pd-sm-b-50 {
    padding-block-end: 50px;
  }

  .pd-sm-l-50 {
    padding-inline-start: 50px;
  }

  .pd-sm-55 {
    padding: 55px;
  }

  .pd-sm-y-55 {
    padding-block-start: 55px;
    padding-block-end: 55px;
  }

  .pd-sm-x-55 {
    padding-inline-start: 55px;
    padding-inline-end: 55px;
  }

  .pd-sm-t-55 {
    padding-block-start: 55px;
  }

  .pd-sm-r-55 {
    padding-inline-end: 55px;
  }

  .pd-sm-b-55 {
    padding-block-end: 55px;
  }

  .pd-sm-l-55 {
    padding-inline-start: 55px;
  }

  .pd-sm-60 {
    padding: 60px;
  }

  .pd-sm-y-60 {
    padding-block-start: 60px;
    padding-block-end: 60px;
  }

  .pd-sm-x-60 {
    padding-inline-start: 60px;
    padding-inline-end: 60px;
  }

  .pd-sm-t-60 {
    padding-block-start: 60px;
  }

  .pd-sm-r-60 {
    padding-inline-end: 60px;
  }

  .pd-sm-b-60 {
    padding-block-end: 60px;
  }

  .pd-sm-l-60 {
    padding-inline-start: 60px;
  }

  .pd-sm-65 {
    padding: 65px;
  }

  .pd-sm-y-65 {
    padding-block-start: 65px;
    padding-block-end: 65px;
  }

  .pd-sm-x-65 {
    padding-inline-start: 65px;
    padding-inline-end: 65px;
  }

  .pd-sm-t-65 {
    padding-block-start: 65px;
  }

  .pd-sm-r-65 {
    padding-inline-end: 65px;
  }

  .pd-sm-b-65 {
    padding-block-end: 65px;
  }

  .pd-sm-l-65 {
    padding-inline-start: 65px;
  }

  .pd-sm-70 {
    padding: 70px;
  }

  .pd-sm-y-70 {
    padding-block-start: 70px;
    padding-block-end: 70px;
  }

  .pd-sm-x-70 {
    padding-inline-start: 70px;
    padding-inline-end: 70px;
  }

  .pd-sm-t-70 {
    padding-block-start: 70px;
  }

  .pd-sm-r-70 {
    padding-inline-end: 70px;
  }

  .pd-sm-b-70 {
    padding-block-end: 70px;
  }

  .pd-sm-l-70 {
    padding-inline-start: 70px;
  }

  .pd-sm-75 {
    padding: 75px;
  }

  .pd-sm-y-75 {
    padding-block-start: 75px;
    padding-block-end: 75px;
  }

  .pd-sm-x-75 {
    padding-inline-start: 75px;
    padding-inline-end: 75px;
  }

  .pd-sm-t-75 {
    padding-block-start: 75px;
  }

  .pd-sm-r-75 {
    padding-inline-end: 75px;
  }

  .pd-sm-b-75 {
    padding-block-end: 75px;
  }

  .pd-sm-l-75 {
    padding-inline-start: 75px;
  }

  .pd-sm-80 {
    padding: 80px;
  }

  .pd-sm-y-80 {
    padding-block-start: 80px;
    padding-block-end: 80px;
  }

  .pd-sm-x-80 {
    padding-inline-start: 80px;
    padding-inline-end: 80px;
  }

  .pd-sm-t-80 {
    padding-block-start: 80px;
  }

  .pd-sm-r-80 {
    padding-inline-end: 80px;
  }

  .pd-sm-b-80 {
    padding-block-end: 80px;
  }

  .pd-sm-l-80 {
    padding-inline-start: 80px;
  }

  .pd-sm-85 {
    padding: 85px;
  }

  .pd-sm-y-85 {
    padding-block-start: 85px;
    padding-block-end: 85px;
  }

  .pd-sm-x-85 {
    padding-inline-start: 85px;
    padding-inline-end: 85px;
  }

  .pd-sm-t-85 {
    padding-block-start: 85px;
  }

  .pd-sm-r-85 {
    padding-inline-end: 85px;
  }

  .pd-sm-b-85 {
    padding-block-end: 85px;
  }

  .pd-sm-l-85 {
    padding-inline-start: 85px;
  }

  .pd-sm-90 {
    padding: 90px;
  }

  .pd-sm-y-90 {
    padding-block-start: 90px;
    padding-block-end: 90px;
  }

  .pd-sm-x-90 {
    padding-inline-start: 90px;
    padding-inline-end: 90px;
  }

  .pd-sm-t-90 {
    padding-block-start: 90px;
  }

  .pd-sm-r-90 {
    padding-inline-end: 90px;
  }

  .pd-sm-b-90 {
    padding-block-end: 90px;
  }

  .pd-sm-l-90 {
    padding-inline-start: 90px;
  }

  .pd-sm-95 {
    padding: 95px;
  }

  .pd-sm-y-95 {
    padding-block-start: 95px;
    padding-block-end: 95px;
  }

  .pd-sm-x-95 {
    padding-inline-start: 95px;
    padding-inline-end: 95px;
  }

  .pd-sm-t-95 {
    padding-block-start: 95px;
  }

  .pd-sm-r-95 {
    padding-inline-end: 95px;
  }

  .pd-sm-b-95 {
    padding-block-end: 95px;
  }

  .pd-sm-l-95 {
    padding-inline-start: 95px;
  }

  .pd-sm-100 {
    padding: 100px;
  }

  .pd-sm-y-100 {
    padding-block-start: 100px;
    padding-block-end: 100px;
  }

  .pd-sm-x-100 {
    padding-inline-start: 100px;
    padding-inline-end: 100px;
  }

  .pd-sm-t-100 {
    padding-block-start: 100px;
  }

  .pd-sm-r-100 {
    padding-inline-end: 100px;
  }

  .pd-sm-b-100 {
    padding-block-end: 100px;
  }

  .pd-sm-l-100 {
    padding-inline-start: 100px;
  }

  .pd-sm-110 {
    padding: 110px;
  }

  .pd-sm-y-110 {
    padding-block-start: 110px;
    padding-block-end: 110px;
  }

  .pd-sm-x-110 {
    padding-inline-start: 110px;
    padding-inline-end: 110px;
  }

  .pd-sm-t-110 {
    padding-block-start: 110px;
  }

  .pd-sm-r-110 {
    padding-inline-end: 110px;
  }

  .pd-sm-b-110 {
    padding-block-end: 110px;
  }

  .pd-sm-l-110 {
    padding-inline-start: 110px;
  }

  .pd-sm-120 {
    padding: 120px;
  }

  .pd-sm-y-120 {
    padding-block-start: 120px;
    padding-block-end: 120px;
  }

  .pd-sm-x-120 {
    padding-inline-start: 120px;
    padding-inline-end: 120px;
  }

  .pd-sm-t-120 {
    padding-block-start: 120px;
  }

  .pd-sm-r-120 {
    padding-inline-end: 120px;
  }

  .pd-sm-b-120 {
    padding-block-end: 120px;
  }

  .pd-sm-l-120 {
    padding-inline-start: 120px;
  }

  .pd-sm-130 {
    padding: 130px;
  }

  .pd-sm-y-130 {
    padding-block-start: 130px;
    padding-block-end: 130px;
  }

  .pd-sm-x-130 {
    padding-inline-start: 130px;
    padding-inline-end: 130px;
  }

  .pd-sm-t-130 {
    padding-block-start: 130px;
  }

  .pd-sm-r-130 {
    padding-inline-end: 130px;
  }

  .pd-sm-b-130 {
    padding-block-end: 130px;
  }

  .pd-sm-l-130 {
    padding-inline-start: 130px;
  }

  .pd-sm-140 {
    padding: 140px;
  }

  .pd-sm-y-140 {
    padding-block-start: 140px;
    padding-block-end: 140px;
  }

  .pd-sm-x-140 {
    padding-inline-start: 140px;
    padding-inline-end: 140px;
  }

  .pd-sm-t-140 {
    padding-block-start: 140px;
  }

  .pd-sm-r-140 {
    padding-inline-end: 140px;
  }

  .pd-sm-b-140 {
    padding-block-end: 140px;
  }

  .pd-sm-l-140 {
    padding-inline-start: 140px;
  }

  .pd-sm-150 {
    padding: 150px;
  }

  .pd-sm-y-150 {
    padding-block-start: 150px;
    padding-block-end: 150px;
  }

  .pd-sm-x-150 {
    padding-inline-start: 150px;
    padding-inline-end: 150px;
  }

  .pd-sm-t-150 {
    padding-block-start: 150px;
  }

  .pd-sm-r-150 {
    padding-inline-end: 150px;
  }

  .pd-sm-b-150 {
    padding-block-end: 150px;
  }

  .pd-sm-l-150 {
    padding-inline-start: 150px;
  }

  .pd-sm-160 {
    padding: 160px;
  }

  .pd-sm-y-160 {
    padding-block-start: 160px;
    padding-block-end: 160px;
  }

  .pd-sm-x-160 {
    padding-inline-start: 160px;
    padding-inline-end: 160px;
  }

  .pd-sm-t-160 {
    padding-block-start: 160px;
  }

  .pd-sm-r-160 {
    padding-inline-end: 160px;
  }

  .pd-sm-b-160 {
    padding-block-end: 160px;
  }

  .pd-sm-l-160 {
    padding-inline-start: 160px;
  }

  .pd-sm-170 {
    padding: 170px;
  }

  .pd-sm-y-170 {
    padding-block-start: 170px;
    padding-block-end: 170px;
  }

  .pd-sm-x-170 {
    padding-inline-start: 170px;
    padding-inline-end: 170px;
  }

  .pd-sm-t-170 {
    padding-block-start: 170px;
  }

  .pd-sm-r-170 {
    padding-inline-end: 170px;
  }

  .pd-sm-b-170 {
    padding-block-end: 170px;
  }

  .pd-sm-l-170 {
    padding-inline-start: 170px;
  }

  .pd-sm-180 {
    padding: 180px;
  }

  .pd-sm-y-180 {
    padding-block-start: 180px;
    padding-block-end: 180px;
  }

  .pd-sm-x-180 {
    padding-inline-start: 180px;
    padding-inline-end: 180px;
  }

  .pd-sm-t-180 {
    padding-block-start: 180px;
  }

  .pd-sm-r-180 {
    padding-inline-end: 180px;
  }

  .pd-sm-b-180 {
    padding-block-end: 180px;
  }

  .pd-sm-l-180 {
    padding-inline-start: 180px;
  }

  .pd-sm-190 {
    padding: 190px;
  }

  .pd-sm-y-190 {
    padding-block-start: 190px;
    padding-block-end: 190px;
  }

  .pd-sm-x-190 {
    padding-inline-start: 190px;
    padding-inline-end: 190px;
  }

  .pd-sm-t-190 {
    padding-block-start: 190px;
  }

  .pd-sm-r-190 {
    padding-inline-end: 190px;
  }

  .pd-sm-b-190 {
    padding-block-end: 190px;
  }

  .pd-sm-l-190 {
    padding-inline-start: 190px;
  }

  .pd-sm-200 {
    padding: 200px;
  }

  .pd-sm-y-200 {
    padding-block-start: 200px;
    padding-block-end: 200px;
  }

  .pd-sm-x-200 {
    padding-inline-start: 200px;
    padding-inline-end: 200px;
  }

  .pd-sm-t-200 {
    padding-block-start: 200px;
  }

  .pd-sm-r-200 {
    padding-inline-end: 200px;
  }

  .pd-sm-b-200 {
    padding-block-end: 200px;
  }

  .pd-sm-l-200 {
    padding-inline-start: 200px;
  }
}

@media (min-width: 768px) {
  .pd-md-0 {
    padding: 0px;
  }

  .pd-md-y-0 {
    padding-block-start: 0px;
    padding-block-end: 0px;
  }

  .pd-md-x-0 {
    padding-inline-start: 0px;
    padding-inline-end: 0px;
  }

  .pd-md-t-0 {
    padding-block-start: 0px;
  }

  .pd-md-r-0 {
    padding-inline-end: 0px;
  }

  .pd-md-b-0 {
    padding-block-end: 0px;
  }

  .pd-md-l-0 {
    padding-inline-start: 0px;
  }

  .pd-md-1 {
    padding: 1px;
  }

  .pd-md-y-1 {
    padding-block-start: 1px;
    padding-block-end: 1px;
  }

  .pd-md-x-1 {
    padding-inline-start: 1px;
    padding-inline-end: 1px;
  }

  .pd-md-t-1 {
    padding-block-start: 1px;
  }

  .pd-md-r-1 {
    padding-inline-end: 1px;
  }

  .pd-md-b-1 {
    padding-block-end: 1px;
  }

  .pd-md-l-1 {
    padding-inline-start: 1px;
  }

  .pd-md-2 {
    padding: 2px;
  }

  .pd-md-y-2 {
    padding-block-start: 2px;
    padding-block-end: 2px;
  }

  .pd-md-x-2 {
    padding-inline-start: 2px;
    padding-inline-end: 2px;
  }

  .pd-md-t-2 {
    padding-block-start: 2px;
  }

  .pd-md-r-2 {
    padding-inline-end: 2px;
  }

  .pd-md-b-2 {
    padding-block-end: 2px;
  }

  .pd-md-l-2 {
    padding-inline-start: 2px;
  }

  .pd-md-3 {
    padding: 3px;
  }

  .pd-md-y-3 {
    padding-block-start: 3px;
    padding-block-end: 3px;
  }

  .pd-md-x-3 {
    padding-inline-start: 3px;
    padding-inline-end: 3px;
  }

  .pd-md-t-3 {
    padding-block-start: 3px;
  }

  .pd-md-r-3 {
    padding-inline-end: 3px;
  }

  .pd-md-b-3 {
    padding-block-end: 3px;
  }

  .pd-md-l-3 {
    padding-inline-start: 3px;
  }

  .pd-md-4 {
    padding: 4px;
  }

  .pd-md-y-4 {
    padding-block-start: 4px;
    padding-block-end: 4px;
  }

  .pd-md-x-4 {
    padding-inline-start: 4px;
    padding-inline-end: 4px;
  }

  .pd-md-t-4 {
    padding-block-start: 4px;
  }

  .pd-md-r-4 {
    padding-inline-end: 4px;
  }

  .pd-md-b-4 {
    padding-block-end: 4px;
  }

  .pd-md-l-4 {
    padding-inline-start: 4px;
  }

  .pd-md-5 {
    padding: 5px;
  }

  .pd-md-y-5 {
    padding-block-start: 5px;
    padding-block-end: 5px;
  }

  .pd-md-x-5 {
    padding-inline-start: 5px;
    padding-inline-end: 5px;
  }

  .pd-md-t-5 {
    padding-block-start: 5px;
  }

  .pd-md-r-5 {
    padding-inline-end: 5px;
  }

  .pd-md-b-5 {
    padding-block-end: 5px;
  }

  .pd-md-l-5 {
    padding-inline-start: 5px;
  }

  .pd-md-6 {
    padding: 6px;
  }

  .pd-md-y-6 {
    padding-block-start: 6px;
    padding-block-end: 6px;
  }

  .pd-md-x-6 {
    padding-inline-start: 6px;
    padding-inline-end: 6px;
  }

  .pd-md-t-6 {
    padding-block-start: 6px;
  }

  .pd-md-r-6 {
    padding-inline-end: 6px;
  }

  .pd-md-b-6 {
    padding-block-end: 6px;
  }

  .pd-md-l-6 {
    padding-inline-start: 6px;
  }

  .pd-md-7 {
    padding: 7px;
  }

  .pd-md-y-7 {
    padding-block-start: 7px;
    padding-block-end: 7px;
  }

  .pd-md-x-7 {
    padding-inline-start: 7px;
    padding-inline-end: 7px;
  }

  .pd-md-t-7 {
    padding-block-start: 7px;
  }

  .pd-md-r-7 {
    padding-inline-end: 7px;
  }

  .pd-md-b-7 {
    padding-block-end: 7px;
  }

  .pd-md-l-7 {
    padding-inline-start: 7px;
  }

  .pd-md-8 {
    padding: 8px;
  }

  .pd-md-y-8 {
    padding-block-start: 8px;
    padding-block-end: 8px;
  }

  .pd-md-x-8 {
    padding-inline-start: 8px;
    padding-inline-end: 8px;
  }

  .pd-md-t-8 {
    padding-block-start: 8px;
  }

  .pd-md-r-8 {
    padding-inline-end: 8px;
  }

  .pd-md-b-8 {
    padding-block-end: 8px;
  }

  .pd-md-l-8 {
    padding-inline-start: 8px;
  }

  .pd-md-9 {
    padding: 9px;
  }

  .pd-md-y-9 {
    padding-block-start: 9px;
    padding-block-end: 9px;
  }

  .pd-md-x-9 {
    padding-inline-start: 9px;
    padding-inline-end: 9px;
  }

  .pd-md-t-9 {
    padding-block-start: 9px;
  }

  .pd-md-r-9 {
    padding-inline-end: 9px;
  }

  .pd-md-b-9 {
    padding-block-end: 9px;
  }

  .pd-md-l-9 {
    padding-inline-start: 9px;
  }

  .pd-md-10 {
    padding: 10px;
  }

  .pd-md-y-10 {
    padding-block-start: 10px;
    padding-block-end: 10px;
  }

  .pd-md-x-10 {
    padding-inline-start: 10px;
    padding-inline-end: 10px;
  }

  .pd-md-t-10 {
    padding-block-start: 10px;
  }

  .pd-md-r-10 {
    padding-inline-end: 10px;
  }

  .pd-md-b-10 {
    padding-block-end: 10px;
  }

  .pd-md-l-10 {
    padding-inline-start: 10px;
  }

  .pd-md-15 {
    padding: 15px;
  }

  .pd-md-y-15 {
    padding-block-start: 15px;
    padding-block-end: 15px;
  }

  .pd-md-x-15 {
    padding-inline-start: 15px;
    padding-inline-end: 15px;
  }

  .pd-md-t-15 {
    padding-block-start: 15px;
  }

  .pd-md-r-15 {
    padding-inline-end: 15px;
  }

  .pd-md-b-15 {
    padding-block-end: 15px;
  }

  .pd-md-l-15 {
    padding-inline-start: 15px;
  }

  .pd-md-20 {
    padding: 20px;
  }

  .pd-md-y-20 {
    padding-block-start: 20px;
    padding-block-end: 20px;
  }

  .pd-md-x-20 {
    padding-inline-start: 20px;
    padding-inline-end: 20px;
  }

  .pd-md-t-20 {
    padding-block-start: 20px;
  }

  .pd-md-r-20 {
    padding-inline-end: 20px;
  }

  .pd-md-b-20 {
    padding-block-end: 20px;
  }

  .pd-md-l-20 {
    padding-inline-start: 20px;
  }

  .pd-md-25 {
    padding: 25px;
  }

  .pd-md-y-25 {
    padding-block-start: 25px;
    padding-block-end: 25px;
  }

  .pd-md-x-25 {
    padding-inline-start: 25px;
    padding-inline-end: 25px;
  }

  .pd-md-t-25 {
    padding-block-start: 25px;
  }

  .pd-md-r-25 {
    padding-inline-end: 25px;
  }

  .pd-md-b-25 {
    padding-block-end: 25px;
  }

  .pd-md-l-25 {
    padding-inline-start: 25px;
  }

  .pd-md-30 {
    padding: 30px;
  }

  .pd-md-y-30 {
    padding-block-start: 30px;
    padding-block-end: 30px;
  }

  .pd-md-x-30 {
    padding-inline-start: 30px;
    padding-inline-end: 30px;
  }

  .pd-md-t-30 {
    padding-block-start: 30px;
  }

  .pd-md-r-30 {
    padding-inline-end: 30px;
  }

  .pd-md-b-30 {
    padding-block-end: 30px;
  }

  .pd-md-l-30 {
    padding-inline-start: 30px;
  }

  .pd-md-35 {
    padding: 35px;
  }

  .pd-md-y-35 {
    padding-block-start: 35px;
    padding-block-end: 35px;
  }

  .pd-md-x-35 {
    padding-inline-start: 35px;
    padding-inline-end: 35px;
  }

  .pd-md-t-35 {
    padding-block-start: 35px;
  }

  .pd-md-r-35 {
    padding-inline-end: 35px;
  }

  .pd-md-b-35 {
    padding-block-end: 35px;
  }

  .pd-md-l-35 {
    padding-inline-start: 35px;
  }

  .pd-md-40 {
    padding: 40px;
  }

  .pd-md-y-40 {
    padding-block-start: 40px;
    padding-block-end: 40px;
  }

  .pd-md-x-40 {
    padding-inline-start: 40px;
    padding-inline-end: 40px;
  }

  .pd-md-t-40 {
    padding-block-start: 40px;
  }

  .pd-md-r-40 {
    padding-inline-end: 40px;
  }

  .pd-md-b-40 {
    padding-block-end: 40px;
  }

  .pd-md-l-40 {
    padding-inline-start: 40px;
  }

  .pd-md-45 {
    padding: 45px;
  }

  .pd-md-y-45 {
    padding-block-start: 45px;
    padding-block-end: 45px;
  }

  .pd-md-x-45 {
    padding-inline-start: 45px;
    padding-inline-end: 45px;
  }

  .pd-md-t-45 {
    padding-block-start: 45px;
  }

  .pd-md-r-45 {
    padding-inline-end: 45px;
  }

  .pd-md-b-45 {
    padding-block-end: 45px;
  }

  .pd-md-l-45 {
    padding-inline-start: 45px;
  }

  .pd-md-50 {
    padding: 50px;
  }

  .pd-md-y-50 {
    padding-block-start: 50px;
    padding-block-end: 50px;
  }

  .pd-md-x-50 {
    padding-inline-start: 50px;
    padding-inline-end: 50px;
  }

  .pd-md-t-50 {
    padding-block-start: 50px;
  }

  .pd-md-r-50 {
    padding-inline-end: 50px;
  }

  .pd-md-b-50 {
    padding-block-end: 50px;
  }

  .pd-md-l-50 {
    padding-inline-start: 50px;
  }

  .pd-md-55 {
    padding: 55px;
  }

  .pd-md-y-55 {
    padding-block-start: 55px;
    padding-block-end: 55px;
  }

  .pd-md-x-55 {
    padding-inline-start: 55px;
    padding-inline-end: 55px;
  }

  .pd-md-t-55 {
    padding-block-start: 55px;
  }

  .pd-md-r-55 {
    padding-inline-end: 55px;
  }

  .pd-md-b-55 {
    padding-block-end: 55px;
  }

  .pd-md-l-55 {
    padding-inline-start: 55px;
  }

  .pd-md-60 {
    padding: 60px;
  }

  .pd-md-y-60 {
    padding-block-start: 60px;
    padding-block-end: 60px;
  }

  .pd-md-x-60 {
    padding-inline-start: 60px;
    padding-inline-end: 60px;
  }

  .pd-md-t-60 {
    padding-block-start: 60px;
  }

  .pd-md-r-60 {
    padding-inline-end: 60px;
  }

  .pd-md-b-60 {
    padding-block-end: 60px;
  }

  .pd-md-l-60 {
    padding-inline-start: 60px;
  }

  .pd-md-65 {
    padding: 65px;
  }

  .pd-md-y-65 {
    padding-block-start: 65px;
    padding-block-end: 65px;
  }

  .pd-md-x-65 {
    padding-inline-start: 65px;
    padding-inline-end: 65px;
  }

  .pd-md-t-65 {
    padding-block-start: 65px;
  }

  .pd-md-r-65 {
    padding-inline-end: 65px;
  }

  .pd-md-b-65 {
    padding-block-end: 65px;
  }

  .pd-md-l-65 {
    padding-inline-start: 65px;
  }

  .pd-md-70 {
    padding: 70px;
  }

  .pd-md-y-70 {
    padding-block-start: 70px;
    padding-block-end: 70px;
  }

  .pd-md-x-70 {
    padding-inline-start: 70px;
    padding-inline-end: 70px;
  }

  .pd-md-t-70 {
    padding-block-start: 70px;
  }

  .pd-md-r-70 {
    padding-inline-end: 70px;
  }

  .pd-md-b-70 {
    padding-block-end: 70px;
  }

  .pd-md-l-70 {
    padding-inline-start: 70px;
  }

  .pd-md-75 {
    padding: 75px;
  }

  .pd-md-y-75 {
    padding-block-start: 75px;
    padding-block-end: 75px;
  }

  .pd-md-x-75 {
    padding-inline-start: 75px;
    padding-inline-end: 75px;
  }

  .pd-md-t-75 {
    padding-block-start: 75px;
  }

  .pd-md-r-75 {
    padding-inline-end: 75px;
  }

  .pd-md-b-75 {
    padding-block-end: 75px;
  }

  .pd-md-l-75 {
    padding-inline-start: 75px;
  }

  .pd-md-80 {
    padding: 80px;
  }

  .pd-md-y-80 {
    padding-block-start: 80px;
    padding-block-end: 80px;
  }

  .pd-md-x-80 {
    padding-inline-start: 80px;
    padding-inline-end: 80px;
  }

  .pd-md-t-80 {
    padding-block-start: 80px;
  }

  .pd-md-r-80 {
    padding-inline-end: 80px;
  }

  .pd-md-b-80 {
    padding-block-end: 80px;
  }

  .pd-md-l-80 {
    padding-inline-start: 80px;
  }

  .pd-md-85 {
    padding: 85px;
  }

  .pd-md-y-85 {
    padding-block-start: 85px;
    padding-block-end: 85px;
  }

  .pd-md-x-85 {
    padding-inline-start: 85px;
    padding-inline-end: 85px;
  }

  .pd-md-t-85 {
    padding-block-start: 85px;
  }

  .pd-md-r-85 {
    padding-inline-end: 85px;
  }

  .pd-md-b-85 {
    padding-block-end: 85px;
  }

  .pd-md-l-85 {
    padding-inline-start: 85px;
  }

  .pd-md-90 {
    padding: 90px;
  }

  .pd-md-y-90 {
    padding-block-start: 90px;
    padding-block-end: 90px;
  }

  .pd-md-x-90 {
    padding-inline-start: 90px;
    padding-inline-end: 90px;
  }

  .pd-md-t-90 {
    padding-block-start: 90px;
  }

  .pd-md-r-90 {
    padding-inline-end: 90px;
  }

  .pd-md-b-90 {
    padding-block-end: 90px;
  }

  .pd-md-l-90 {
    padding-inline-start: 90px;
  }

  .pd-md-95 {
    padding: 95px;
  }

  .pd-md-y-95 {
    padding-block-start: 95px;
    padding-block-end: 95px;
  }

  .pd-md-x-95 {
    padding-inline-start: 95px;
    padding-inline-end: 95px;
  }

  .pd-md-t-95 {
    padding-block-start: 95px;
  }

  .pd-md-r-95 {
    padding-inline-end: 95px;
  }

  .pd-md-b-95 {
    padding-block-end: 95px;
  }

  .pd-md-l-95 {
    padding-inline-start: 95px;
  }

  .pd-md-100 {
    padding: 100px;
  }

  .pd-md-y-100 {
    padding-block-start: 100px;
    padding-block-end: 100px;
  }

  .pd-md-x-100 {
    padding-inline-start: 100px;
    padding-inline-end: 100px;
  }

  .pd-md-t-100 {
    padding-block-start: 100px;
  }

  .pd-md-r-100 {
    padding-inline-end: 100px;
  }

  .pd-md-b-100 {
    padding-block-end: 100px;
  }

  .pd-md-l-100 {
    padding-inline-start: 100px;
  }

  .pd-md-110 {
    padding: 110px;
  }

  .pd-md-y-110 {
    padding-block-start: 110px;
    padding-block-end: 110px;
  }

  .pd-md-x-110 {
    padding-inline-start: 110px;
    padding-inline-end: 110px;
  }

  .pd-md-t-110 {
    padding-block-start: 110px;
  }

  .pd-md-r-110 {
    padding-inline-end: 110px;
  }

  .pd-md-b-110 {
    padding-block-end: 110px;
  }

  .pd-md-l-110 {
    padding-inline-start: 110px;
  }

  .pd-md-120 {
    padding: 120px;
  }

  .pd-md-y-120 {
    padding-block-start: 120px;
    padding-block-end: 120px;
  }

  .pd-md-x-120 {
    padding-inline-start: 120px;
    padding-inline-end: 120px;
  }

  .pd-md-t-120 {
    padding-block-start: 120px;
  }

  .pd-md-r-120 {
    padding-inline-end: 120px;
  }

  .pd-md-b-120 {
    padding-block-end: 120px;
  }

  .pd-md-l-120 {
    padding-inline-start: 120px;
  }

  .pd-md-130 {
    padding: 130px;
  }

  .pd-md-y-130 {
    padding-block-start: 130px;
    padding-block-end: 130px;
  }

  .pd-md-x-130 {
    padding-inline-start: 130px;
    padding-inline-end: 130px;
  }

  .pd-md-t-130 {
    padding-block-start: 130px;
  }

  .pd-md-r-130 {
    padding-inline-end: 130px;
  }

  .pd-md-b-130 {
    padding-block-end: 130px;
  }

  .pd-md-l-130 {
    padding-inline-start: 130px;
  }

  .pd-md-140 {
    padding: 140px;
  }

  .pd-md-y-140 {
    padding-block-start: 140px;
    padding-block-end: 140px;
  }

  .pd-md-x-140 {
    padding-inline-start: 140px;
    padding-inline-end: 140px;
  }

  .pd-md-t-140 {
    padding-block-start: 140px;
  }

  .pd-md-r-140 {
    padding-inline-end: 140px;
  }

  .pd-md-b-140 {
    padding-block-end: 140px;
  }

  .pd-md-l-140 {
    padding-inline-start: 140px;
  }

  .pd-md-150 {
    padding: 150px;
  }

  .pd-md-y-150 {
    padding-block-start: 150px;
    padding-block-end: 150px;
  }

  .pd-md-x-150 {
    padding-inline-start: 150px;
    padding-inline-end: 150px;
  }

  .pd-md-t-150 {
    padding-block-start: 150px;
  }

  .pd-md-r-150 {
    padding-inline-end: 150px;
  }

  .pd-md-b-150 {
    padding-block-end: 150px;
  }

  .pd-md-l-150 {
    padding-inline-start: 150px;
  }

  .pd-md-160 {
    padding: 160px;
  }

  .pd-md-y-160 {
    padding-block-start: 160px;
    padding-block-end: 160px;
  }

  .pd-md-x-160 {
    padding-inline-start: 160px;
    padding-inline-end: 160px;
  }

  .pd-md-t-160 {
    padding-block-start: 160px;
  }

  .pd-md-r-160 {
    padding-inline-end: 160px;
  }

  .pd-md-b-160 {
    padding-block-end: 160px;
  }

  .pd-md-l-160 {
    padding-inline-start: 160px;
  }

  .pd-md-170 {
    padding: 170px;
  }

  .pd-md-y-170 {
    padding-block-start: 170px;
    padding-block-end: 170px;
  }

  .pd-md-x-170 {
    padding-inline-start: 170px;
    padding-inline-end: 170px;
  }

  .pd-md-t-170 {
    padding-block-start: 170px;
  }

  .pd-md-r-170 {
    padding-inline-end: 170px;
  }

  .pd-md-b-170 {
    padding-block-end: 170px;
  }

  .pd-md-l-170 {
    padding-inline-start: 170px;
  }

  .pd-md-180 {
    padding: 180px;
  }

  .pd-md-y-180 {
    padding-block-start: 180px;
    padding-block-end: 180px;
  }

  .pd-md-x-180 {
    padding-inline-start: 180px;
    padding-inline-end: 180px;
  }

  .pd-md-t-180 {
    padding-block-start: 180px;
  }

  .pd-md-r-180 {
    padding-inline-end: 180px;
  }

  .pd-md-b-180 {
    padding-block-end: 180px;
  }

  .pd-md-l-180 {
    padding-inline-start: 180px;
  }

  .pd-md-190 {
    padding: 190px;
  }

  .pd-md-y-190 {
    padding-block-start: 190px;
    padding-block-end: 190px;
  }

  .pd-md-x-190 {
    padding-inline-start: 190px;
    padding-inline-end: 190px;
  }

  .pd-md-t-190 {
    padding-block-start: 190px;
  }

  .pd-md-r-190 {
    padding-inline-end: 190px;
  }

  .pd-md-b-190 {
    padding-block-end: 190px;
  }

  .pd-md-l-190 {
    padding-inline-start: 190px;
  }

  .pd-md-200 {
    padding: 200px;
  }

  .pd-md-y-200 {
    padding-block-start: 200px;
    padding-block-end: 200px;
  }

  .pd-md-x-200 {
    padding-inline-start: 200px;
    padding-inline-end: 200px;
  }

  .pd-md-t-200 {
    padding-block-start: 200px;
  }

  .pd-md-r-200 {
    padding-inline-end: 200px;
  }

  .pd-md-b-200 {
    padding-block-end: 200px;
  }

  .pd-md-l-200 {
    padding-inline-start: 200px;
  }
}

@media (min-width: 992px) {
  .pd-lg-0 {
    padding: 0px;
  }

  .pd-lg-y-0 {
    padding-block-start: 0px;
    padding-block-end: 0px;
  }

  .pd-lg-x-0 {
    padding-inline-start: 0px;
    padding-inline-end: 0px;
  }

  .pd-lg-t-0 {
    padding-block-start: 0px;
  }

  .pd-lg-r-0 {
    padding-inline-end: 0px;
  }

  .pd-lg-b-0 {
    padding-block-end: 0px;
  }

  .pd-lg-l-0 {
    padding-inline-start: 0px;
  }

  .pd-lg-1 {
    padding: 1px;
  }

  .pd-lg-y-1 {
    padding-block-start: 1px;
    padding-block-end: 1px;
  }

  .pd-lg-x-1 {
    padding-inline-start: 1px;
    padding-inline-end: 1px;
  }

  .pd-lg-t-1 {
    padding-block-start: 1px;
  }

  .pd-lg-r-1 {
    padding-inline-end: 1px;
  }

  .pd-lg-b-1 {
    padding-block-end: 1px;
  }

  .pd-lg-l-1 {
    padding-inline-start: 1px;
  }

  .pd-lg-2 {
    padding: 2px;
  }

  .pd-lg-y-2 {
    padding-block-start: 2px;
    padding-block-end: 2px;
  }

  .pd-lg-x-2 {
    padding-inline-start: 2px;
    padding-inline-end: 2px;
  }

  .pd-lg-t-2 {
    padding-block-start: 2px;
  }

  .pd-lg-r-2 {
    padding-inline-end: 2px;
  }

  .pd-lg-b-2 {
    padding-block-end: 2px;
  }

  .pd-lg-l-2 {
    padding-inline-start: 2px;
  }

  .pd-lg-3 {
    padding: 3px;
  }

  .pd-lg-y-3 {
    padding-block-start: 3px;
    padding-block-end: 3px;
  }

  .pd-lg-x-3 {
    padding-inline-start: 3px;
    padding-inline-end: 3px;
  }

  .pd-lg-t-3 {
    padding-block-start: 3px;
  }

  .pd-lg-r-3 {
    padding-inline-end: 3px;
  }

  .pd-lg-b-3 {
    padding-block-end: 3px;
  }

  .pd-lg-l-3 {
    padding-inline-start: 3px;
  }

  .pd-lg-4 {
    padding: 4px;
  }

  .pd-lg-y-4 {
    padding-block-start: 4px;
    padding-block-end: 4px;
  }

  .pd-lg-x-4 {
    padding-inline-start: 4px;
    padding-inline-end: 4px;
  }

  .pd-lg-t-4 {
    padding-block-start: 4px;
  }

  .pd-lg-r-4 {
    padding-inline-end: 4px;
  }

  .pd-lg-b-4 {
    padding-block-end: 4px;
  }

  .pd-lg-l-4 {
    padding-inline-start: 4px;
  }

  .pd-lg-5 {
    padding: 5px;
  }

  .pd-lg-y-5 {
    padding-block-start: 5px;
    padding-block-end: 5px;
  }

  .pd-lg-x-5 {
    padding-inline-start: 5px;
    padding-inline-end: 5px;
  }

  .pd-lg-t-5 {
    padding-block-start: 5px;
  }

  .pd-lg-r-5 {
    padding-inline-end: 5px;
  }

  .pd-lg-b-5 {
    padding-block-end: 5px;
  }

  .pd-lg-l-5 {
    padding-inline-start: 5px;
  }

  .pd-lg-6 {
    padding: 6px;
  }

  .pd-lg-y-6 {
    padding-block-start: 6px;
    padding-block-end: 6px;
  }

  .pd-lg-x-6 {
    padding-inline-start: 6px;
    padding-inline-end: 6px;
  }

  .pd-lg-t-6 {
    padding-block-start: 6px;
  }

  .pd-lg-r-6 {
    padding-inline-end: 6px;
  }

  .pd-lg-b-6 {
    padding-block-end: 6px;
  }

  .pd-lg-l-6 {
    padding-inline-start: 6px;
  }

  .pd-lg-7 {
    padding: 7px;
  }

  .pd-lg-y-7 {
    padding-block-start: 7px;
    padding-block-end: 7px;
  }

  .pd-lg-x-7 {
    padding-inline-start: 7px;
    padding-inline-end: 7px;
  }

  .pd-lg-t-7 {
    padding-block-start: 7px;
  }

  .pd-lg-r-7 {
    padding-inline-end: 7px;
  }

  .pd-lg-b-7 {
    padding-block-end: 7px;
  }

  .pd-lg-l-7 {
    padding-inline-start: 7px;
  }

  .pd-lg-8 {
    padding: 8px;
  }

  .pd-lg-y-8 {
    padding-block-start: 8px;
    padding-block-end: 8px;
  }

  .pd-lg-x-8 {
    padding-inline-start: 8px;
    padding-inline-end: 8px;
  }

  .pd-lg-t-8 {
    padding-block-start: 8px;
  }

  .pd-lg-r-8 {
    padding-inline-end: 8px;
  }

  .pd-lg-b-8 {
    padding-block-end: 8px;
  }

  .pd-lg-l-8 {
    padding-inline-start: 8px;
  }

  .pd-lg-9 {
    padding: 9px;
  }

  .pd-lg-y-9 {
    padding-block-start: 9px;
    padding-block-end: 9px;
  }

  .pd-lg-x-9 {
    padding-inline-start: 9px;
    padding-inline-end: 9px;
  }

  .pd-lg-t-9 {
    padding-block-start: 9px;
  }

  .pd-lg-r-9 {
    padding-inline-end: 9px;
  }

  .pd-lg-b-9 {
    padding-block-end: 9px;
  }

  .pd-lg-l-9 {
    padding-inline-start: 9px;
  }

  .pd-lg-10 {
    padding: 10px;
  }

  .pd-lg-y-10 {
    padding-block-start: 10px;
    padding-block-end: 10px;
  }

  .pd-lg-x-10 {
    padding-inline-start: 10px;
    padding-inline-end: 10px;
  }

  .pd-lg-t-10 {
    padding-block-start: 10px;
  }

  .pd-lg-r-10 {
    padding-inline-end: 10px;
  }

  .pd-lg-b-10 {
    padding-block-end: 10px;
  }

  .pd-lg-l-10 {
    padding-inline-start: 10px;
  }

  .pd-lg-15 {
    padding: 15px;
  }

  .pd-lg-y-15 {
    padding-block-start: 15px;
    padding-block-end: 15px;
  }

  .pd-lg-x-15 {
    padding-inline-start: 15px;
    padding-inline-end: 15px;
  }

  .pd-lg-t-15 {
    padding-block-start: 15px;
  }

  .pd-lg-r-15 {
    padding-inline-end: 15px;
  }

  .pd-lg-b-15 {
    padding-block-end: 15px;
  }

  .pd-lg-l-15 {
    padding-inline-start: 15px;
  }

  .pd-lg-20 {
    padding: 20px;
  }

  .pd-lg-y-20 {
    padding-block-start: 20px;
    padding-block-end: 20px;
  }

  .pd-lg-x-20 {
    padding-inline-start: 20px;
    padding-inline-end: 20px;
  }

  .pd-lg-t-20 {
    padding-block-start: 20px;
  }

  .pd-lg-r-20 {
    padding-inline-end: 20px;
  }

  .pd-lg-b-20 {
    padding-block-end: 20px;
  }

  .pd-lg-l-20 {
    padding-inline-start: 20px;
  }

  .pd-lg-25 {
    padding: 25px;
  }

  .pd-lg-y-25 {
    padding-block-start: 25px;
    padding-block-end: 25px;
  }

  .pd-lg-x-25 {
    padding-inline-start: 25px;
    padding-inline-end: 25px;
  }

  .pd-lg-t-25 {
    padding-block-start: 25px;
  }

  .pd-lg-r-25 {
    padding-inline-end: 25px;
  }

  .pd-lg-b-25 {
    padding-block-end: 25px;
  }

  .pd-lg-l-25 {
    padding-inline-start: 25px;
  }

  .pd-lg-30 {
    padding: 30px;
  }

  .pd-lg-y-30 {
    padding-block-start: 30px;
    padding-block-end: 30px;
  }

  .pd-lg-x-30 {
    padding-inline-start: 30px;
    padding-inline-end: 30px;
  }

  .pd-lg-t-30 {
    padding-block-start: 30px;
  }

  .pd-lg-r-30 {
    padding-inline-end: 30px;
  }

  .pd-lg-b-30 {
    padding-block-end: 30px;
  }

  .pd-lg-l-30 {
    padding-inline-start: 30px;
  }

  .pd-lg-35 {
    padding: 35px;
  }

  .pd-lg-y-35 {
    padding-block-start: 35px;
    padding-block-end: 35px;
  }

  .pd-lg-x-35 {
    padding-inline-start: 35px;
    padding-inline-end: 35px;
  }

  .pd-lg-t-35 {
    padding-block-start: 35px;
  }

  .pd-lg-r-35 {
    padding-inline-end: 35px;
  }

  .pd-lg-b-35 {
    padding-block-end: 35px;
  }

  .pd-lg-l-35 {
    padding-inline-start: 35px;
  }

  .pd-lg-40 {
    padding: 40px;
  }

  .pd-lg-y-40 {
    padding-block-start: 40px;
    padding-block-end: 40px;
  }

  .pd-lg-x-40 {
    padding-inline-start: 40px;
    padding-inline-end: 40px;
  }

  .pd-lg-t-40 {
    padding-block-start: 40px;
  }

  .pd-lg-r-40 {
    padding-inline-end: 40px;
  }

  .pd-lg-b-40 {
    padding-block-end: 40px;
  }

  .pd-lg-l-40 {
    padding-inline-start: 40px;
  }

  .pd-lg-45 {
    padding: 45px;
  }

  .pd-lg-y-45 {
    padding-block-start: 45px;
    padding-block-end: 45px;
  }

  .pd-lg-x-45 {
    padding-inline-start: 45px;
    padding-inline-end: 45px;
  }

  .pd-lg-t-45 {
    padding-block-start: 45px;
  }

  .pd-lg-r-45 {
    padding-inline-end: 45px;
  }

  .pd-lg-b-45 {
    padding-block-end: 45px;
  }

  .pd-lg-l-45 {
    padding-inline-start: 45px;
  }

  .pd-lg-50 {
    padding: 50px;
  }

  .pd-lg-y-50 {
    padding-block-start: 50px;
    padding-block-end: 50px;
  }

  .pd-lg-x-50 {
    padding-inline-start: 50px;
    padding-inline-end: 50px;
  }

  .pd-lg-t-50 {
    padding-block-start: 50px;
  }

  .pd-lg-r-50 {
    padding-inline-end: 50px;
  }

  .pd-lg-b-50 {
    padding-block-end: 50px;
  }

  .pd-lg-l-50 {
    padding-inline-start: 50px;
  }

  .pd-lg-55 {
    padding: 55px;
  }

  .pd-lg-y-55 {
    padding-block-start: 55px;
    padding-block-end: 55px;
  }

  .pd-lg-x-55 {
    padding-inline-start: 55px;
    padding-inline-end: 55px;
  }

  .pd-lg-t-55 {
    padding-block-start: 55px;
  }

  .pd-lg-r-55 {
    padding-inline-end: 55px;
  }

  .pd-lg-b-55 {
    padding-block-end: 55px;
  }

  .pd-lg-l-55 {
    padding-inline-start: 55px;
  }

  .pd-lg-60 {
    padding: 60px;
  }

  .pd-lg-y-60 {
    padding-block-start: 60px;
    padding-block-end: 60px;
  }

  .pd-lg-x-60 {
    padding-inline-start: 60px;
    padding-inline-end: 60px;
  }

  .pd-lg-t-60 {
    padding-block-start: 60px;
  }

  .pd-lg-r-60 {
    padding-inline-end: 60px;
  }

  .pd-lg-b-60 {
    padding-block-end: 60px;
  }

  .pd-lg-l-60 {
    padding-inline-start: 60px;
  }

  .pd-lg-65 {
    padding: 65px;
  }

  .pd-lg-y-65 {
    padding-block-start: 65px;
    padding-block-end: 65px;
  }

  .pd-lg-x-65 {
    padding-inline-start: 65px;
    padding-inline-end: 65px;
  }

  .pd-lg-t-65 {
    padding-block-start: 65px;
  }

  .pd-lg-r-65 {
    padding-inline-end: 65px;
  }

  .pd-lg-b-65 {
    padding-block-end: 65px;
  }

  .pd-lg-l-65 {
    padding-inline-start: 65px;
  }

  .pd-lg-70 {
    padding: 70px;
  }

  .pd-lg-y-70 {
    padding-block-start: 70px;
    padding-block-end: 70px;
  }

  .pd-lg-x-70 {
    padding-inline-start: 70px;
    padding-inline-end: 70px;
  }

  .pd-lg-t-70 {
    padding-block-start: 70px;
  }

  .pd-lg-r-70 {
    padding-inline-end: 70px;
  }

  .pd-lg-b-70 {
    padding-block-end: 70px;
  }

  .pd-lg-l-70 {
    padding-inline-start: 70px;
  }

  .pd-lg-75 {
    padding: 75px;
  }

  .pd-lg-y-75 {
    padding-block-start: 75px;
    padding-block-end: 75px;
  }

  .pd-lg-x-75 {
    padding-inline-start: 75px;
    padding-inline-end: 75px;
  }

  .pd-lg-t-75 {
    padding-block-start: 75px;
  }

  .pd-lg-r-75 {
    padding-inline-end: 75px;
  }

  .pd-lg-b-75 {
    padding-block-end: 75px;
  }

  .pd-lg-l-75 {
    padding-inline-start: 75px;
  }

  .pd-lg-80 {
    padding: 80px;
  }

  .pd-lg-y-80 {
    padding-block-start: 80px;
    padding-block-end: 80px;
  }

  .pd-lg-x-80 {
    padding-inline-start: 80px;
    padding-inline-end: 80px;
  }

  .pd-lg-t-80 {
    padding-block-start: 80px;
  }

  .pd-lg-r-80 {
    padding-inline-end: 80px;
  }

  .pd-lg-b-80 {
    padding-block-end: 80px;
  }

  .pd-lg-l-80 {
    padding-inline-start: 80px;
  }

  .pd-lg-85 {
    padding: 85px;
  }

  .pd-lg-y-85 {
    padding-block-start: 85px;
    padding-block-end: 85px;
  }

  .pd-lg-x-85 {
    padding-inline-start: 85px;
    padding-inline-end: 85px;
  }

  .pd-lg-t-85 {
    padding-block-start: 85px;
  }

  .pd-lg-r-85 {
    padding-inline-end: 85px;
  }

  .pd-lg-b-85 {
    padding-block-end: 85px;
  }

  .pd-lg-l-85 {
    padding-inline-start: 85px;
  }

  .pd-lg-90 {
    padding: 90px;
  }

  .pd-lg-y-90 {
    padding-block-start: 90px;
    padding-block-end: 90px;
  }

  .pd-lg-x-90 {
    padding-inline-start: 90px;
    padding-inline-end: 90px;
  }

  .pd-lg-t-90 {
    padding-block-start: 90px;
  }

  .pd-lg-r-90 {
    padding-inline-end: 90px;
  }

  .pd-lg-b-90 {
    padding-block-end: 90px;
  }

  .pd-lg-l-90 {
    padding-inline-start: 90px;
  }

  .pd-lg-95 {
    padding: 95px;
  }

  .pd-lg-y-95 {
    padding-block-start: 95px;
    padding-block-end: 95px;
  }

  .pd-lg-x-95 {
    padding-inline-start: 95px;
    padding-inline-end: 95px;
  }

  .pd-lg-t-95 {
    padding-block-start: 95px;
  }

  .pd-lg-r-95 {
    padding-inline-end: 95px;
  }

  .pd-lg-b-95 {
    padding-block-end: 95px;
  }

  .pd-lg-l-95 {
    padding-inline-start: 95px;
  }

  .pd-lg-100 {
    padding: 100px;
  }

  .pd-lg-y-100 {
    padding-block-start: 100px;
    padding-block-end: 100px;
  }

  .pd-lg-x-100 {
    padding-inline-start: 100px;
    padding-inline-end: 100px;
  }

  .pd-lg-t-100 {
    padding-block-start: 100px;
  }

  .pd-lg-r-100 {
    padding-inline-end: 100px;
  }

  .pd-lg-b-100 {
    padding-block-end: 100px;
  }

  .pd-lg-l-100 {
    padding-inline-start: 100px;
  }

  .pd-lg-110 {
    padding: 110px;
  }

  .pd-lg-y-110 {
    padding-block-start: 110px;
    padding-block-end: 110px;
  }

  .pd-lg-x-110 {
    padding-inline-start: 110px;
    padding-inline-end: 110px;
  }

  .pd-lg-t-110 {
    padding-block-start: 110px;
  }

  .pd-lg-r-110 {
    padding-inline-end: 110px;
  }

  .pd-lg-b-110 {
    padding-block-end: 110px;
  }

  .pd-lg-l-110 {
    padding-inline-start: 110px;
  }

  .pd-lg-120 {
    padding: 120px;
  }

  .pd-lg-y-120 {
    padding-block-start: 120px;
    padding-block-end: 120px;
  }

  .pd-lg-x-120 {
    padding-inline-start: 120px;
    padding-inline-end: 120px;
  }

  .pd-lg-t-120 {
    padding-block-start: 120px;
  }

  .pd-lg-r-120 {
    padding-inline-end: 120px;
  }

  .pd-lg-b-120 {
    padding-block-end: 120px;
  }

  .pd-lg-l-120 {
    padding-inline-start: 120px;
  }

  .pd-lg-130 {
    padding: 130px;
  }

  .pd-lg-y-130 {
    padding-block-start: 130px;
    padding-block-end: 130px;
  }

  .pd-lg-x-130 {
    padding-inline-start: 130px;
    padding-inline-end: 130px;
  }

  .pd-lg-t-130 {
    padding-block-start: 130px;
  }

  .pd-lg-r-130 {
    padding-inline-end: 130px;
  }

  .pd-lg-b-130 {
    padding-block-end: 130px;
  }

  .pd-lg-l-130 {
    padding-inline-start: 130px;
  }

  .pd-lg-140 {
    padding: 140px;
  }

  .pd-lg-y-140 {
    padding-block-start: 140px;
    padding-block-end: 140px;
  }

  .pd-lg-x-140 {
    padding-inline-start: 140px;
    padding-inline-end: 140px;
  }

  .pd-lg-t-140 {
    padding-block-start: 140px;
  }

  .pd-lg-r-140 {
    padding-inline-end: 140px;
  }

  .pd-lg-b-140 {
    padding-block-end: 140px;
  }

  .pd-lg-l-140 {
    padding-inline-start: 140px;
  }

  .pd-lg-150 {
    padding: 150px;
  }

  .pd-lg-y-150 {
    padding-block-start: 150px;
    padding-block-end: 150px;
  }

  .pd-lg-x-150 {
    padding-inline-start: 150px;
    padding-inline-end: 150px;
  }

  .pd-lg-t-150 {
    padding-block-start: 150px;
  }

  .pd-lg-r-150 {
    padding-inline-end: 150px;
  }

  .pd-lg-b-150 {
    padding-block-end: 150px;
  }

  .pd-lg-l-150 {
    padding-inline-start: 150px;
  }

  .pd-lg-160 {
    padding: 160px;
  }

  .pd-lg-y-160 {
    padding-block-start: 160px;
    padding-block-end: 160px;
  }

  .pd-lg-x-160 {
    padding-inline-start: 160px;
    padding-inline-end: 160px;
  }

  .pd-lg-t-160 {
    padding-block-start: 160px;
  }

  .pd-lg-r-160 {
    padding-inline-end: 160px;
  }

  .pd-lg-b-160 {
    padding-block-end: 160px;
  }

  .pd-lg-l-160 {
    padding-inline-start: 160px;
  }

  .pd-lg-170 {
    padding: 170px;
  }

  .pd-lg-y-170 {
    padding-block-start: 170px;
    padding-block-end: 170px;
  }

  .pd-lg-x-170 {
    padding-inline-start: 170px;
    padding-inline-end: 170px;
  }

  .pd-lg-t-170 {
    padding-block-start: 170px;
  }

  .pd-lg-r-170 {
    padding-inline-end: 170px;
  }

  .pd-lg-b-170 {
    padding-block-end: 170px;
  }

  .pd-lg-l-170 {
    padding-inline-start: 170px;
  }

  .pd-lg-180 {
    padding: 180px;
  }

  .pd-lg-y-180 {
    padding-block-start: 180px;
    padding-block-end: 180px;
  }

  .pd-lg-x-180 {
    padding-inline-start: 180px;
    padding-inline-end: 180px;
  }

  .pd-lg-t-180 {
    padding-block-start: 180px;
  }

  .pd-lg-r-180 {
    padding-inline-end: 180px;
  }

  .pd-lg-b-180 {
    padding-block-end: 180px;
  }

  .pd-lg-l-180 {
    padding-inline-start: 180px;
  }

  .pd-lg-190 {
    padding: 190px;
  }

  .pd-lg-y-190 {
    padding-block-start: 190px;
    padding-block-end: 190px;
  }

  .pd-lg-x-190 {
    padding-inline-start: 190px;
    padding-inline-end: 190px;
  }

  .pd-lg-t-190 {
    padding-block-start: 190px;
  }

  .pd-lg-r-190 {
    padding-inline-end: 190px;
  }

  .pd-lg-b-190 {
    padding-block-end: 190px;
  }

  .pd-lg-l-190 {
    padding-inline-start: 190px;
  }

  .pd-lg-200 {
    padding: 200px;
  }

  .pd-lg-y-200 {
    padding-block-start: 200px;
    padding-block-end: 200px;
  }

  .pd-lg-x-200 {
    padding-inline-start: 200px;
    padding-inline-end: 200px;
  }

  .pd-lg-t-200 {
    padding-block-start: 200px;
  }

  .pd-lg-r-200 {
    padding-inline-end: 200px;
  }

  .pd-lg-b-200 {
    padding-block-end: 200px;
  }

  .pd-lg-l-200 {
    padding-inline-start: 200px;
  }
}

@media (min-width: 1200px) {
  .pd-xl-0 {
    padding: 0px;
  }

  .pd-xl-y-0 {
    padding-block-start: 0px;
    padding-block-end: 0px;
  }

  .pd-xl-x-0 {
    padding-inline-start: 0px;
    padding-inline-end: 0px;
  }

  .pd-xl-t-0 {
    padding-block-start: 0px;
  }

  .pd-xl-r-0 {
    padding-inline-end: 0px;
  }

  .pd-xl-b-0 {
    padding-block-end: 0px;
  }

  .pd-xl-l-0 {
    padding-inline-start: 0px;
  }

  .pd-xl-1 {
    padding: 1px;
  }

  .pd-xl-y-1 {
    padding-block-start: 1px;
    padding-block-end: 1px;
  }

  .pd-xl-x-1 {
    padding-inline-start: 1px;
    padding-inline-end: 1px;
  }

  .pd-xl-t-1 {
    padding-block-start: 1px;
  }

  .pd-xl-r-1 {
    padding-inline-end: 1px;
  }

  .pd-xl-b-1 {
    padding-block-end: 1px;
  }

  .pd-xl-l-1 {
    padding-inline-start: 1px;
  }

  .pd-xl-2 {
    padding: 2px;
  }

  .pd-xl-y-2 {
    padding-block-start: 2px;
    padding-block-end: 2px;
  }

  .pd-xl-x-2 {
    padding-inline-start: 2px;
    padding-inline-end: 2px;
  }

  .pd-xl-t-2 {
    padding-block-start: 2px;
  }

  .pd-xl-r-2 {
    padding-inline-end: 2px;
  }

  .pd-xl-b-2 {
    padding-block-end: 2px;
  }

  .pd-xl-l-2 {
    padding-inline-start: 2px;
  }

  .pd-xl-3 {
    padding: 3px;
  }

  .pd-xl-y-3 {
    padding-block-start: 3px;
    padding-block-end: 3px;
  }

  .pd-xl-x-3 {
    padding-inline-start: 3px;
    padding-inline-end: 3px;
  }

  .pd-xl-t-3 {
    padding-block-start: 3px;
  }

  .pd-xl-r-3 {
    padding-inline-end: 3px;
  }

  .pd-xl-b-3 {
    padding-block-end: 3px;
  }

  .pd-xl-l-3 {
    padding-inline-start: 3px;
  }

  .pd-xl-4 {
    padding: 4px;
  }

  .pd-xl-y-4 {
    padding-block-start: 4px;
    padding-block-end: 4px;
  }

  .pd-xl-x-4 {
    padding-inline-start: 4px;
    padding-inline-end: 4px;
  }

  .pd-xl-t-4 {
    padding-block-start: 4px;
  }

  .pd-xl-r-4 {
    padding-inline-end: 4px;
  }

  .pd-xl-b-4 {
    padding-block-end: 4px;
  }

  .pd-xl-l-4 {
    padding-inline-start: 4px;
  }

  .pd-xl-5 {
    padding: 5px;
  }

  .pd-xl-y-5 {
    padding-block-start: 5px;
    padding-block-end: 5px;
  }

  .pd-xl-x-5 {
    padding-inline-start: 5px;
    padding-inline-end: 5px;
  }

  .pd-xl-t-5 {
    padding-block-start: 5px;
  }

  .pd-xl-r-5 {
    padding-inline-end: 5px;
  }

  .pd-xl-b-5 {
    padding-block-end: 5px;
  }

  .pd-xl-l-5 {
    padding-inline-start: 5px;
  }

  .pd-xl-6 {
    padding: 6px;
  }

  .pd-xl-y-6 {
    padding-block-start: 6px;
    padding-block-end: 6px;
  }

  .pd-xl-x-6 {
    padding-inline-start: 6px;
    padding-inline-end: 6px;
  }

  .pd-xl-t-6 {
    padding-block-start: 6px;
  }

  .pd-xl-r-6 {
    padding-inline-end: 6px;
  }

  .pd-xl-b-6 {
    padding-block-end: 6px;
  }

  .pd-xl-l-6 {
    padding-inline-start: 6px;
  }

  .pd-xl-7 {
    padding: 7px;
  }

  .pd-xl-y-7 {
    padding-block-start: 7px;
    padding-block-end: 7px;
  }

  .pd-xl-x-7 {
    padding-inline-start: 7px;
    padding-inline-end: 7px;
  }

  .pd-xl-t-7 {
    padding-block-start: 7px;
  }

  .pd-xl-r-7 {
    padding-inline-end: 7px;
  }

  .pd-xl-b-7 {
    padding-block-end: 7px;
  }

  .pd-xl-l-7 {
    padding-inline-start: 7px;
  }

  .pd-xl-8 {
    padding: 8px;
  }

  .pd-xl-y-8 {
    padding-block-start: 8px;
    padding-block-end: 8px;
  }

  .pd-xl-x-8 {
    padding-inline-start: 8px;
    padding-inline-end: 8px;
  }

  .pd-xl-t-8 {
    padding-block-start: 8px;
  }

  .pd-xl-r-8 {
    padding-inline-end: 8px;
  }

  .pd-xl-b-8 {
    padding-block-end: 8px;
  }

  .pd-xl-l-8 {
    padding-inline-start: 8px;
  }

  .pd-xl-9 {
    padding: 9px;
  }

  .pd-xl-y-9 {
    padding-block-start: 9px;
    padding-block-end: 9px;
  }

  .pd-xl-x-9 {
    padding-inline-start: 9px;
    padding-inline-end: 9px;
  }

  .pd-xl-t-9 {
    padding-block-start: 9px;
  }

  .pd-xl-r-9 {
    padding-inline-end: 9px;
  }

  .pd-xl-b-9 {
    padding-block-end: 9px;
  }

  .pd-xl-l-9 {
    padding-inline-start: 9px;
  }

  .pd-xl-10 {
    padding: 10px;
  }

  .pd-xl-y-10 {
    padding-block-start: 10px;
    padding-block-end: 10px;
  }

  .pd-xl-x-10 {
    padding-inline-start: 10px;
    padding-inline-end: 10px;
  }

  .pd-xl-t-10 {
    padding-block-start: 10px;
  }

  .pd-xl-r-10 {
    padding-inline-end: 10px;
  }

  .pd-xl-b-10 {
    padding-block-end: 10px;
  }

  .pd-xl-l-10 {
    padding-inline-start: 10px;
  }

  .pd-xl-15 {
    padding: 15px;
  }

  .pd-xl-y-15 {
    padding-block-start: 15px;
    padding-block-end: 15px;
  }

  .pd-xl-x-15 {
    padding-inline-start: 15px;
    padding-inline-end: 15px;
  }

  .pd-xl-t-15 {
    padding-block-start: 15px;
  }

  .pd-xl-r-15 {
    padding-inline-end: 15px;
  }

  .pd-xl-b-15 {
    padding-block-end: 15px;
  }

  .pd-xl-l-15 {
    padding-inline-start: 15px;
  }

  .pd-xl-20 {
    padding: 20px;
  }

  .pd-xl-y-20 {
    padding-block-start: 20px;
    padding-block-end: 20px;
  }

  .pd-xl-x-20 {
    padding-inline-start: 20px;
    padding-inline-end: 20px;
  }

  .pd-xl-t-20 {
    padding-block-start: 20px;
  }

  .pd-xl-r-20 {
    padding-inline-end: 20px;
  }

  .pd-xl-b-20 {
    padding-block-end: 20px;
  }

  .pd-xl-l-20 {
    padding-inline-start: 20px;
  }

  .pd-xl-25 {
    padding: 25px;
  }

  .pd-xl-y-25 {
    padding-block-start: 25px;
    padding-block-end: 25px;
  }

  .pd-xl-x-25 {
    padding-inline-start: 25px;
    padding-inline-end: 25px;
  }

  .pd-xl-t-25 {
    padding-block-start: 25px;
  }

  .pd-xl-r-25 {
    padding-inline-end: 25px;
  }

  .pd-xl-b-25 {
    padding-block-end: 25px;
  }

  .pd-xl-l-25 {
    padding-inline-start: 25px;
  }

  .pd-xl-30 {
    padding: 30px;
  }

  .pd-xl-y-30 {
    padding-block-start: 30px;
    padding-block-end: 30px;
  }

  .pd-xl-x-30 {
    padding-inline-start: 30px;
    padding-inline-end: 30px;
  }

  .pd-xl-t-30 {
    padding-block-start: 30px;
  }

  .pd-xl-r-30 {
    padding-inline-end: 30px;
  }

  .pd-xl-b-30 {
    padding-block-end: 30px;
  }

  .pd-xl-l-30 {
    padding-inline-start: 30px;
  }

  .pd-xl-35 {
    padding: 35px;
  }

  .pd-xl-y-35 {
    padding-block-start: 35px;
    padding-block-end: 35px;
  }

  .pd-xl-x-35 {
    padding-inline-start: 35px;
    padding-inline-end: 35px;
  }

  .pd-xl-t-35 {
    padding-block-start: 35px;
  }

  .pd-xl-r-35 {
    padding-inline-end: 35px;
  }

  .pd-xl-b-35 {
    padding-block-end: 35px;
  }

  .pd-xl-l-35 {
    padding-inline-start: 35px;
  }

  .pd-xl-40 {
    padding: 40px;
  }

  .pd-xl-y-40 {
    padding-block-start: 40px;
    padding-block-end: 40px;
  }

  .pd-xl-x-40 {
    padding-inline-start: 40px;
    padding-inline-end: 40px;
  }

  .pd-xl-t-40 {
    padding-block-start: 40px;
  }

  .pd-xl-r-40 {
    padding-inline-end: 40px;
  }

  .pd-xl-b-40 {
    padding-block-end: 40px;
  }

  .pd-xl-l-40 {
    padding-inline-start: 40px;
  }

  .pd-xl-45 {
    padding: 45px;
  }

  .pd-xl-y-45 {
    padding-block-start: 45px;
    padding-block-end: 45px;
  }

  .pd-xl-x-45 {
    padding-inline-start: 45px;
    padding-inline-end: 45px;
  }

  .pd-xl-t-45 {
    padding-block-start: 45px;
  }

  .pd-xl-r-45 {
    padding-inline-end: 45px;
  }

  .pd-xl-b-45 {
    padding-block-end: 45px;
  }

  .pd-xl-l-45 {
    padding-inline-start: 45px;
  }

  .pd-xl-50 {
    padding: 50px;
  }

  .pd-xl-y-50 {
    padding-block-start: 50px;
    padding-block-end: 50px;
  }

  .pd-xl-x-50 {
    padding-inline-start: 50px;
    padding-inline-end: 50px;
  }

  .pd-xl-t-50 {
    padding-block-start: 50px;
  }

  .pd-xl-r-50 {
    padding-inline-end: 50px;
  }

  .pd-xl-b-50 {
    padding-block-end: 50px;
  }

  .pd-xl-l-50 {
    padding-inline-start: 50px;
  }

  .pd-xl-55 {
    padding: 55px;
  }

  .pd-xl-y-55 {
    padding-block-start: 55px;
    padding-block-end: 55px;
  }

  .pd-xl-x-55 {
    padding-inline-start: 55px;
    padding-inline-end: 55px;
  }

  .pd-xl-t-55 {
    padding-block-start: 55px;
  }

  .pd-xl-r-55 {
    padding-inline-end: 55px;
  }

  .pd-xl-b-55 {
    padding-block-end: 55px;
  }

  .pd-xl-l-55 {
    padding-inline-start: 55px;
  }

  .pd-xl-60 {
    padding: 60px;
  }

  .pd-xl-y-60 {
    padding-block-start: 60px;
    padding-block-end: 60px;
  }

  .pd-xl-x-60 {
    padding-inline-start: 60px;
    padding-inline-end: 60px;
  }

  .pd-xl-t-60 {
    padding-block-start: 60px;
  }

  .pd-xl-r-60 {
    padding-inline-end: 60px;
  }

  .pd-xl-b-60 {
    padding-block-end: 60px;
  }

  .pd-xl-l-60 {
    padding-inline-start: 60px;
  }

  .pd-xl-65 {
    padding: 65px;
  }

  .pd-xl-y-65 {
    padding-block-start: 65px;
    padding-block-end: 65px;
  }

  .pd-xl-x-65 {
    padding-inline-start: 65px;
    padding-inline-end: 65px;
  }

  .pd-xl-t-65 {
    padding-block-start: 65px;
  }

  .pd-xl-r-65 {
    padding-inline-end: 65px;
  }

  .pd-xl-b-65 {
    padding-block-end: 65px;
  }

  .pd-xl-l-65 {
    padding-inline-start: 65px;
  }

  .pd-xl-70 {
    padding: 70px;
  }

  .pd-xl-y-70 {
    padding-block-start: 70px;
    padding-block-end: 70px;
  }

  .pd-xl-x-70 {
    padding-inline-start: 70px;
    padding-inline-end: 70px;
  }

  .pd-xl-t-70 {
    padding-block-start: 70px;
  }

  .pd-xl-r-70 {
    padding-inline-end: 70px;
  }

  .pd-xl-b-70 {
    padding-block-end: 70px;
  }

  .pd-xl-l-70 {
    padding-inline-start: 70px;
  }

  .pd-xl-75 {
    padding: 75px;
  }

  .pd-xl-y-75 {
    padding-block-start: 75px;
    padding-block-end: 75px;
  }

  .pd-xl-x-75 {
    padding-inline-start: 75px;
    padding-inline-end: 75px;
  }

  .pd-xl-t-75 {
    padding-block-start: 75px;
  }

  .pd-xl-r-75 {
    padding-inline-end: 75px;
  }

  .pd-xl-b-75 {
    padding-block-end: 75px;
  }

  .pd-xl-l-75 {
    padding-inline-start: 75px;
  }

  .pd-xl-80 {
    padding: 80px;
  }

  .pd-xl-y-80 {
    padding-block-start: 80px;
    padding-block-end: 80px;
  }

  .pd-xl-x-80 {
    padding-inline-start: 80px;
    padding-inline-end: 80px;
  }

  .pd-xl-t-80 {
    padding-block-start: 80px;
  }

  .pd-xl-r-80 {
    padding-inline-end: 80px;
  }

  .pd-xl-b-80 {
    padding-block-end: 80px;
  }

  .pd-xl-l-80 {
    padding-inline-start: 80px;
  }

  .pd-xl-85 {
    padding: 85px;
  }

  .pd-xl-y-85 {
    padding-block-start: 85px;
    padding-block-end: 85px;
  }

  .pd-xl-x-85 {
    padding-inline-start: 85px;
    padding-inline-end: 85px;
  }

  .pd-xl-t-85 {
    padding-block-start: 85px;
  }

  .pd-xl-r-85 {
    padding-inline-end: 85px;
  }

  .pd-xl-b-85 {
    padding-block-end: 85px;
  }

  .pd-xl-l-85 {
    padding-inline-start: 85px;
  }

  .pd-xl-90 {
    padding: 90px;
  }

  .pd-xl-y-90 {
    padding-block-start: 90px;
    padding-block-end: 90px;
  }

  .pd-xl-x-90 {
    padding-inline-start: 90px;
    padding-inline-end: 90px;
  }

  .pd-xl-t-90 {
    padding-block-start: 90px;
  }

  .pd-xl-r-90 {
    padding-inline-end: 90px;
  }

  .pd-xl-b-90 {
    padding-block-end: 90px;
  }

  .pd-xl-l-90 {
    padding-inline-start: 90px;
  }

  .pd-xl-95 {
    padding: 95px;
  }

  .pd-xl-y-95 {
    padding-block-start: 95px;
    padding-block-end: 95px;
  }

  .pd-xl-x-95 {
    padding-inline-start: 95px;
    padding-inline-end: 95px;
  }

  .pd-xl-t-95 {
    padding-block-start: 95px;
  }

  .pd-xl-r-95 {
    padding-inline-end: 95px;
  }

  .pd-xl-b-95 {
    padding-block-end: 95px;
  }

  .pd-xl-l-95 {
    padding-inline-start: 95px;
  }

  .pd-xl-100 {
    padding: 100px;
  }

  .pd-xl-y-100 {
    padding-block-start: 100px;
    padding-block-end: 100px;
  }

  .pd-xl-x-100 {
    padding-inline-start: 100px;
    padding-inline-end: 100px;
  }

  .pd-xl-t-100 {
    padding-block-start: 100px;
  }

  .pd-xl-r-100 {
    padding-inline-end: 100px;
  }

  .pd-xl-b-100 {
    padding-block-end: 100px;
  }

  .pd-xl-l-100 {
    padding-inline-start: 100px;
  }

  .pd-xl-110 {
    padding: 110px;
  }

  .pd-xl-y-110 {
    padding-block-start: 110px;
    padding-block-end: 110px;
  }

  .pd-xl-x-110 {
    padding-inline-start: 110px;
    padding-inline-end: 110px;
  }

  .pd-xl-t-110 {
    padding-block-start: 110px;
  }

  .pd-xl-r-110 {
    padding-inline-end: 110px;
  }

  .pd-xl-b-110 {
    padding-block-end: 110px;
  }

  .pd-xl-l-110 {
    padding-inline-start: 110px;
  }

  .pd-xl-120 {
    padding: 120px;
  }

  .pd-xl-y-120 {
    padding-block-start: 120px;
    padding-block-end: 120px;
  }

  .pd-xl-x-120 {
    padding-inline-start: 120px;
    padding-inline-end: 120px;
  }

  .pd-xl-t-120 {
    padding-block-start: 120px;
  }

  .pd-xl-r-120 {
    padding-inline-end: 120px;
  }

  .pd-xl-b-120 {
    padding-block-end: 120px;
  }

  .pd-xl-l-120 {
    padding-inline-start: 120px;
  }

  .pd-xl-130 {
    padding: 130px;
  }

  .pd-xl-y-130 {
    padding-block-start: 130px;
    padding-block-end: 130px;
  }

  .pd-xl-x-130 {
    padding-inline-start: 130px;
    padding-inline-end: 130px;
  }

  .pd-xl-t-130 {
    padding-block-start: 130px;
  }

  .pd-xl-r-130 {
    padding-inline-end: 130px;
  }

  .pd-xl-b-130 {
    padding-block-end: 130px;
  }

  .pd-xl-l-130 {
    padding-inline-start: 130px;
  }

  .pd-xl-140 {
    padding: 140px;
  }

  .pd-xl-y-140 {
    padding-block-start: 140px;
    padding-block-end: 140px;
  }

  .pd-xl-x-140 {
    padding-inline-start: 140px;
    padding-inline-end: 140px;
  }

  .pd-xl-t-140 {
    padding-block-start: 140px;
  }

  .pd-xl-r-140 {
    padding-inline-end: 140px;
  }

  .pd-xl-b-140 {
    padding-block-end: 140px;
  }

  .pd-xl-l-140 {
    padding-inline-start: 140px;
  }

  .pd-xl-150 {
    padding: 150px;
  }

  .pd-xl-y-150 {
    padding-block-start: 150px;
    padding-block-end: 150px;
  }

  .pd-xl-x-150 {
    padding-inline-start: 150px;
    padding-inline-end: 150px;
  }

  .pd-xl-t-150 {
    padding-block-start: 150px;
  }

  .pd-xl-r-150 {
    padding-inline-end: 150px;
  }

  .pd-xl-b-150 {
    padding-block-end: 150px;
  }

  .pd-xl-l-150 {
    padding-inline-start: 150px;
  }
  .pd-xl-160 {
    padding: 160px;
  }

  .pd-xl-y-160 {
    padding-block-start: 160px;
    padding-block-end: 160px;
  }

  .pd-xl-x-160 {
    padding-inline-start: 160px;
    padding-inline-end: 160px;
  }

  .pd-xl-t-160 {
    padding-block-start: 160px;
  }

  .pd-xl-r-160 {
    padding-inline-end: 160px;
  }

  .pd-xl-b-160 {
    padding-block-end: 160px;
  }

  .pd-xl-l-160 {
    padding-inline-start: 160px;
  }

  .pd-xl-170 {
    padding: 170px;
  }

  .pd-xl-y-170 {
    padding-block-start: 170px;
    padding-block-end: 170px;
  }

  .pd-xl-x-170 {
    padding-inline-start: 170px;
    padding-inline-end: 170px;
  }

  .pd-xl-t-170 {
    padding-block-start: 170px;
  }

  .pd-xl-r-170 {
    padding-inline-end: 170px;
  }

  .pd-xl-b-170 {
    padding-block-end: 170px;
  }

  .pd-xl-l-170 {
    padding-inline-start: 170px;
  }

  .pd-xl-180 {
    padding: 180px;
  }

  .pd-xl-y-180 {
    padding-block-start: 180px;
    padding-block-end: 180px;
  }

  .pd-xl-x-180 {
    padding-inline-start: 180px;
    padding-inline-end: 180px;
  }

  .pd-xl-t-180 {
    padding-block-start: 180px;
  }

  .pd-xl-r-180 {
    padding-inline-end: 180px;
  }

  .pd-xl-b-180 {
    padding-block-end: 180px;
  }

  .pd-xl-l-180 {
    padding-inline-start: 180px;
  }

  .pd-xl-190 {
    padding: 190px;
  }

  .pd-xl-y-190 {
    padding-block-start: 190px;
    padding-block-end: 190px;
  }

  .pd-xl-x-190 {
    padding-inline-start: 190px;
    padding-inline-end: 190px;
  }

  .pd-xl-t-190 {
    padding-block-start: 190px;
  }

  .pd-xl-r-190 {
    padding-inline-end: 190px;
  }

  .pd-xl-b-190 {
    padding-block-end: 190px;
  }

  .pd-xl-l-190 {
    padding-inline-start: 190px;
  }

  .pd-xl-200 {
    padding: 200px;
  }

  .pd-xl-y-200 {
    padding-block-start: 200px;
    padding-block-end: 200px;
  }

  .pd-xl-x-200 {
    padding-inline-start: 200px;
    padding-inline-end: 200px;
  }

  .pd-xl-t-200 {
    padding-block-start: 200px;
  }

  .pd-xl-r-200 {
    padding-inline-end: 200px;
  }

  .pd-xl-b-200 {
    padding-block-end: 200px;
  }

  .pd-xl-l-200 {
    padding-inline-start: 200px;
  }
}

.pd-12 {
  padding: 12px;
}

.pd-y-12 {
  padding-block-start: 12px;
  padding-block-end: 12px;
}

.pd-x-12 {
  padding-inline-start: 12px;
  padding-inline-end: 12px;
}

.pd-t-12 {
  padding-block-start: 12px;
}

.pd-r-12 {
  padding-inline-end: 12px;
}

.pd-b-12 {
  padding-block-end: 12px;
}

.pd-l-12 {
  padding-inline-start: 12px;
}

.pd-13 {
  padding: 13px;
}

.pd-y-13 {
  padding-block-start: 13px;
  padding-block-end: 13px;
}

.pd-x-13 {
  padding-inline-start: 13px;
  padding-inline-end: 13px;
}

.pd-t-13 {
  padding-block-start: 13px;
}

.pd-r-13 {
  padding-inline-end: 13px;
}

.pd-b-13 {
  padding-block-end: 13px;
}

.pd-l-13 {
  padding-inline-start: 13px;
}
/*---Padding---*/
/*------ Alignments -------*/

.align-baseline {
	vertical-align: baseline !important;
  }
  
  .align-top {
	vertical-align: top !important;
  }
  
  .align-middle {
	vertical-align: middle !important;
  }
  
  .align-bottom {
	vertical-align: bottom !important;
  }
  
  .align-text-bottom {
	vertical-align: text-bottom !important;
  }
  
  .align-text-top {
	vertical-align: text-top !important;
  }
  
  .clearfix {
	&::after {
	  display: block;
	  clear: both;
	  content: "";
	}
  
  }
  
  i.fa.round {
	border-radius: 50%;
	padding: 5px;
	width: 15%;
  }
  
  .list-icon span p {
	border-radius: 15px;
	width: 50%;
	margin: 0px auto;
  }
  
  .embed-responsive {
	position: relative;
	display: block;
	width: 100%;
	padding: 0;
	overflow: hidden;
  
	&::before {
	  display: block;
	  content: "";
	}
  
	.embed-responsive-item, iframe, embed, object, video {
	  position: absolute;
	  top: 0;
	  bottom: 0;
	  inset-inline-start: 0;
	  width: 100%;
	  height: 100%;
	  border: 0;
	}
  }
  
  .embed-responsive-21by9::before {
	padding-block-start: 42.85714286%;
  }
  
  .embed-responsive-16by9::before {
	padding-block-start: 56.25%;
  }
  
  .embed-responsive-4by3::before {
	padding-block-start: 75%;
  }
  
  .embed-responsive-1by1::before {
	padding-block-start: 100%;
  }
  
  
  @media (min-width: 576px) {
	.float-sm-left {
	  float: $float-left !important;
	}
  
	.float-sm-right {
	  float: $float-right !important;
	}
  
	.float-sm-none {
	  float: none !important;
	}
  }
  
  @media (min-width: 768px) {
	.page-content {
	  margin: 1.5rem 0;
	}
  
  }
  
  @media (min-width: 992px) {
	.text-lg-left {
	  text-align: start !important;
	}
  
	.text-lg-right {
	  text-align: end !important;
	}
  
	.text-lg-center {
	  text-align: center !important;
	}
  
  }
  .float-start {
	float: $float-left ;
  }
  
  .float-end {
	float: $float-right ;
  }
  
  .position-static {
	position: static !important;
  }
  
  .position-relative {
	position: relative !important;
  }
  
  .position-absolute {
	position: absolute !important;
  }
  
  .position-fixed {
	position: fixed !important;
  }
  
  .position-sticky {
	position: -webkit-sticky !important;
	position: sticky !important;
  }
  
  .fixed-top {
	position: fixed;
	top: 0;
	inset-inline-end: 0;
	inset-inline-start: 0;
	z-index: 1030;
  }
  
  .fixed-bottom {
	position: fixed;
	inset-inline-end: 0;
	bottom: 0;
	inset-inline-start: 0;
	z-index: 1030;
  }
  
  @supports (position: -webkit-sticky) or (position: sticky) {
	.sticky-top {
	  position: -webkit-sticky;
	  position: sticky;
	  top: 0;
	  z-index: 1020;
	}
  }
  
  .sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
  }
  
  .sr-only-focusable {
	&:active, &:focus {
	  position: static;
	  width: auto;
	  height: auto;
	  overflow: visible;
	  clip: auto;
	  white-space: normal;
	}
  }
  
  .shadow-sm {
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }
  
  .shadow {
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
  
  .shadow-lg {
	box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  }
  
  .shadow-none {
	box-shadow: none !important;
  }
  
  /*------- Alignments & values-------*/
  
  .visible {
	visibility: visible !important;
  }
  
  .invisible {
	visibility: hidden !important;
  }
  
  .lead {
	line-height: 1.4;
  }
  
  a {
	-webkit-text-decoration-skip: ink;
	text-decoration-skip: ink;
  }
  
  h1 a, h2 a, h3 a, h4 a, h5 a, h6 a, .h1 a, .h2 a, .h3 a, .h4 a, .h5 a, .h6 a {
	color: inherit;
  }
  
  strong, b {
	font-weight: 600;
  }
  
  p, ul, ol {
	margin-bottom: 1em;
  }
  
  blockquote {
	margin-bottom: 1em;
	font-style: italic;
	color: #6e7687;
	padding-inline-start: 2rem;
	border-inline-start: 2px solid rgba(0, 40, 100, 0.12);
  
	p {
	  margin-bottom: 1rem;
	}
  
	cite {
	  display: block;
	  text-align: end;
  
	  &:before {
		content: 'â€” ';
	  }
	}
  }
  
  code {
	background: #f0f0f2;
	border: 1px solid $border;
	border-radius: 3px;
	padding: 3px;
  }
  
  pre code {
	padding: 0;
	border-radius: 0;
	border: none;
	background: none;
  }
  
  hr {
	margin-block-start: 2rem;
	margin-bottom: 2rem;
  }
  
  img {
	max-width: 100%;
  }
  
  .text-wrap {
	font-size: 14px;
	line-height: 1.66;
  
	> {
	  :first-child {
		margin-block-start: 0;
	  }
  
	  :last-child {
		margin-bottom: 0;
	  }
  
	  h1, h2, h3, h4, h5, h6 {
		margin-block-start: 1em;
	  }
	}
  }
  
  .section-nav {
	background-color: #f8f9fa;
	margin: 1rem 0;
	padding: .5rem 1rem;
	border: 1px solid $border;
	border-radius: 3px;
	list-style: none;
  
	&:before {
	  content: 'Table of contents:';
	  display: block;
	  font-weight: 600;
	}
  }
  
  .row-cards > {
	.col, [class*='col-'] {
	  display: -ms-flexbox;
	  display: flex;
	  -ms-flex-direction: column;
	  flex-direction: column;
	}
  }
  
  .row-deck > {
	.col, [class*='col-'] {
	  display: -ms-flexbox;
	  display: flex;
	  -ms-flex-align: stretch;
	  align-items: stretch;
	}
  
	.col .card, [class*='col-'] .card {
	  -ms-flex: 1 1 auto;
	  flex: 1 1 auto;
	}
  }
  
  .col-text {
	max-width: 48rem;
  }
  
  .col-login {
	max-width: 24rem;
  }
  
  .gutters-0 {
	margin-inline-end: 0;
	margin-inline-start: 0;
  
	> {
	  .col, [class*="col-"] {
		padding-inline-end: 0;
		padding-inline-start: 0;
	  }
	}
  
	.card {
	  margin-bottom: 0;
	}
  }
  
  .gutters-xs {
	margin-inline-end: -0.25rem;
	margin-inline-start: -0.25rem;
  
	> {
	  .col, [class*="col-"] {
		padding-inline-end: 0.25rem;
		padding-inline-start: 0.25rem;
	  }
	}
  
	.card {
	  margin-bottom: 0.5rem;
	}
  }
  
  .gutters-sm {
	margin-inline-end: -0.5rem;
	margin-inline-start: -0.5rem;
  
	> {
	  .col, [class*="col-"] {
		padding-inline-end: 0.5rem;
		padding-inline-start: 0.5rem;
	  }
	}
  
	.card {
	  margin-bottom: 1rem;
	}
  }
  
  .gutters-lg {
	margin-inline-end: -1rem;
	margin-inline-start: -1rem;
  
	> {
	  .col, [class*="col-"] {
		padding-inline-end: 1rem;
		padding-inline-start: 1rem;
	  }
	}
  
	.card {
	  margin-bottom: 2rem;
	}
  }
  
  .gutters-xl {
	margin-inline-end: -1.5rem;
	margin-inline-start: -1.5rem;
  
	> {
	  .col, [class*="col-"] {
		padding-inline-end: 1.5rem;
		padding-inline-start: 1.5rem;
	  }
	}
  
	.card {
	  margin-bottom: 3rem;
	}
  }
  
  .page {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
	min-height: 100vh;
  }
  
  .page-content {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
	min-height: 100%;
	margin: .75rem 0;
  }
  
  body.fixed-header .page {
	padding-block-start: 4.5rem;
  }
  
  @media (min-width: 1600px) {
	body.aside-opened .page {
	  margin-inline-end: 22rem;
	}
  }
  
  .page-main {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
  }
  
  .pos-absolute {
	position: absolute;
	top: 0;
	border: 0;
	inset-inline-start: 0;
	inset-inline-end: 0;
	display: block;
	width: 100%;
	height: 100%;
  }
  
  .text-icon {
	color: #5c678f !important;
  }
  /*------ Alignments -------*/

/*---------Popovers----------*/
.custom-popover {
	--bs-popover-max-width: 12.5rem;
	--bs-popover-border-color: #{$primary};
	--bs-popover-header-bg: #{$primary};
	--bs-popover-header-color: #{$white};
	--bs-popover-body-padding-x: 1rem;
	--bs-popover-body-padding-y: 0.5rem;
  }
  
  .popover {
	background-color: $white;
	border: 1px solid $border;
	box-shadow: $shadow;
	font-size: 0.8rem;
	border-radius: 5px;
	.popover-header {
	  background-color: $white;
	  border-block-end: 1px solid $border;
	}
	.popover-body {
	  color: $default-color ;
	}
  }
  .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after,
  .bs-popover-top > .popover-arrow::after,
  .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before,
  .bs-popover-top > .popover-arrow::before {
	border-top-color: $gray-100;
  }
  .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after,
  .bs-popover-end > .popover-arrow::after,
  .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before,
  .bs-popover-end > .popover-arrow::before {
	border-right-color: $gray-100;
  }
  .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after,
  .bs-popover-start > .popover-arrow::after,
  .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before,
  .bs-popover-start > .popover-arrow::before {
	border-left-color: $gray-100;
  }
  .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after,
  .bs-popover-bottom > .popover-arrow::after,
  .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before,
  .bs-popover-bottom > .popover-arrow::before,
  .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before,
  .bs-popover-bottom .popover-header::before {
	border-bottom-color: $gray-100;
  }
  
  @mixin pop-header-color($default-color ) {
	.popover-header {
	  background-color: $default-color ;
	  color: $white;
	}
	&.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after,
	&.bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
	  border-bottom-color: $default-color ;
	}
  }
  .header-primary {
	@include pop-header-color($primary);
  }
  .header-secondary {
	@include pop-header-color($secondary);
  }
  .header-warning {
	@include pop-header-color($warning);
  }
  .header-info {
	@include pop-header-color($info);
  }
  .header-success {
	@include pop-header-color($success);
  }
  .header-danger {
	@include pop-header-color($danger);
  }
  
  @mixin pop-bgcolor($default-color ) {
	&.popover {
		border: 1px solid $default-color ;
	  .popover-header {
		background-color: $default-color ;
		border-block-end: 1px solid rgba(255, 255, 255, 0.1);
		color: $white;
		border-radius: 5px 5px 0 0;
	  }
	  .popover-body {
		background-color: $default-color ;
		color: $white;
		border-radius: 0 0 5px 5px;
	  }
	}
	&.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after,
	&.bs-popover-top > .popover-arrow::after,
	&.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before,
	&.bs-popover-top > .popover-arrow::before {
	  border-top-color: $default-color ;
	}
	&.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after,
	&.bs-popover-end > .popover-arrow::after,
	&.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before,
	&.bs-popover-end > .popover-arrow::before {
	  border-right-color: $default-color ;
	}
	&.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after,
	&.bs-popover-start > .popover-arrow::after,
	&.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before,
	&.bs-popover-start > .popover-arrow::before {
	  border-left-color: $default-color ;
	}
	&.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after,
	&.bs-popover-bottom > .popover-arrow::after,
	&.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before,
	&.bs-popover-bottom > .popover-arrow::before,
	&.bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before,
	&.bs-popover-bottom .popover-header::before {
	  border-bottom-color: $default-color ;
	}
  }
  .popover-primary {
	@include pop-bgcolor($primary);
  }
  .popover-secondary {
	@include pop-bgcolor($secondary);
  }
  .popover-warning {
	@include pop-bgcolor($warning);
  }
  .popover-info {
	@include pop-bgcolor($info);
  }
  .popover-success {
	@include pop-bgcolor($success);
  }
  .popover-danger {
	@include pop-bgcolor($danger);
  }
  .popover-purple {
	@include pop-bgcolor($purple);
  }
  .popover-teal {
	@include pop-bgcolor($teal);
  }
  .tooltip {
	@mixin tooltip-color($color) {
	  .tooltip-inner {
		background-color: $color;
	  }
	  &.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before,
	  &.bs-tooltip-top .tooltip-arrow::before {
		border-top-color: $color;
	  }
	  &.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before,
	  &.bs-tooltip-end .tooltip-arrow::before {
		border-inline-end-color: $color;
	  }
	  &.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before,
	  &.bs-tooltip-start .tooltip-arrow::before {
		border-inline-start-color: $color;
	  }
	  &.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before,
	  &.bs-tooltip-end .tooltip-arrow::before {
		border-bottom-color: $color;
	  }
	}
	&.tooltip-primary {
	  @include tooltip-color($primary);
	}
	&.tooltip-secondary {
	  @include tooltip-color($secondary);
	}
	&.tooltip-warning {
	  @include tooltip-color($warning);
	}
	&.tooltip-info {
	  @include tooltip-color($info);
	}
	&.tooltip-success {
	  @include tooltip-color($success);
	}
	&.tooltip-danger {
	  @include tooltip-color($danger);
	}
	&.tooltip-dark {
	  @include tooltip-color($dark);
	  .tooltip-inner {
		color: $white;
	  }
	}
	&.tooltip-light {
	  @include tooltip-color($gray-100);
	  .tooltip-inner {
		color: $default-color;
	  }
	}
  }

/*---------Popovers----------*/